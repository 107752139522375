<!-- =========================================================================================
  File Name: InitData.vue
  Description: JS file that is use to fetch data from the server
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    async getLayerContent() {
      this.$vs.loading()
      await this.$store.dispatch('hoverlay/getLayerContent')
      this.$vs.loading.close()
    },
    modeExists(provider, collection, name, version) {
      return (
        this.$store.state.hoverlay.models.find(
          model =>
            model.provider == provider &&
            model.collection == collection &&
            model.name == name &&
            model.version == version
        ) != undefined
      )
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
  },
  computed: {
    hobjects() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.hobjects
      } else {
        return null
      }
    },
  },
  async mounted() {},

  async created() {
    try {
      this.$vs.loading()
      var promiseArray = []
      // if hobjects property does not contain any hobjects, we fetch the database to check if there is any.
      // await this.timeout(500)
      // if (this.hobjects && this.hobjects.length == 0)
      // this.getLayerContent()

      // Fetch anchors if they do not exist already
      if (this.$store.state.hoverlay.anchors.find(anchor => anchor.anchor_type == 'floor') == undefined)
        promiseArray.push(this.$store.dispatch('hoverlay/fetchAnchor', 'floor'))

      if (this.$store.state.hoverlay.anchors.find(anchor => anchor.anchor_type == 'scene-origin') == undefined)
        promiseArray.push(this.$store.dispatch('hoverlay/fetchAnchor', 'scene-origin'))

      if (this.$store.state.hoverlay.anchors.find(anchor => anchor.anchor_type == 'table-top') == undefined)
        promiseArray.push(this.$store.dispatch('hoverlay/fetchAnchor', 'table-top'))

      // Fetch all models from the database if they do not exist already
      if (
        !this.modeExists('hoverlay', 'core', 'Image', '1.0') ||
        !this.modeExists('hoverlay', 'core', 'Video', '1.0') ||
        !this.modeExists('hoverlay', 'core', 'ChromaVideo', '1.0') ||
        !this.modeExists('hoverlay', 'core', '3dModel', '1.0') ||
        !this.modeExists('hoverlay', 'core', 'Button', '1.0') ||
        !this.modeExists('hoverlay', 'core', 'Audio', '1.0')
      )
        promiseArray.push(
          this.$store.dispatch('hoverlay/fetchModel', {
            provider: 'hoverlay',
            collection: 'core',
          })
        )
      await Promise.all(promiseArray)
    } catch (e) {
      console.error(e)
    } finally {
      this.$vs.loading.close()
    }
  },
}
</script>
