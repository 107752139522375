<!-- =========================================================================================
  File Name: FormVideoControls.vue
  Description: Form to edit the attributes name and desccription of a hobject
========================================================================================== -->

<template>
  <div>
    <p>Video player controls</p>
    <div class="vx-row mt-6 mb-6">
      <div class="vx-col w-1/3">
        <vs-checkbox v-model="loop">Loops</vs-checkbox>
      </div>
      <div class="vx-col w-1/3">
        <vs-checkbox v-model="auto_start">Starts automatically</vs-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  props: {
    hobject: {},
  },
  data() {
    return {
      loop: true,
      auto_start: true,
    }
  },
  watch: {
    loop: {
      handler: function () {
        var abilities
        if (this.loop) {
          abilities = JSON.parse(this.hobject.abilities)
          abilities.skin.loop = true
          this.hobject.abilities = JSON.stringify(abilities)
        } else {
          abilities = JSON.parse(this.hobject.abilities)
          abilities.skin.loop = false
          this.hobject.abilities = JSON.stringify(abilities)
        }
      },
    },
    auto_start: {
      handler: function () {
        var abilities
        if (this.auto_start) {
          abilities = JSON.parse(this.hobject.abilities)
          abilities.skin.auto_start = true
          this.hobject.abilities = JSON.stringify(abilities)
        } else {
          abilities = JSON.parse(this.hobject.abilities)
          abilities.skin.auto_start = false
          this.hobject.abilities = JSON.stringify(abilities)
        }
      },
    },
    hobject: {
      handler: function (updatedValue) {
        this.$emit('setHobject', updatedValue)
      },
    },
  },
  created() {
    try {
      var abilities = JSON.parse(this.hobject.abilities)
      if ('skin' in abilities)
      {
        if (abilities.skin.loop == true) this.loop = true
        else this.loop = false        
        if (abilities.skin.auto_start == true) this.auto_start = true
        else this.auto_start = false
      }
    } catch (e) {
      this.loop = false
      this.auto_start = false
    }
  },
  methods: {
    validate() {
      return true
    },
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}
</style>
