<!-- =========================================================================================
  File Name: uploads/GlbUpload.vue
  Description: GLB Upload Form
========================================================================================== -->

<template>
  <form data-vv-scope="step-1">
    <div>
      <div class="vx-col w-full">
        <p>
          If you need to pack your GLTF model into one single file use
          <a target="_blank" rel="noopener noreferrer" href="https://sbtron.github.io/makeglb/"
            >this online converter</a
          >
        </p>
      </div>
      <div id="gist"></div>
      <center>
        <div v-if="glbUrl">
          <model-viewer
            v-on:model-visibility="tryCanvasScreenshot()"
            autoplay
            shadow-intensity="1"
            :animation-name="selectedAnimation"
            :src="encodeURI(glbUrl)"
            alt="A 3D model of an astronaut"
            auto-rotate
            camera-controls
          ></model-viewer>
        </div>
      </center>
      <br />
      <file-pond
        :maxFileSize="this.maxFileSize"
        name="test"
        ref="pond"
        :label-idle="`${this.$t('HoverpackGltfDrag')} (Max ${this.maxFileSize})`"
        :server="{ process, load, fetch, remove }"
        :allow-multiple="false"
        allowFileTypeValidation="{false}"
        accepted-file-types=".glb,.gltf"
        :files="filePondFile"
        v-on:addfile="fileAdded"
        v-on:removefile="fileRemoved"
        v-on:init="handleFilePondInit"
      />

      <span v-if="errorMessageUpload" class="text-danger">{{ errorMessageUpload }}</span>

      <div v-if="glbUrl && report && report.issues.messages.length > 0" class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-alert
            active="true"
            :color="getAlertColor(report.issues)"
            class="mt-5"
            icon-pack="feather"
            icon="icon-info"
          >
            Your model has some issues. Go to
            <a target="_blank" rel="noopener noreferrer" href="https://github.khronos.org/glTF-Validator/"
              >gltF-Validator</a
            >
            for more info.
          </vs-alert>

          <p></p>
        </div>
      </div>
      <vs-checkbox v-show="isCompressingOptionsVisible" v-model="hobject.compress_glb">Compress model</vs-checkbox>
      <div v-if="animationOptions.length > 0" class="vx-row mb-6">
        <div class="vx-col w-1/2">
          <label for="" class="vs-input--label">Select your animation</label>
          <v-select v-model="selectedAnimation" :options="animationOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
        </div>
        <div class="vx-col w-1/2">
          <vs-checkbox class="inline-flex mt-8" v-model="loopAnimation">Loop</vs-checkbox>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'

import postscribe from 'postscribe'

// Import Vue FilePond
import vueFilePond from 'vue-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js'

import * as HoverlayThreeUtils from '@/assets/js/utils/hoverlay-three-utils.js'

import * as Utils from '@/assets/js/utils/utils.js'
const validator = require('gltf-validator')
import humanize from 'string-humanize'

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize)
import vSelect from 'vue-select'

/* eslint-disable no-useless-escape */

export default {
  inject: ['$validator'],
  components: {
    FilePond,
    vSelect,
  },
  props: {
    hobject: {},
    initialModel: {},
  },
  data() {
    return {
      // inherited variables from parent
      model: this.initialModel,
      isAnimationSupported: false,
      animationOptions: [],
      selectedAnimation: null,
      loopAnimation: null,
      triangles_count: 0,
      // local variables
      errorMessageUpload: '',
      glbUrl: null,
      filePondFile: [],
      plan: HoverlayUtils.getLayerPlanName(),
      screenshotInterval: null,
      report: null,
      isCompressingOptionsVisible: false,
    }
  },
  computed: {
    maxFileSize: function() {
      return HoverlayUtils.getMaxUploadFileSize()
    },
  },
  watch: {
    triangles_count: {
      handler: function(updatedValue) {
        this.hobject.triangles_count = updatedValue
      },
    },
    model: {
      handler: function(updatedValue) {},
      deep: true,
    },
    selectedAnimation: {
      handler: function(updatedValue) {
        var abilities = JSON.parse(this.hobject.abilities)
        if (updatedValue) abilities.gltf_loader.animation_clip_name = updatedValue
        else delete abilities.gltf_loader.animation_clip_name
        this.hobject.abilities = JSON.stringify(abilities)
      },
      deep: true,
    },
    hobject: {
      handler: function(updatedValue) {},
      deep: true,
    },
    loopAnimation: {
      handler: function(updatedValue) {
        var abilities = JSON.parse(this.hobject.abilities)
        if (updatedValue) abilities.gltf_loader.loop = true
        else abilities.gltf_loader.loop = false
        this.hobject.abilities = JSON.stringify(abilities)
      },
    },
  },
  created() {
    console.log('[uploads/GlbUpload.vue] created ')
    console.log(this.hobject)
    if (!this.hobject.abilities)
      this.hobject.abilities =
        '{	"interactions": { "start_behavior": "enabled", "orientable_by_user": true, "resizable_by_user": true, "positionable_by_user": true, "rules": [{ "when": "anchorfound", "do": "enable" }]	},	"gltf_loader": { "type": "glb", "pre_cache": true, "auto_start": true, "loop": false	}}'
    // Use compression
    this.hobject.compress_glb = true
  },
  async mounted() {
    postscribe(
      '#gist',
      `<script type="module" src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"><\/script>`
    )
    postscribe(
      '#gist',
      `<script nomodule src="https://unpkg.com/@google/model-viewer/dist/model-viewer-legacy.js"><\/script>`
    )

    if (this.hobject.asset_uri) {
      this.filePondFile = [
        {
          source: this.hobject.asset_uri,
          options: {
            type: 'local',
          },
        },
      ]
      this.glbUrl = this.hobject.asset_uri
    }

    var abilities = JSON.parse(this.hobject.abilities)
    try {
      this.loopAnimation = abilities.gltf_loader.loop
    } catch (e) {
      e
    }
    try {
      this.selectedAnimation = abilities.gltf_loader.animation_clip_name
    } catch (e) {
      e
    }
  },
  methods: {
    getAlertColor(issues) {
      if (issues.numErrors > 0) return 'warning'
      else return 'warning'
    },
    tryCanvasScreenshot() {
      this.screenshotInterval = setInterval(this.takeCanvasScreenshot, 1000)
    },
    takeCanvasScreenshot() {
      try {
        var canvas = document.getElementsByTagName('model-viewer')[0].shadowRoot.getElementById('webgl-canvas')
        this.hobject.image = Utils.dataURItoBlob(canvas.toDataURL())
        clearInterval(this.screenshotInterval)
      } catch (e) {
        console.log(e)
      }
    },
    validate() {
      if (!this.hobject.data && !this.hobject.asset_uri) {
        this.errorMessageUpload = 'You must upload a glb file'
        return false
      } else if (!this.hobject.name) {
        this.errorMessageUpload = 'You must provide a name'
        return false
      } else {
        this.errorMessageUpload = ''
        return true
      }
    },
    showErrorOnScreen(title, errorMessage) {
      this.$vs.dialog({
        color: 'danger',
        title: title,
        text: errorMessage,
      })
      // remove file from file-pond
      this.$refs.pond.removeFile()
      this.glbUrl = null
      this.report = null
    },

    chooseFile: function() {
      document.getElementById('fileUpload').click()
    },
    fileAdded: function(error, file) {
      if (!this.hobject.name) {
        delete this.hobject.name
        this.$set(this.hobject, 'name', humanize(file.filename.replace(/\.[^/.]+$/, '')).substring(0, 63))
      }
    },
    fileRemoved: function(error, file) {
      // remove file from file-pond
      this.glbUrl = null
      this.report = null
      this.animationOptions = []
    },
    handleFilePondInit: function() {
      // FilePond instance methods are available on `this.$refs.pond`
    },
    async process(fieldName, file, metadata, load, error, progress, abort) {
      console.info('p1')
      this.isCompressingOptionsVisible = true

      this.glbUrl = this.hobject.asset_uri
      console.log(file)

      var objectURL = URL.createObjectURL(file)
      var self = this

      this.hobject.data = file
      this.$eventBus.$emit('hobjectChanged', this.hobject)

      try {
        this.glbUrl = objectURL
        var response = await fetch(objectURL)
        var asset = await response.arrayBuffer()
        this.report = await validator.validateBytes(new Uint8Array(asset))
        console.info('Validation succeeded: ', this.report)
        if (this.report.mimeType == 'model/gltf-binary')
          Object.defineProperty(file, 'name', {
            writable: true,
            value: 'model.glb',
          })
        else {
          Object.defineProperty(file, 'name', {
            writable: true,
            value: 'model.gltf',
          })
        }
        if (
          this.report.info &&
          this.report.info.extensionsUsed &&
          this.report.info.extensionsUsed.length > 0 &&
          this.report.info.extensionsUsed.includes('GOOGLE_tilt_brush_material')
        ) {
          self.showErrorOnScreen('Invalid GLB', 'Please embed your textures within the GLB file. \n')
          console.error('Validation failed: ', error)
          return
        }
      } catch (e) {
        console.error(e)
        self.showErrorOnScreen(
          'File not supported',
          'It looks like the file you are trying to upload is not a valid embeded GLTF. \n'
        )
        console.error('Validation failed: ', error)
        return
      }
      await this.validateGLB(objectURL)
      load(file)
      // Should expose an abort method so the request can be cancelled
      return {
        abort: () => {
          // Let FilePond know the request has been cancelled
          abort()
        },
      }
    },
    async load(source, load, error, progress, abort, headers) {
      var validation = this.validateGLB(source)
      var myRequest = new Request(source)
      var response = await fetch(myRequest)
      var myBlob = await response.blob()
      load(myBlob)
    },

    fetch(url, load, error, progress, abort, headers) {
      var myRequest = new Request(url)
      fetch(myRequest).then(function(response) {
        response.blob().then(function(myBlob) {
          load(myBlob)
        })
      })
    },
    remove(source, load, error) {
      console.log('REMOVE')
      this.hobject.asset_uri = null
      this.glbUrl = null
      this.animationOptions = []
      // Should call the load method when done, no parameters required
      load()
    },
    animationClipNames(animation_clip_names) {
      var abilities = JSON.parse(this.hobject.abilities)
      abilities.gltf_loader['animation_clip_names'] = animation_clip_names
      this.hobject.abilities = JSON.stringify(abilities)
    },
    materialVariantNames(material_variant_names) {
      console.log('VARIANTS' + material_variant_names)
      var abilities = JSON.parse(this.hobject.abilities)
      abilities.gltf_loader['material_variant_names'] = material_variant_names
      this.hobject.abilities = JSON.stringify(abilities)
    },
    async validateGLB(objectURL) {
      var self = this
      // Instantiate a loader
      const loader = new GLTFLoader()
      console.info('p1')
      // Optional: Provide a DRACOLoader instance to decode compressed mesh data
      const dracoLoader = new DRACOLoader()
      dracoLoader.setDecoderPath(`${window.location.origin}/js/draco/`)
      loader.setDRACOLoader(dracoLoader)

      await new Promise((resolve, reject) => {
        // Load a glTF resource
        loader.setMeshoptDecoder(MeshoptDecoder)

        loader.load(
          // resource URL
          objectURL,
          // called when the resource is loaded
          function (gltf) {
            // Animation clips
            if (gltf.animations && gltf.animations.length > 0) {
              var animationClipNames = []
              self.animationOptions = []
              self.isAnimationSupported = true
              gltf.animations.forEach(function(clip) {
                animationClipNames.push(clip.name)
                self.animationOptions.push(clip.name)
              })
              self.animationClipNames(animationClipNames)
              if (self.selectedAnimation == null) self.selectedAnimation = gltf.animations[0].name
              else {
                if (!animationClipNames.includes(self.selectedAnimation)) self.selectedAnimation = null
              }
            }
            console.log('VARIANTS')
            // Material Variants
            if (gltf.scene.children[0].material && gltf.scene.children[0].material.userData.variantNames) {
              var materialVariantNames = []
              gltf.scene.children[0].material.userData.variantNames.forEach(function(variant) {
                materialVariantNames.push(variant)
              })
              self.materialVariantNames(materialVariantNames)
            }

            self.triangles_count = HoverlayThreeUtils.getNumberOfTriangles(gltf.scene)
            // eslint-disable-next-line no-prototype-builtins
            resolve(true)
            // if (gltf.parser.extensions.hasOwnProperty('KHR_materials_unlit')) {
            //   self.showErrorOnScreen(
            //     'Extension not supported',
            //     'This GLTF is using the KHR_materials_unlit extension. We do not support this specific material yet. Please choose another model.'
            //   )
            //   reject(false)
            // } else {
            //   resolve(true)
            // }
          },
          // called while loading is progressing
          function(xhr) {
            // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
          },
          // called when loading has errors
          function(error) {
            console.log('An error happened' + error)
            self.showErrorOnScreen(
              'File not supported',
              'It looks like the file you are trying to upload is not a valid embeded GLTF or GLB.'
            )
            reject(false)
          }
        )
      })
    },
  },
}
</script>

<style lang="scss">
model-viewer {
  width: 100%;
  height: 400px;
  top: 0px;
  position: sticky;
}
.margin-top {
  margin-top: 25px;
}

.image-preview {
  height: 220px;
  width: 220px;
}
.square {
  position: relative;
  width: 100%;
}

.square:after {
  content: '';
  display: block;
  padding-bottom: 100%;
  height: 200px;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
