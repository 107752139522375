import * as THREE from 'three'

import * as AdvancedGreenScreenShader from '@/assets/js/shaders/advanced-green-screen-shader.js'

export default class ChromakeyMaterial extends THREE.ShaderMaterial {
  constructor(params, options) {
    // Default parameters
    super(params)
    this.vertexShader = AdvancedGreenScreenShader.vertexShader()
    this.fragmentShader = AdvancedGreenScreenShader.fragmentShader()
    this.name = 'ChromakeyShader'

    // Read custom chromakey options
    this.keyColor = options.keyColor || new THREE.Color(0, 1, 0)
    this.unityColor = new THREE.Color()
    this.convertGammaToLinear()

    this.texture = options.texture || null
    this.crop = options.crop || new THREE.Vector4(0, 0, 0, 0) // Cropping [Vec4.xyzw => top, bottom, left, right]

    // We need video dimensions to compare neighboring pixels
    this.videoWidth = options.videoWidth || 1000
    this.videoHeight = options.videoHeight || 1000
    this.pxFraction = new THREE.Vector2(1 / this.videoWidth, 1 / this.videoHeight)

    this.uniforms = {
      // Object uniforms
      _keyColor: { value: this.unityColor },
      _mainTex: { value: this.texture },
      _mainTexSize: { value: this.pxFraction },
      _crop: { value: this.crop },

      // Number uniforms
      _colorCutoff: { value: options.colorCutoff == null ? 0.5 : options.colorCutoff },
      _colorFeathering: { value: options.colorFeathering == null ? 0.5 : options.colorFeathering },
      _maskFeathering: { value: options.maskFeathering == null ? 1 : options.maskFeathering },
      _sharpening: { value: options.sharpening == null ? 0.5 : options.sharpening },
      _despill: { value: options.despill == null ? 0.0 : options.despill },
      _despillLuminanceAdd: { value: options.despillLuminanceAdd == null ? 0.0 : options.despillLuminanceAdd },
    }
  }

  // We must perform conversion to match Unity's color managemen
  convertGammaToLinear(color) {
    const gammaFactor = 2.2

    // Convert from Gamma to Linear
    // this.unityColor.r = Math.pow(this.keyColor.r, gammaFactor);
    // this.unityColor.g = Math.pow(this.keyColor.g, gammaFactor);
    // this.unityColor.b = Math.pow(this.keyColor.b, gammaFactor);

    // No conversion as we use Gamma color space in Unity
    if (color) {
      console.log(color)
      this.unityColor.r = color.r
      this.unityColor.g = color.g
      this.unityColor.b = color.b
    } else {
      console.log(color)

      this.unityColor.r = this.keyColor.r
      this.unityColor.g = this.keyColor.g
      this.unityColor.b = this.keyColor.b
    }
  }
}
