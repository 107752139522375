<!-- =========================================================================================
  File Name: FormVideo.vue
  Description: Form to edit the attributes of video hoverpack
========================================================================================== -->

<template>
  <div>
    <!-- {{ this.hobject.abilities }} -->
    <!-- {{ defaultUnit }} -->
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label for="" class="vs-input--label">{{ this.$t('HoverpackGltfAutoResize') }}</label>
        <v-select
          :clearable="false"
          v-model="defaultAutoResize"
          label="text"
          class="w-full"
          :options="autosizeOptions"
        />
      </div>

      <div class="vx-col w-2/3"></div>
      <!-- <div v-show="defaultAutoResize.value" class="vx-col w-1/3">
        <vs-input class="w-full" :label="this.$t('HoverpackHeight')" v-model.number="height" name="height" />
      </div>
      <div v-show="defaultAutoResize.value" class="vx-col w-1/3">
        <label for="" class="vs-input--label">{{this.$t('HoverpackUnit') }}</label>
        <v-select :clearable="false" v-model="defaultUnit" label="text" class="w-full" :options="unitOptions" />
      </div> -->
    </div>
    <form-physical-height
      v-if="defaultAutoResize.value"
      v-multi-ref:form
      v-bind:hobject="hobject"
      v-bind:abilityName="'gltf_loader'"
      v-bind:initialModel="initialModel"
    ></form-physical-height>

    <span v-bind:key="errorMessageUpload" v-for="errorMessageUpload in errorMessageUploads" class="text-danger"
      >{{ errorMessageUpload }}<br
    /></span>
    <span class="text-danger">{{ errors.first('step-2.name') }}</span>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import FormPhysicalHeight from './FormPhysicalHeight.vue'

export default {
  inject: ['$validator'],
  components: {
    'v-select': vSelect,
    FormPhysicalHeight,
  },
  props: {
    initialPlacement: {},
    hobject: {},
    initialModel: {},
    autoScaleGlb: {},
  },
  data() {
    return {
      defaultAutoResize: {
        text: 'Set custom height',
        value: true,
      },
      autosizeOptions: [
        {
          text: 'Set custom height',
          value: true,
        },
        {
          text: 'Keep original model height',
          value: false,
        },
      ],
      errorMessageUploads: [],
    }
  },
  watch: {
    defaultAutoResize: {
      handler: function(updatedValue) {
        this.autoScaleGlb = updatedValue.value
        console.log('defaultAutoResize')
        console.log(updatedValue.value)

        if (updatedValue.value == false)
          try {
            var abilities = JSON.parse(this.hobject.abilities)
            abilities.gltf_loader.height = null
            this.hobject.abilities = JSON.stringify(abilities)
          } catch (e) {
            console.log(e)
            this.height = 100
          }
      },
    },

    hobject: {
      handler: function(updatedValue) {},
    },
  },
  mounted() {},

  created() {
    console.log('GLTF FORM CREATED')
    console.log(this.hobject)
    var abilities = JSON.parse(this.hobject.abilities)
    if (!abilities.gltf_loader.height) {
      console.log('defaultAutoResize false')
      this.defaultAutoResize = {
        text: 'Keep original model height',
        value: false,
      }
      this.autoScaleGlb = false
    } else {
      console.log('defaultAutoResize true')
      this.defaultAutoResize = {
        text: 'Set custom height',
        value: true,
      }
      this.autoScaleGlb = true
    }
  },
  methods: {},
  validate() {
    this.errorMessageUploads = []
    return true
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}
</style>
