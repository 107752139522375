<!-- =========================================================================================
  File Name: FormVideo.vue
  Description: Form to edit the attributes of video hoverpack
========================================================================================== -->

<template>
  <div></div>
</template>

<script>
export default {
  inject: ['$validator'],
  props: {
    initialPlacement: {},
    hobject: {},
  },
  data() {
    return {
      model_identifier: 'hoverlay.core.Audio.1.0',
      errorMessageUploads: [],
    }
  },
  watch: {
    hobject: {
      handler: function(updatedValue) {
        this.$emit('setHobject', updatedValue)
      },
    },
  },
  created() {
    this.hobject.model_identifier = this.model_identifier
  },
  methods: {
    validate() {
      console.log('[FormAudio.vue] VALIDATION ' + true)
      return true
    },
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}
</style>
