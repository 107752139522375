function vertexShader() {
  return `
        varying vec2 vUv;

        void main() {
            vUv = uv;

            // Position
            vec4 mvPosition = vec4( position, 1.0 );
            mvPosition = modelViewMatrix * mvPosition;
            gl_Position = projectionMatrix * mvPosition;
        }
    `
}
function fragmentShader() {
  return `
        uniform vec3 _keyColor;
        uniform sampler2D _mainTex;
        uniform vec2 _mainTexSize;
        uniform float _colorCutoff;
        uniform float _colorFeathering;
        uniform float _maskFeathering;
        uniform float _sharpening;
        uniform float _despill;
        uniform float _despillLuminanceAdd;
        uniform vec4 _crop; //Cropping [Vec4.xyzw => top, bottom, left, right]

        varying vec2 vUv;

        // vec4 sRGBToLinear( in vec4 value ) {
        //     return vec4( mix( pow( value.rgb * 0.9478672986 + vec3( 0.0521327014 ), vec3( 2.4 ) ), value.rgb * 0.0773993808, vec3( lessThanEqual( value.rgb, vec3( 0.04045 ) ) ) ), value.a );
        // }

        // Chroma Function From Oliver Davies
        float rgb2y(vec3 c) {
            return (0.299 * c.r + 0.587 * c.g + 0.114 * c.b);
        }

        float rgb2cb(in vec3 c) {
            return (0.5 - 0.168736 * c.r - 0.331264 * c.g + 0.5 * c.b);
        }

        float rgb2cr(in vec3 c) {
            return (0.5 + 0.5 * c.r - 0.418688 * c.g - 0.081312 * c.b);
        }

        float colorclose(in float Cb_p, in float Cr_p, in float Cb_key, in float Cr_key, in float tola, in float tolb) {
            float temp = (Cb_key - Cb_p) * (Cb_key - Cb_p) + (Cr_key - Cr_p) * (Cr_key - Cr_p);
            float tola2 = tola * tola;
            float tolb2 = tolb * tolb;
            if (temp < tola2) return (0.0);
            if (temp < tolb2) return (temp - tola2) / (tolb2 - tola2);
            return 1.0;
        }

        float maskedTex2D(in sampler2D tex, in vec2 uv) {
            // Convert Gamma to Linear
            // vec4 texSample = sRGBToLinear(texture2D(tex, uv));

            // No conversion as we use Gamma color space in Unity
            vec4 texSample = texture2D(tex, uv);

            // Let's optimize by performing this calculation once
            float key_cb = rgb2cb(_keyColor);
            float key_cr = rgb2cr(_keyColor);
            float pix_cb = rgb2cb(texSample.rgb);
            float pix_cr = rgb2cr(texSample.rgb);

            return colorclose(pix_cb, pix_cr, key_cb, key_cr, _colorCutoff, _colorFeathering);
        }

        vec4 compute_chroma(in vec2 uv) {
            vec2 pxW = vec2(_mainTexSize.x, 0.0);
            vec2 pxH = vec2(0.0, _mainTexSize.y);

            vec4 color = texture2D(_mainTex, uv);

            // Check masking on all neighbors
            float c = maskedTex2D(_mainTex, uv);
            float r = maskedTex2D(_mainTex, uv + pxW);
            float l = maskedTex2D(_mainTex, uv - pxW);
            float d = maskedTex2D(_mainTex, uv + pxW);
            float u = maskedTex2D(_mainTex, uv - pxW);
            float rd = maskedTex2D(_mainTex, uv + pxW + pxH) * 0.707;
            float ru = maskedTex2D(_mainTex, uv + pxW - pxH) * 0.707;
            float ld = maskedTex2D(_mainTex, uv - pxW + pxH) * 0.707;
            float lu = maskedTex2D(_mainTex, uv - pxW - pxH) * 0.707;

            float blurContribution = (c + r + l + d + u + rd + ru + ld + lu) * 0.12774655;
            float smoothedMask = smoothstep(_sharpening, 1.0, mix(c, blurContribution, _maskFeathering));

            vec4 result = color * smoothedMask;

            // Despill
            float v = (2.0 * result.b + result.r) / 4.0;
            if (result.g > v) result.g = mix(result.g, v, _despill);
            vec4 dif = (color - result);
            float desaturatedDif = rgb2y(dif.xyz);

            result += mix(0.0, desaturatedDif, _despillLuminanceAdd);

            return vec4(result.xyz, smoothedMask);
        }

        void main() {
            // Perform cropping
            float cropped = step(_crop.z, vUv.x) * step(vUv.x, 1.0 - _crop.w) * step(_crop.y, vUv.y) * step(vUv.y, 1.0 - _crop.x);
            if (cropped < 0.5) discard;

            vec4 finalColor = compute_chroma(vUv);

            if (finalColor.a <= 0.0) discard;

            gl_FragColor.rgb = finalColor.rgb;
            gl_FragColor.a = finalColor.a;
        }
    `
}

export { vertexShader, fragmentShader }
