<!-- =========================================================================================
  File Name: uploads/VideoUpload.vue
  Description: Form to edit a youtube link
========================================================================================== -->

<template>
  <div>
    <h4>Green Screen Settings :</h4>
    <div id="container" class="mt-2 w-full">
      <canvas id="webgl-canvas"></canvas>
      <div id="controls"></div>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three'
import VideoPlane from './VideoPlane'
import { Pane } from 'tweakpane'
import * as Utils from '@/assets/js/utils/utils.js'

export default {
  inject: ['$validator'],
  props: {
    hobject: null,
    videoUrl: null,
  },
  data() {
    return {
      videoPlane: null,
      cam: null,
      renderer: null,
      scene: null,
      video: null,
      texture: null,
      offsetWidth: null,
      canvasElem: null,
    }
  },
  watch: {
    hobject: {
      handler(hobject) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
      },
      deep: true,
    },
  },
  computed: {},
  async mounted() {
    this.canvasElem = document.getElementById('webgl-canvas')
    this.offsetWidth = document.getElementById('container').offsetWidth
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvasElem,
      antialias: true,
      alpha: true,
    })

    this.renderer.outputEncoding = THREE.sRGBEncoding
    this.renderer.setPixelRatio(window.devicePixelRatio)

    this.cam = new THREE.PerspectiveCamera(45, 1, 0.1, 50)
    this.cam.position.z = 2.5
    this.scene = new THREE.Scene()
    this.scene.background = new THREE.Color(0xe2e2e2)

    // Helper vectors
    this.VP = new THREE.Vector3()
    this.mousePxPrev = new THREE.Vector2()
    this.mousePxDelta = new THREE.Vector2()
    this.mousePosNow = new THREE.Vector2(0, 0)
    this.mousePosTween = new THREE.Vector2()

    // Drag events
    this.canvasElem.addEventListener('mousedown', this.pointerdown)
    this.canvasElem.addEventListener('mousemove', this.pointermove)
    this.canvasElem.addEventListener('mouseup', this.pointerup)

    this.videoPlane = new VideoPlane(this.scene, this.videoUrl, JSON.parse(this.hobject.abilities).skin)
    await this.videoPlane.createMatAndAddToScene()
    this.video = this.videoPlane.video
    this.texture = this.videoPlane.texture

    this.bindGUI()
    this.onResize()
    this.animate()
  },
  created() {},
  methods: {
    bindGUI() {
      this.pane = new Pane({ title: 'Controls', container: document.getElementById('controls'), expanded: true })
      // Let VideoPlane add its own GUI controls
      this.videoPlane.bindGUI(this.pane)
      // Add GUI for background controls
      const sceneFolder = this.pane.addFolder({ title: 'Scene Properties (this will not be saved)', expanded: true })
      sceneFolder
        .addInput(
          {
            background: '0xe2e2e2',
          },
          'background',
          {
            label: 'Background Color',
          }
        )
        .on('change', ev => {
          console.log(ev.value)
          this.scene.background = new THREE.Color(ev.value)
        })
    },
    onResize() {
      const vpW = this.offsetWidth
      const vpH = 600
      this.VP.set(vpW, vpH, vpW / vpH)

      this.cam.aspect = this.VP.z
      this.cam.updateProjectionMatrix()
      this.renderer.setSize(this.VP.x, this.VP.y)
    },
    animate() {
      requestAnimationFrame(this.animate)
      this.renderer.render(this.scene, this.cam)
    },
    render() {
      if (this.video && this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
        if (this.texture) {
          // console.log('update')
          this.texture.needsUpdate = true
        }
      }
    },
    validate() {
      var params = this.videoPlane.mat1.uniforms
      var abilities = JSON.parse(this.hobject.abilities)
      abilities.skin.despill_luminance_add = params._despillLuminanceAdd.value
      abilities.skin.despill_strength = params._despill.value
      abilities.skin.sharpening = params._sharpening.value
      abilities.skin.mask_feathering = params._maskFeathering.value
      abilities.skin.color_feathering = params._colorFeathering.value
      abilities.skin.cut_off = params._colorCutoff.value
      abilities.skin.key_color = Utils.rgbToHex(
        params._keyColor.value.r * 255,
        params._keyColor.value.g * 255,
        params._keyColor.value.b * 255
      )
      // crop
      abilities.skin.crop_top = params._crop.value.x
      abilities.skin.crop_bottom = params._crop.value.y
      abilities.skin.crop_left = params._crop.value.z
      abilities.skin.crop_right = params._crop.value.w
      this.hobject.abilities = JSON.stringify(abilities)
      this.$emit('setHobject', this.hobject)
      return true
    },
  },

  components: {},
}
</script>

<style lang="scss">
#webgl-canvas {
  border-radius: 6px;
}
#container {
  position: relative;
  border-radius: 50%;
}
#controls {
  max-width: 350px;
  width: 40%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.7; /* for demo purpose  */
}
</style>
