<!-- =========================================================================================
  File Name: uploads/ImageUpload.vue
  Description: Image Upload Form
========================================================================================== -->

<template>
  <div>
    <hoverpacks-selection
      ref="objectSelection"
      v-bind:initialHobject="hobject"
      v-bind:initialAnchor="anchor"
    ></hoverpacks-selection>
    <vs-row vs-type="flex" vs-justify="center">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center">
        <div v-if="hobject.model_identifier" class="vx-col w-full mb-base">
          <vx-card :title="getCardTitleFromModelIdentifier(hobject.model_identifier)">
            <vs-col vs-type="flex" class="mb-6">
              <div class="vx-col w-1/2 mr-4">
                <span v-if="hobject.model_identifier == 'hoverlay.core.Portal.1.0'"
                  >Click
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://docs.spaces.hoverlay.com/1.0/content-creation-guidelines/360s"
                    >here</a
                  >
                  to learn more about 360s
                </span>
                <span v-if="hobject.model_identifier == 'hoverlay.core.UnityAssetBundle.1.0'">
                  To prepare and export Unity assets use
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://docs.spaces.hoverlay.com/1.0/content-creation-guidelines/unity3d"
                    >our plugin</a
                  >.
                </span>
                <image-upload
                  class="w-full"
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-if="hobject.model_identifier == 'hoverlay.core.Image.1.0'"
                ></image-upload>
                <cta
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-if="hobject.model_identifier == 'hoverlay.core.Button.1.0'"
                ></cta>
                <video-upload
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-if="hobject.model_identifier == 'hoverlay.core.Video.1.0'"
                ></video-upload>
                <chroma-video-upload
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-if="hobject.model_identifier == 'hoverlay.core.ChromaVideo.1.0'"
                ></chroma-video-upload>
                <audio-upload
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-if="hobject.model_identifier == 'hoverlay.core.Audio.1.0'"
                ></audio-upload>
                <glb-upload
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-bind:initialModel="model"
                  v-if="hobject.model_identifier == 'hoverlay.core.3dModel.1.0'"
                ></glb-upload>
                <uab-upload
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-bind:initialModel="model"
                  v-if="hobject.model_identifier == 'hoverlay.core.UnityAssetBundle.1.0'"
                ></uab-upload>
                <video-image-upload
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-bind:initialModel="model"
                  v-if="hobject.model_identifier == 'hoverlay.core.Portal.1.0'"
                ></video-image-upload>
                <form-audio
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-if="hobject.model_identifier == 'hoverlay.core.Audio.1.0'"
                ></form-audio>
                <form-text
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-bind:initialModel="model"
                  v-if="hobject.model_identifier == 'hoverlay.core.TextCard.1.0'"
                ></form-text>
              </div>
              <div class="vx-col w-1/2 ml-4">
                <form-name-description
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-bind:initialModel="model"
                ></form-name-description>
                <form-glb
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-bind:initialModel="model"
                  v-if="hobject.model_identifier == 'hoverlay.core.3dModel.1.0'"
                ></form-glb>
                <div v-if="hobject.model_identifier == 'hoverlay.core.Audio.1.0'">
                  <form-audio-volume
                    v-multi-ref:form
                    v-bind:hobject="hobject"
                    v-bind:initialModel="model"
                  ></form-audio-volume>
                  <form-change-audio-mode
                    v-multi-ref:form
                    v-bind:hobject="hobject"
                    v-bind:initialModel="model"
                  ></form-change-audio-mode>
                  <form-audio-controls
                    v-multi-ref:form
                    v-bind:hobject="hobject"
                    v-bind:initialModel="model"
                  ></form-audio-controls>
                </div>
                <div
                  v-if="
                    hobject.model_identifier == 'hoverlay.core.Image.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.Video.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.ChromaVideo.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.Button.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.TextCard.1.0'
                  "
                >
                  <form-physical-height
                    v-multi-ref:form
                    v-bind:hobject="hobject"
                    v-bind:abilityName="'skin'"
                    v-bind:initialModel="model"
                  ></form-physical-height>
                  <div
                    v-if="
                      hobject.model_identifier == 'hoverlay.core.Video.1.0' ||
                        hobject.model_identifier == 'hoverlay.core.ChromaVideo.1.0'
                    "
                  >
                    <form-video-volume
                      v-multi-ref:form
                      v-bind:hobject="hobject"
                      v-bind:initialModel="model"
                    ></form-video-volume>
                      <form-video-controls
                    v-multi-ref:form
                    v-bind:hobject="hobject"
                    v-bind:initialModel="model"
                  ></form-video-controls>
                    <div v-if="hobject.model_identifier == 'hoverlay.core.ChromaVideo.1.0'">
                      <form-chroma-settings
                        v-if="!isChromaKeyTweakerVisible"
                        v-multi-ref:form
                        v-bind:hobject="hobject"
                        v-bind:initialModel="model"
                      ></form-chroma-settings>
                      <form-change-chroma-key-algo
                        v-multi-ref:form
                        v-bind:hobject="hobject"
                        v-bind:initialModel="model"
                      ></form-change-chroma-key-algo>
                    </div>
                    <vs-divider />
                  </div>
                  <form-orientation
                    v-if="hobject.model_identifier != 'hoverlay.core.Portal.1.0'"
                    v-multi-ref:form
                    v-bind:hobject="hobject"
                    v-bind:initialModel="model"
                  ></form-orientation>
                </div>

                <div
                  v-if="
                    hobject.model_identifier == 'hoverlay.core.Image.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.Video.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.ChromaVideo.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.Portal.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.TextCard.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.3dModel.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.UnityAssetBundle.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.Button.1.0' ||
                      hobject.model_identifier == 'hoverlay.core.Audio.1.0'
                  "
                >
                  <form-interaction
                    v-multi-ref:form
                    v-bind:hobject="hobject"
                    v-bind:initialModel="model"
                  ></form-interaction>
                  <form-abilities v-if="isHobjectAbilitiesEnable" :hobject="hobject"></form-abilities>
                </div>
              </div>
            </vs-col>
            <div class="vx-row">
              <div class="vx-col w-full" v-if="hobject.model_identifier == 'hoverlay.core.ChromaVideo.1.0'">
                <chroma-key-tweaker
                  v-if="isChromaKeyTweakerVisible"
                  v-multi-ref:form
                  v-bind:hobject="hobject"
                  v-bind:videoUrl="greenScreenVideoUrl"
                ></chroma-key-tweaker>
              </div>
            </div>

            &nbsp;
          </vx-card>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import HoverpacksSelection from '@/views/hoverlay/components/HoverpacksSelection.vue'
import FormNameDescription from '@/views/hoverlay/components/objects-form/FormNameDescription.vue'
import FormVideoVolume from '@/views/hoverlay/components/objects-form/FormVideoVolume.vue'
import FormAudioVolume from '@/views/hoverlay/components/objects-form/FormAudioVolume.vue'
import FormChangeAudioMode from '@/views/hoverlay/components/objects-form/FormChangeAudioMode.vue'
import FormAudioControls from  '@/views/hoverlay/components/objects-form/FormAudioControls.vue'
import FormPhysicalHeight from '@/views/hoverlay/components/objects-form/FormPhysicalHeight.vue'
import FormOrientation from '@/views/hoverlay/components/objects-form/FormOrientation.vue'
import FormAbilities from '@/views/hoverlay/components/objects-form/FormAbilities.vue'
import FormInteraction from '@/views/hoverlay/components/objects-form/FormInteraction.vue'
import FormChromaSettings from '@/views/hoverlay/components/objects-form/FormChromaSettings.vue'
import Cta from '@/views/hoverlay/components/objects-form/CTA.vue'
import ImageUpload from '@/views/hoverlay/components/objects-form-upload/ImageUpload.vue'
import GlbUpload from '@/views/hoverlay/components/objects-form-upload/GlbUpload.vue'
import UabUpload from '@/views/hoverlay/components/objects-form-upload/UabUpload.vue'
import VideoUpload from '@/views/hoverlay/components/objects-form-upload/VideoUpload.vue'
import ChromaVideoUpload from '@/views/hoverlay/components/objects-form-upload/ChromaVideoUpload.vue'
import FormChangeChromaKeyAlgo from '@/views/hoverlay/components/objects-form/FormChangeChromaKeyAlgo.vue'
import ChromaKeyTweaker from '@/views/hoverlay/components/objects-form/ChromaKeyTweaker/Scene.vue'
import FormVideoControls from  '@/views/hoverlay/components/objects-form/FormVideoControls.vue'
import AudioUpload from '@/views/hoverlay/components/objects-form-upload/AudioUpload.vue'
import VideoImageUpload from '@/views/hoverlay/components/objects-form-upload/VideoImageUpload.vue'
import FormAudio from '@/views/hoverlay/components/objects-form/FormAudio.vue'
import FormGlb from '@/views/hoverlay/components/objects-form/FormGlb.vue'
import FormText from '@/views/hoverlay/components/objects-form/FormText.vue'

import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'
import * as Utils from '@/assets/js/utils/utils.js'

export default {
  components: {
    HoverpacksSelection,
    FormNameDescription,
    FormVideoVolume,
    FormAudioVolume,
    FormChangeAudioMode,
    FormAudioControls,
    FormPhysicalHeight,
    FormOrientation,
    FormAbilities,
    FormInteraction,
    FormChromaSettings,
    FormChangeChromaKeyAlgo,
    FormVideoControls,
    Cta,
    ImageUpload,
    GlbUpload,
    UabUpload,
    VideoUpload,
    ChromaVideoUpload,
    ChromaKeyTweaker,
    AudioUpload,
    VideoImageUpload,
    FormAudio,
    FormGlb,
    FormText,
  },
  props: {
    hobject: {},
    anchor: {},
    model: {},
  },
  data() {
    return {
      isHobjectAbilitiesEnable: HoverlayUtils.isHobjectAbilitiesEnable(),
      isChromaKeyTweakerVisible: false,
      greenScreenVideoUrl: null,
    }
  },
  watch: {
    space: {
      handler: function() {},
      deep: true,
    },
    hobject: {
      handler() {
        console.log('ComputeIsChromaKeyTweakerVisible')
        this.isChromaKeyTweakerVisible = this.ComputeIsChromaKeyTweakerVisible()
      },
      deep: true,
    },
    greenScreenVideoUrl: {
      handler(updatedValue) {
        this.isChromaKeyTweakerVisible = this.ComputeIsChromaKeyTweakerVisible()
      },
      deep: true,
    },
  },
  computed: {},
  created() {
    // usefull to toggle on/off the panel to adjust greenscreen settings
    this.$eventBus.$on('chromaKeyVideoUrlUpdated', this.onChromaKeyVideoUrlUpdated)
  },
  methods: {
    getCardTitleFromModelIdentifier(model_identifier) {
      switch (model_identifier) {
        case 'hoverlay.core.Audio.1.0':
          return this.$t('HoverpackAudioTitle')
        case 'hoverlay.core.Video.1.0':
          return this.$t('HoverpackVideoTitle')
        case 'hoverlay.core.ChromaVideo.1.0':
          return this.$t('HoverpackHologramTitle')
        case 'hoverlay.core.Image.1.0':
          return this.$t('HoverpackImageTitle')
        case 'hoverlay.core.Button.1.0':
          return this.$t('HoverpackButtonTitle')
        case 'hoverlay.core.3dModel.1.0':
          return this.$t('HoverpackModelTitle')
        case 'hoverlay.core.TextCard.1.0':
          return this.$t('HoverpackTextTitle')
        case 'hoverlay.core.Portal.1.0':
          return this.$t('HoverpackPortalTitle')
        case 'hoverlay.core.UnityAssetBundle.1.0':
          return this.$t('HoverpackUnityAssetBundleTitle')
        default:
          return ''
      }
    },
    async validate() {
      if (this.$refs.objectSelection.validate() == false) return false
      var success = true
      for (let index = 0; index < this.$refs.form.length; index++) {
        const el = this.$refs.form[index]
        if (el.hobject.model_identifier == this.hobject.model_identifier)
          if (el && el.validate) success = success && (await el.validate())
      }
      return success
    },
    ComputeIsChromaKeyTweakerVisible() {
      try {
        var abilities = JSON.parse(this.hobject.abilities)
        if (abilities.skin && abilities.skin.chroma_key_algorithm == 'advanced' && this.greenScreenVideoUrl) return true
        else return false
      } catch (e) {}
    },
    onChromaKeyVideoUrlUpdated(greenScreenVideoUrl) {
      this.greenScreenVideoUrl = greenScreenVideoUrl
    },
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}

.image-preview {
  height: 220px;
  width: 220px;
}
</style>
