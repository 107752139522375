<!-- =========================================================================================
    File Name: HoverpacksSelection.vue
    Description: Page to choose which hoverpacks the user want to create
========================================================================================== -->

<template>
  <div id="demo-basic-card">
    <br />
    <div class="grid-layout-container alignment-block">
      <vs-row vs-align="center" vs-type="flex" vs-justify="center" vs-w="12">
        <vs-col
          :key="index"
          v-for="(card, index) in cards"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2.4"
        >
          <vx-card class="mb-base mr-3 ml-3">
            <div slot="no-body">
              <img :src="card.img" alt="content-img" class="responsive card-img-top"/>
            </div>
            <h5 class="mb-2">{{ card.title }}</h5>
            <p class="text-grey">{{ card.subtitle }}</p>
            <p class="text-grey">{{ card.subtitle_2 }}</p>
            <button
              v-on:click="setHobject(card.model_identifier)"
              type="button"
              name="button"
              class="vs-component vs-button w-full mt-6 vs-button-null vs-button-gradient"
              style="background: linear-gradient(30deg, rgb(115, 103, 240) 0%, rgb(206, 159, 252) 100%)"
              :disabled="!card.visible"
            >
              <span class="vs-button-text vs-button--text">{{ card.visible ? $t('HoverpackAdd') : $t('HoverpackAddPaid') }}</span>
              <span
                class="vs-button-linex"
                style="top: auto; bottom: -2px; background: rgb(115, 103, 240); left: 50%; transform: translate(-50%)"
              ></span>
            </button>
          </vx-card>
        </vs-col>
      </vs-row>
    </div>
    <span v-for="errorMessageUpload in errorMessageUploads" v-bind:key="errorMessageUpload"
      ><br />
      <h4 class="text-danger">{{ errorMessageUpload }}</h4>
    </span>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css'
import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'
import { variants } from 'tailwindcss/stubs/defaultConfig.stub'

export default {
  inject: ['$validator'],
  components: {},
  props: {
    initialPlacement: {},
    initialHobject: {},
  },
  data() {
    return {
      hobject: this.initialHobject,
      bypassValidation: false,
      errorMessageUploads: [],
      userRole: HoverlayUtils.getUserRole(),
      placementCreationNeeded: false,
      existingCard: {
        img: require('@/assets/images/pages/create/existing.png'),
        title: 'Existing Asset',
        subtitle: 'Choose and select an existing asset from a list',
        type: 'existing',
      },
      cards: [
        {
          img: require('@/assets/images/pages/create/cutout.png'),
          title: this.$t('HoverpackSelectionImageTitle'),
          subtitle: this.$t('HoverpackSelectionImageDescription'),
          model_identifier: 'hoverlay.core.Image.1.0',
          visible: true,
        },
        {
          img: require('@/assets/images/pages/create/hologram.png'),
          title: this.$t('HoverpackSelectionHologramTitle'),
          subtitle: this.$t('HoverpackSelectionHologramDescription'),
          model_identifier: 'hoverlay.core.ChromaVideo.1.0',
          maintenance: false,
          visible: true,
        },
        {
          img: require('@/assets/images/pages/create/video.png'),
          title: this.$t('HoverpackSelectionVideoTitle'),
          subtitle: this.$t('HoverpackSelectionVideoDescription'),
          model_identifier: 'hoverlay.core.Video.1.0',
          visible: true,
          maintenance: false,
        },
        {
          img: require('@/assets/images/pages/create/gltf.png'),
          title: this.$t('HoverpackSelection3DTitle'),
          subtitle: this.$t('HoverpackSelection3DDescription'),
          model_identifier: 'hoverlay.core.3dModel.1.0',
          visible: true,
        },
        {
          img: require('@/assets/images/pages/create/portal.png'),
          title: this.$t('HoverpackSelectionPortalTitle'),
          subtitle: this.$t('HoverpackSelectionPortalDescription'),
          model_identifier: 'hoverlay.core.Portal.1.0',
          maintenance: false,
          visible: true,
        },
        {
          img: require('@/assets/images/pages/create/ctasingle.jpeg'),
          title: this.$t('HoverpackSelectionCTATitle'),
          subtitle: this.$t('HoverpackSelectionCTADescription'),
          model_identifier: 'hoverlay.core.Button.1.0',
          visible: true,
        },
        {
          img: require('@/assets/images/pages/create/audiosource.jpg'),
          title: this.$t('HoverpackSelectionAudioSourceTitle'),
          subtitle: this.$t('HoverpackSelectionAudioSourceDescription'),
          model_identifier: 'hoverlay.core.Audio.1.0',
          visible: true,
        },
        {
          img: require('@/assets/images/pages/create/text.png'),
          title: this.$t('HoverpackSelectionTextTitle'),
          subtitle: this.$t('HoverpackSelectionTextDescription'),
          model_identifier: 'hoverlay.core.TextCard.1.0',
          visible: true,
        },
        {
          img: require('@/assets/images/pages/create/assetbundles.png'),
          title: this.$t('HoverpackSelectionAssetBundlesTitle'),
          subtitle: this.$t('HoverpackSelectionAssetBundlesDescription'),
          model_identifier: 'hoverlay.core.UnityAssetBundle.1.0',
          visible: HoverlayUtils.isUnityAssetBundleUploadEnable(),
        },
      ],
    }
  },
  watch: {},
  computed: {
    cardsClass: function () {
      return 'vx-col w-full w-full sm:w-1/3 lg:w-1/5 mb-base'
      //return this.placementCreationNeeded ? 'vx-col w-full sm:w-1/3 lg:w-1/5 mb-base' : 'vx-col w-full sm:w-1/3 lg:w-1/4 mb-base';
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    },
    models() {
      if (this.$store.state.hoverlay) {
        console.log(this.$store.state.hoverlay.models)
        return this.$store.state.hoverlay.models
      } else return []
    },
  },
  mounted() {},
  created() {
    // this.$eventBus.$on('hobjectChanged', this.onHobjectChanged)

    console.log('this.$route.params.lat ')
    console.log(this.$route.params)

    if (this.$route.params.lat) this.placementCreationNeeded = true
  },
  beforeDestroy() {
    // this.$eventBus.$off('hobjectChanged')
  },
  methods: {
    findModel(provider, collection, name, version) {
      return this.models.find(
        model =>
          model.provider == provider && model.collection == collection && model.name == name && model.version == version
      )
    },
    onHobjectChanged(hobject) {
      console.log('onHobjectChanged -- ')
      console.log(hobject)

      this.hobject = hobject
    },
    validate() {
      console.log('validate = hoverpack selection ')
      console.log(this.hobject)
      if (this.hobject.pid) {
        return true
      }

      if (this.bypassValidation == true) return true

      this.errorMessageUploads = []
      this.errorMessageUploads.push('You must choose an object first')
      return false
    },
    setHobject(model_identifier) {
      var abilities = null
      var triangles_count = 0
      switch (model_identifier) {
        case 'hoverlay.core.Image.1.0':
          abilities = this.findModel('hoverlay', 'core', 'Image', '1.0').abilities
          triangles_count = 2
          break
        case 'hoverlay.core.Video.1.0':
          abilities = this.findModel('hoverlay', 'core', 'Video', '1.0').abilities
          triangles_count = 2
          break
        case 'hoverlay.core.ChromaVideo.1.0':
          abilities = this.findModel('hoverlay', 'core', 'ChromaVideo', '1.0').abilities
          triangles_count = 2
          break
        case 'hoverlay.core.3dModel.1.0':
          abilities = this.findModel('hoverlay', 'core', '3dModel', '1.0').abilities
          triangles_count = 0
          break
        case 'hoverlay.core.Button.1.0':
          abilities = this.findModel('hoverlay', 'core', 'Button', '1.0').abilities
          triangles_count = 1072
          break
        case 'hoverlay.core.Audio.1.0':
          abilities = this.findModel('hoverlay', 'core', 'Audio', '1.0').abilities
          triangles_count = 0
          break
        case 'hoverlay.core.UnityAssetBundle.1.0':
          abilities = this.findModel('hoverlay', 'core', 'UnityAssetBundle', '1.0').abilities
          triangles_count = 0
          break
        case 'hoverlay.core.TextCard.1.0':
          abilities = this.findModel('hoverlay', 'core', 'TextCard', '1.0').abilities
          triangles_count = 2
          break
        case 'hoverlay.core.Portal.1.0':
          abilities = this.findModel('hoverlay', 'core', 'Portal', '1.0').abilities
          triangles_count = 8192
          break
      }
      console.log('model_identifier')
      console.log(model_identifier)
      var hobject = {}
      hobject.abilities = abilities
      hobject.model_identifier = model_identifier
      hobject.triangles_count = triangles_count
      this.$eventBus.$emit('hobjectChanged', hobject)
      this.bypassValidation = true
      setTimeout(() => {
        window.scroll({
          top: 1000,
          behavior: 'smooth',
        })
      }, '300')

      // document.getElementsByClassName('wizard-footer-right')[0].childNodes[1].click()
    },
  },
}
</script>

<style lang="scss">
.wizard-footer-right {
  // display: none;
}

#demo-basic-card {
  .overlay-card {
    .vx-card__collapsible-content {
      max-height: 485px;
    }
  }

  .chat-card-log {
    height: 360px;

    .chat-sent-msg {
      background-color: #f2f4f7 !important;
    }
  }

  .card-video {
    .video-js {
      height: 370px;
    }
  }
}
</style>
