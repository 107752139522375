import * as THREE from 'three'
import * as Utils from './utils.js'
import * as DefaultGreenScreenShader from '@/assets/js/shaders/default-green-screen-shader.js'
import * as AdvancedGreenScreenShader from '@/assets/js/shaders/advanced-green-screen-shader.js'
import ChromakeyMaterial from '@/assets/js/materials/ChromakeyMaterial'

import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'
import * as CameraUtils from 'three/examples/jsm/utils/CameraUtils.js'
import hexRgb from 'hex-rgb'

function toggleMorphAttributes(obj, restore) {
  obj.traverse(c => {
    if (c.geometry) {
      if (restore) {
        c.geometry.morphAttributes = c.geometry.userData.morphAttributes || {}
        delete c.geometry.userData.morphAttributes
      } else {
        c.geometry.userData.morphAttributes = c.geometry.morphAttributes
        c.geometry.morphAttributes = {}
      }
      c.geometry.boundingBox = null
    }
  })
}
function expandByBoundingBox(box, otherBox) {
  const min = box.min
  const max = box.max
  const otherMin = otherBox.min
  const otherMax = otherBox.max

  if (otherMin.x < min.x) min.x = otherMin.x
  if (otherMin.y < min.y) min.y = otherMin.y
  if (otherMin.z < min.z) min.z = otherMin.z
  if (otherMax.x > max.x) max.x = otherMax.x
  if (otherMax.y > max.y) max.y = otherMax.y
  if (otherMax.z > max.z) max.z = otherMax.z
}

// Computes the bounding box of a model, taking into consideration the bones animation for skinned meshes
// Returns the computed bounding box
function computeBoundingBox(model) {

  var skinMeshFound = false
  
  model.traverse(function (child) {
    if (child.isSkinnedMesh) skinMeshFound = true
  })

  var bbox = new THREE.Box3().setFromObject(model)

  // compute bouding box differently if model contails bones animation
  if (skinMeshFound) {
    toggleMorphAttributes(model, false)
    // compute bounding box
    const meshes = []
    model.traverse(node => {
      if (node.isSkinnedMesh) {
        meshes.push(node)
      }
    })
    const vector = new THREE.Vector3()
    meshes.forEach(mesh => {
      mesh.computeBoundingBox() 
      expandByBoundingBox(bbox, mesh.boundingBox)
      // const position = mesh.geometry.attributes.position;

      // for (let i = 0, il = position.count; i < il; i++) {

      //   vector.fromBufferAttribute(position, i);
      //   mesh.applyBoneTransform(i, vector);
      //   // mesh.localToWorlsd(vector);
      //   bbox.expandByPoint(vector);
      // }
    })
    bbox.applyMatrix4(model.matrixWorld)
    toggleMorphAttributes(model, true)
  } else {
    bbox = new THREE.Box3().setFromObject(model)
  }
  return bbox
}

async function createCore3dModel(hobject, node, modelGroup, gltfLoader, mixerDictionnary) {
  var gltf = await gltfModelLoader(hobject.asset_uri, gltfLoader)
  var model = gltf.scene

  var bbox = computeBoundingBox(model)

  // compute bounding box
  var cent = bbox.getCenter(new THREE.Vector3())
  var size = bbox.getSize(new THREE.Vector3())
  var hobjectHeight = JSON.parse(hobject.abilities).gltf_loader.height
  if (!hobjectHeight) hobjectHeight = size.y

  var scalingRatio = size.y / hobjectHeight

  var loop = THREE.LoopOnce
  try {
    if (JSON.parse(hobject.abilities).gltf_loader.loop) loop = THREE.LoopRepeat
  } catch (e) {}
  // Play animation
  if (gltf.animations.length > 0) {
    mixerDictionnary[node.pid] = new THREE.AnimationMixer(gltf.scene)
    var animation = gltf.animations[0]
    // Find the right animation to play if defined otherwise play the first animation
    try {
      var abilities = JSON.parse(hobject.abilities)
      if (abilities.gltf_loader.animation_clip_name) {
        if (THREE.AnimationClip.findByName(gltf.animations, abilities.gltf_loader.animation_clip_name))
          animation = THREE.AnimationClip.findByName(gltf.animations, abilities.gltf_loader.animation_clip_name)
      }
    } catch (e) {
      console.log(e)
    }
    var action = mixerDictionnary[node.pid].clipAction(animation)
    action.clampWhenFinished = true
    action.loop = loop
    action.play()
  }

  // Process Material Variants
  // Details of the KHR_materials_variants extension used here can be found below
  // https://github.com/KhronosGroup/glTF/tree/master/extensions/2.0/Khronos/KHR_materials_variants
  const parser = gltf.parser;
  try {
    const variantsExtension = gltf.userData.gltfExtensions['KHR_materials_variants'];
    if (variantsExtension) {
      console.log('Variants Extension Found');
      // go through variants and print the name of each variant
      console.log(variantsExtension.variants);
    }
  } catch (e) {
      console.log(e)
  }
    // const variants = variantsExtension.variants.map((variant) => variant.name);
    // const variantMaterials = variantsExtension.variants.map((variant) => {
    //   const material = parser.getDependency('material', variant.material);
    //   console.log('Map' + variant.name);

    //   return material;
    // });
    // const variantMap = new Map();
    // variants.forEach((variant, index) => {
    //   variantMap.set(variant, variantMaterials[index]);
    // });
    // const variantSelector = parser.getDependency('variantSelector', variantsExtension.selector);
    // const variant = variantSelector.getVariant();
    // if (variant) {
    //   model.traverse((object) => {
    //     if (object.isMesh) {
    //       const variantMaterial = variantMap.get(variant);
    //       if (variantMaterial) {
    //         object.material = variantMaterial;
    //       }
    //     }
    //   });
    // }

  // Make sure the pivot point is at the very bottom of the loaded model
  try {
    if (JSON.parse(hobject.abilities).gltf_loader.set_pivot_point_at_bottom) {
      var paddingY = size.y / 2 - cent.y
      model.position.set(model.position.x, model.position.y + paddingY, model.position.z)
    }
  } catch (e) {}

  modelGroup.scale.set(
    modelGroup.scale.x / scalingRatio,
    modelGroup.scale.y / scalingRatio,
    modelGroup.scale.z / scalingRatio
  )
  return model
}

async function createCoreButton(hobject, modelGroup, gltfLoader) {
  var model
  var gltf = await gltfModelLoader(`${window.location.origin}/models/Button.glb`, gltfLoader)
  model = gltf.scene

  var hobjectThumbail = null
  if (hobject.original_image) hobjectThumbail = await Utils.loadImage(hobject.original_image)
  else if (hobject.asset_uri) hobjectThumbail = await Utils.loadImage(Utils.getCloudfrontUrlFromS3(hobject.asset_uri))
  else hobjectThumbail = await Utils.loadImage(Utils.getCloudfrontUrlFromS3(hobject.asset_uri))

  const texture = new THREE.TextureLoader().load(hobjectThumbail.src)
  // If texture is used for color information, set colorspace.
  texture.encoding = THREE.sRGBEncoding
  gltf.scene.traverse(function(child) {
    if (child.isMesh) {
      child.material.map = texture
    }
  })

  setPositionAndRotationGltf(hobject, model, modelGroup)
  return model
}
async function loadVideoTexture(dimensions, video, threeJSVideoComponents) {
  var videoWidth = dimensions[0]
  var videoHeight = dimensions[1]

  var videoImage = document.createElement('canvas')
  videoImage.crossOrigin = 'Anonymous'
  videoImage.width = videoWidth
  videoImage.height = videoHeight

  var videoImageContext = videoImage.getContext('2d')
  // background color if no video present
  videoImageContext.fillStyle = '#000000'
  videoImageContext.fillRect(0, 0, videoImage.width, videoImage.height)

  var videoTexture = new THREE.Texture(videoImage)
  videoTexture.minFilter = THREE.LinearFilter
  videoTexture.magFilter = THREE.LinearFilter

  threeJSVideoComponents.push({
    videoTexture: videoTexture,
    video: video,
    videoImageContext: videoImageContext,
  })
  return { videoWidth: videoWidth, videoHeight: videoHeight, videoTexture: videoTexture }
}
async function createCoreVideoWidthMetadata(hobject, node, dimensions, videoUrl, video, threeJSVideoComponents) {
  var res = await loadVideoTexture(dimensions, video, threeJSVideoComponents)
  var videoTexture = res.videoTexture
  var videoWidth = res.videoWidth
  var videoHeight = res.videoHeight

  // key_color
  let chromaKeyColor = hexRgb('#00ff00')
  try {
    chromaKeyColor = hexRgb(JSON.parse(hobject.abilities).skin.key_color)
  } catch (e) {}
  // chroma_transparency
  let videoTransparency = false
  try {
    videoTransparency = JSON.parse(hobject.abilities).skin.chroma_transparency
  } catch (e) {}
  let chromaKeyAlgorithm = 'default'
  try {
    if (JSON.parse(hobject.abilities).skin.chroma_key_algorithm)
      chromaKeyAlgorithm = JSON.parse(hobject.abilities).skin.chroma_key_algorithm
  } catch (e) {}

  let material
  // Use default ThreeJs ChromaKeyShader
  if (videoTransparency && chromaKeyAlgorithm == 'default')
    material = new THREE.ShaderMaterial({
      transparent: true,
      side: THREE.DoubleSide,
      uniforms: {
        map: { value: videoTexture },
        keyColor: { value: [chromaKeyColor.red / 255, chromaKeyColor.green / 255, chromaKeyColor.blue / 255] },
        similarity: { value: 0.7 },
        smoothness: { value: 0.0 },
      },
      vertexShader: DefaultGreenScreenShader.vertexShader(),
      fragmentShader: DefaultGreenScreenShader.fragmentShader(),
    })
  else if (chromaKeyAlgorithm == 'advanced') {
    // crop
    var top = JSON.parse(hobject.abilities).skin.crop_top || 0
    var bottom = JSON.parse(hobject.abilities).skin.crop_bottom || 0
    var left = JSON.parse(hobject.abilities).skin.crop_left || 0
    var right = JSON.parse(hobject.abilities).skin.crop_right || 0
    material = new ChromakeyMaterial(
      { transparent: true },
      {
        texture: videoTexture,
        keyColor: new THREE.Color(chromaKeyColor.red / 255, chromaKeyColor.green / 255, chromaKeyColor.blue / 255),
        colorCutoff: JSON.parse(hobject.abilities).skin.cut_off || 0.41,
        colorFeathering: JSON.parse(hobject.abilities).skin.color_feathering || 0.43,
        maskFeathering: JSON.parse(hobject.abilities).skin.mask_feathering || 0.95,
        sharpening: JSON.parse(hobject.abilities).skin.sharpening || 0.6,
        despill: JSON.parse(hobject.abilities).skin.despill_strength || 0.35,
        despillLuminanceAdd: JSON.parse(hobject.abilities).skin.despill_luminance_add || 1,
        crop: new THREE.Vector4(top, bottom, left, right),
        videoWidth: videoWidth,
        videoHeight: videoHeight,
      }
    )
  } else
    material = new THREE.MeshLambertMaterial({
      map: videoTexture,
      overdraw: true,
      side: THREE.DoubleSide,
    })

  let vertical_content_ratio_to_media = 1
  let ground_offset = 0
  try {
    vertical_content_ratio_to_media = JSON.parse(hobject.abilities).skin.vertical_content_ratio_to_media
  } finally {
    if (!vertical_content_ratio_to_media) vertical_content_ratio_to_media = 1
  }
  try {
    ground_offset = JSON.parse(hobject.abilities).skin.ground_offset
  } finally {
    if (!ground_offset) ground_offset = 0
  }
  var hobjectHeight = JSON.parse(hobject.abilities).skin.height
  hobjectHeight = JSON.parse(hobject.abilities).skin.height / vertical_content_ratio_to_media
  var hobjectWidth = hobjectHeight * (videoWidth / videoHeight)

  const geometry = new THREE.PlaneGeometry(hobjectWidth, hobjectHeight, 32)
  var model = new THREE.Mesh(geometry, material)
  model.castShadow = true
  // model.rotateZ(-Math.PI / 2)
  model.position.set(
    model.position.x,
    model.position.y + hobjectHeight / 2 - ground_offset * hobjectHeight,
    model.position.z
  )
  return model
}

async function createCoreVideo(hobject, node, threeJSVideoComponents) {
  var res = await createVideoElement(hobject, node, threeJSVideoComponents)
  return createCoreVideoWidthMetadata(hobject, node, res.dimensions, res.videoUrl, res.video, threeJSVideoComponents)
}

async function createVideoElement(hobject, node, threeJSVideoComponents) {
  var videoUrl = null
  videoUrl = Utils.getCloudfrontUrlFromS3(hobject.asset_uri)
  // create the video element
  var video = document.createElement('video')
  video.crossOrigin = 'Anonymous'
  video.src = videoUrl
  video.muted = true
  video.loop = true
  video.id = `video_${node.pid}`
  video.load() // must call after setting/changing source
  video.play()

  var promise = new Promise(function(resolve) {
    video.addEventListener(
      'loadedmetadata',
      function(event) {
        var video = event.target
        var dimensions = [video.videoWidth, video.videoHeight]
        resolve(dimensions)
      },
      { once: true }
    ) // remove event after run once
  })
  var dimensions = await promise
  return { dimensions: dimensions, videoUrl: videoUrl, video: video }
}

function renderPortal(
  thisPortalMesh,
  otherPortalMesh,
  thisPortalTexture,
  reflectedPosition,
  camera,
  portalCamera,
  bottomLeftCorner,
  bottomRightCorner,
  topLeftCorner,
  renderer,
  scene
) {
  // set the portal camera position to be reflected about the portal plane
  thisPortalMesh.worldToLocal(reflectedPosition.copy(camera.position))
  reflectedPosition.x *= -1.0
  reflectedPosition.z *= -1.0
  otherPortalMesh.localToWorld(reflectedPosition)
  portalCamera.position.copy(reflectedPosition)

  // grab the corners of the other portal
  // - note: the portal is viewed backwards; flip the left/right coordinates
  otherPortalMesh.localToWorld(bottomLeftCorner.set(50.05, -50.05, 0.0))
  otherPortalMesh.localToWorld(bottomRightCorner.set(-50.05, -50.05, 0.0))
  otherPortalMesh.localToWorld(topLeftCorner.set(50.05, 50.05, 0.0))
  // set the projection matrix to encompass the portal's frame
  CameraUtils.frameCorners(portalCamera, bottomLeftCorner, bottomRightCorner, topLeftCorner, false)

  // render the portal
  thisPortalTexture.texture.encoding = renderer.outputEncoding
  renderer.setRenderTarget(thisPortalTexture)
  renderer.state.buffers.depth.setMask(true) // make sure the depth buffer is writable so it can be properly cleared, see #18897
  if (renderer.autoClear === false) renderer.clear()
  thisPortalMesh.visible = false // hide this portal from its own rendering
  renderer.render(scene, portalCamera)
  thisPortalMesh.visible = true // re-enable this portal's visibility for general rendering
}
async function createCorePortalThumbnail(hobject, node, renderer, render, threeJSVideoComponents) {
  var abilities = JSON.parse(hobject.abilities)
  var texture
  if (abilities.skin.type == '360video') {
    var res = await createVideoElement(hobject, node, threeJSVideoComponents)
    res = await loadVideoTexture(res['dimensions'], res['video'], threeJSVideoComponents)
    texture = res.videoTexture
  } else {
    console.log(hobject.asset_uri)
    var hobjectThumbail = await Utils.loadImage(Utils.getCloudfrontUrlFromS3(hobject.asset_uri))
    texture = new THREE.TextureLoader().load(hobjectThumbail.src, render)
    texture.anisotropy = renderer.capabilities.getMaxAnisotropy()
  }
  let mat = new THREE.MeshBasicMaterial({
    map: texture,
    side: THREE.DoubleSide,
  })
  texture.center.set(0.5, 0.5)
  // texture.offset.x = 0.5 // 0.0 - 1.0
  texture.repeat.set(0.1, 0.3)
  // texture.offset.y = 0.5 // 0.0 - 1.0
  let door = new THREE.Mesh(new THREE.PlaneGeometry(1, 2), mat)
  door.position.y = 1
  var model = new THREE.Group()
  model.add(door)
  return { model: model, texture: texture }
}
async function createCorePortal(hobject, node, renderer, render, threeJSVideoComponents) {
  var abilities = JSON.parse(hobject.abilities)
  var texture
  if (abilities.skin.type == '360video') {
    var res = await createVideoElement(hobject, node, threeJSVideoComponents)
    res = await loadVideoTexture(res['dimensions'], res['video'], threeJSVideoComponents)
    texture = res.videoTexture
  } else {
    console.log(hobject.asset_uri)
    var hobjectThumbail = await Utils.loadImage(Utils.getCloudfrontUrlFromS3(hobject.asset_uri))
    texture = new THREE.TextureLoader().load(hobjectThumbail.src, render)
    texture.anisotropy = renderer.capabilities.getMaxAnisotropy()
  }

  // texture.rotation = THREE.MathUtils.degToRad(45)
  // texture.rotation = Math.PI
  // texture.flipY = true

  var model = new THREE.Group()
  let halfSphereGroup = new THREE.Group()
  halfSphereGroup.position.y = 1
  model.add(halfSphereGroup)

  let sphereRadius = 1.4
  let holeRadius = 0.5
  let borderThickness = 0.05

  let halfSphereGeometry = new THREE.SphereGeometry(sphereRadius, 32, 32, Math.PI, Math.PI) // startAngle, sweepAngle

  let cloakMaterial = new THREE.MeshBasicMaterial({
    map: texture,
    side: THREE.FrontSide,
    colorWrite: false,
  }) // change colorWrite: true to see the cloak
  cloakMaterial.castShadow = false
  let outerSphereMat = new THREE.MeshBasicMaterial({
    side: THREE.BackSide,
    visible: false,
  }) // change colorWrite: true to see the cloak
  let innerSphereMat = new THREE.MeshBasicMaterial({
    map: texture,
    side: THREE.BackSide,
  })
  let innerSphere = new THREE.Mesh(halfSphereGeometry, innerSphereMat)
  innerSphereMat.castShadow = false
  innerSphere.castShadow = false

  let outerSphere = new THREE.Mesh(halfSphereGeometry, outerSphereMat)
  let holeMeshleft = new THREE.Mesh(new THREE.PlaneGeometry(1, 3), cloakMaterial)
  let holeMesright = new THREE.Mesh(new THREE.PlaneGeometry(1, 3), cloakMaterial)
  let holeMeshtop = new THREE.Mesh(new THREE.PlaneGeometry(1, 0.5), cloakMaterial)
  let holeMesbottom = new THREE.Mesh(new THREE.PlaneGeometry(1, 0.5), cloakMaterial)

  holeMeshtop.position.y = 1.3
  holeMesbottom.position.y = -1.25
  holeMesright.position.x = +1
  holeMeshleft.position.x = -1
  // let holeMesh = new THREE.Mesh(new THREE.RingGeometry(holeRadius, sphereRadius * 1.01, 32), cloakMaterial)
  let borderMesh = new THREE.Mesh(
    new THREE.RingGeometry(holeRadius, holeRadius + borderThickness, 32),
    new THREE.MeshBasicMaterial({
      color: 0xffffff,
      side: THREE.DoubleSide,
    })
  )
  borderMesh.castShadow = false

  borderMesh.position.z = 0.001 // avoid depth-fighting artifacts

  halfSphereGroup.add(innerSphere)
  halfSphereGroup.add(outerSphere)
  halfSphereGroup.add(holeMeshleft)
  halfSphereGroup.add(holeMesright)
  halfSphereGroup.add(holeMeshtop)
  halfSphereGroup.add(holeMesbottom)

  // halfSphereGroup.add(borderMesh)
  return model
}
async function createCoreAudio(hobject, renderer, render) {
  // If not thumbnail is found add a default one
  if (!hobject.original_image) hobject.original_image = require('@/assets/images/hoverlay/hoverpacks/sound.png')
  return await createCoreImage(hobject, renderer, render)
}
async function createCoreImage(hobject, renderer, render) {
  var hobjectHeight = 1
  try {
    hobjectHeight = JSON.parse(hobject.abilities).skin.height
  } catch (e) {}
  var hobjectThumbail = null

  if (hobject.original_image) hobjectThumbail = await Utils.loadImage(hobject.original_image)
  else if (hobject.asset_uri) hobjectThumbail = await Utils.loadImage(Utils.getCloudfrontUrlFromS3(hobject.asset_uri))
  else hobjectThumbail = await Utils.loadImage(Utils.getCloudfrontUrlFromS3(hobject.asset_uri))

  var hobjectThumbnailPixelWidth = hobjectThumbail.naturalWidth
  var hobjectThumbnailPixelHeight = hobjectThumbail.naturalHeight
  const texture = new THREE.TextureLoader().load(hobjectThumbail.src, render)
  texture.anisotropy = renderer.capabilities.getMaxAnisotropy()
  // texture.rotation = Math.PI
  // texture.flipY = true
  var material = new THREE.MeshLambertMaterial({
    alphaTest: 0.5,
    map: texture,
    transparent: true,
    side: THREE.DoubleSide,
  })

  var hobjectWidth = hobjectHeight * (hobjectThumbnailPixelWidth / hobjectThumbnailPixelHeight)
  const geometry = new THREE.PlaneGeometry(hobjectWidth, hobjectHeight, 32)

  var model = new THREE.Mesh(geometry, material)
  model.castShadow = true

  model.customDepthMaterial = new THREE.MeshDepthMaterial({
    depthPacking: THREE.RGBADepthPacking,
    map: texture,
    alphaTest: 0.5,
  })

  // model.rotateZ(-Math.PI / 2)
  model.position.set(model.position.x, model.position.y + hobjectHeight / 2, model.position.z)
  return model
}

function gltfModelLoader(url, gltfLoader) {
  return new Promise((resolve, reject) => {
    // Instantiate a loader

    // Load a glTF resource
    gltfLoader.load(
      // resource URL
      url,
      // called when the resource is loaded
      function(gltf) {
        gltf.scene.traverse(function(child) {
          if (child.isMesh) {
            child.castShadow = true
          }
        })
        
        resolve(gltf)
      },
      // called while loading is progressing
      function(xhr) {
        // - // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
      },
      // called when loading has errors
      function(error) {
        // console.log(error)
        reject(error)
      }
    )
  })
}

function setPositionAndRotationGltf(hobject, model, modelGroup) {
  var hobjectHeight
  var scalingRatio
  hobjectHeight = JSON.parse(hobject.abilities).skin.height
  if (!hobjectHeight) hobjectHeight = 1

  var bbox = new THREE.Box3().setFromObject(model)
  var cent = bbox.getCenter(new THREE.Vector3())
  var size = bbox.getSize(new THREE.Vector3())
  bbox.setFromObject(model)
  bbox.getCenter(cent)
  bbox.getSize(size)
  scalingRatio = size.y / hobjectHeight

  try {
    var is_set_pivot_point_at_bottom = JSON.parse(hobject.abilities).gltf_loader.set_pivot_point_at_bottom
    if (is_set_pivot_point_at_bottom == true) {
      var paddingY = size.y / 2 - cent.y
      model.position.set(model.position.x, model.position.y + paddingY, model.position.z)
    }
  } catch (e) {}

  // var helper = new THREE.Box3Helper(bbox, 0xff0000)
  // this.scene.add(helper)

  modelGroup.scale.set(
    modelGroup.scale.x / scalingRatio,
    modelGroup.scale.y / scalingRatio,
    modelGroup.scale.z / scalingRatio
  )
}

function loadReflectionProbe(context) {
  var loader = new RGBELoader().load(`${window.location.origin}/images/three/studio_small_1k.hdr`, function(texture) {
    texture.mapping = THREE.EquirectangularReflectionMapping
    context.scene.environment = texture
  })
}

function getNumberOfTriangles(object) {
  var triangles_count = 0
  object.traverseVisible(function(object) {
    if (object.isMesh) {
      const geometry = object.geometry
      if (geometry.index !== null) {
        triangles_count += geometry.index.count / 3
      } else {
        triangles_count += geometry.attributes.position.count / 3
      }
    }
  })
  return triangles_count
}

function eulerToQuaternion(angle_x, angle_y, angle_z) {
  // Convert angles from degrees to radians
  let xRad = angle_x * Math.PI / 180;
  let yRad = angle_y * Math.PI / 180;
  let zRad = angle_z * Math.PI / 180;

  // Compute the quaternion
  let cy = Math.cos(zRad * 0.5);
  let sy = Math.sin(zRad * 0.5);
  let cp = Math.cos(yRad * 0.5);
  let sp = Math.sin(yRad * 0.5);
  let cr = Math.cos(xRad * 0.5);
  let sr = Math.sin(xRad * 0.5);

  let w = cr * cp * cy + sr * sp * sy;
  let x = sr * cp * cy - cr * sp * sy;
  let y = cr * sp * cy + sr * cp * sy;
  let z = cr * cp * sy - sr * sp * cy;

  return { x, y, z, w };
}

// Helper function to format numbers, removing any trailing zeros and the decimal point if there are no digits after it.
function formatFloatWithPrecision(value, precision) {
  // Format the number to the specified precision and remove trailing zeros
  const formattedString = parseFloat(value).toFixed(precision).replace(/\.?0+$/, '');
  // Parse the formatted string back to a float
  return parseFloat(formattedString);
}

// Function to convert a threejs object to a unity object transform
function threejsObjectToUnity(t) {
  // Set the prevision for x,y,z. If x,y or z are less than 0.1, set precision to 3 decimal places. Otherwise 2 decimal places.
  let precisionX = t.position.x < 0.1 ? 3 : 2;
  let precisionY = t.position.y < 0.1 ? 3 : 2;
  let precisionZ = t.position.z < 0.1 ? 3 : 2;

  return {
    x: formatFloatWithPrecision(t.position.x, precisionX),
    y: formatFloatWithPrecision(t.position.y, precisionY),
    z: formatFloatWithPrecision(-t.position.z, precisionZ),
    quaternion_x: t.quaternion.x,
    quaternion_y: t.quaternion.y,
    quaternion_z: -t.quaternion.z,
    quaternion_w: -t.quaternion.w,
    angle_x: formatFloatWithPrecision(parseFloat(-t.rotation.x) * (180 / Math.PI), 1),
    angle_y: formatFloatWithPrecision(parseFloat(-t.rotation.y) * (180 / Math.PI), 1),
    angle_z: formatFloatWithPrecision(parseFloat(t.rotation.z) * (180 / Math.PI), 1),
    scale: formatFloatWithPrecision(t.scale.x, 1),
  };
}

// Function to convert a threejs object to a unity object transform
function threejsMatrixToUnity(m) {

  var position = new THREE.Vector3()
  var quaternion = new THREE.Quaternion()
  var scale = new THREE.Vector3()
  m.decompose(position, quaternion, scale)

  return {
    x: parseFloat(position.x),
    y: parseFloat(position.y),
    z: -parseFloat(position.z),
    quaternion_x: quaternion.x,
    quaternion_y: quaternion.y,
    quaternion_z: -quaternion.z,
    quaternion_w: -quaternion.w,
    scale: parseFloat(scale.x.toFixed(2)),
  }
}

export {
  expandByBoundingBox,
  computeBoundingBox,
  createCore3dModel,
  createCoreButton,
  createCoreVideoWidthMetadata,
  createCoreImage,
  createCoreVideo,
  createCorePortal,
  loadReflectionProbe,
  renderPortal,
  createCorePortalThumbnail,
  createCoreAudio,
  getNumberOfTriangles,
  eulerToQuaternion,
  threejsObjectToUnity,
  threejsMatrixToUnity
}
