<!-- =========================================================================================
  File Name: FormNameDescription.vue
  Description: Form to edit the attributes name and desccription of a hobject
========================================================================================== -->

<template>
  <div>
    <!-- {{ hobject }} -->
    <div class="vx-row mb-6">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full"
          data-vv-name="name"
          name="name"
          v-validate="{ max: 64, required: true }"
          :label="this.$t('Name')"
          v-model="hobject.name"
        />
        <span class="text-danger">{{ errors.first('name') }}</span>
      </div>
      <div class="vx-col w-1/2">
        <vs-input class="w-full" label="Description" v-model="hobject.description" />
      </div>
    </div>

    <span v-bind:key="errorMessageUpload" v-for="errorMessageUpload in errorMessageUploads" class="text-danger"
      >{{ errorMessageUpload }}<br /><br
    /></span>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  props: {
    initialPlacement: {},
    hobject: {},
  },
  data() {
    return {
      errorMessageUploads: [],
    }
  },
  watch: {
    hobject: {
      handler: function(updatedValue) {
        this.$emit('setHobject', updatedValue)
      },
    },
  },
  created() {},
  methods: {
    validate() {
      if (this.errors.items.length > 0) return false
      else return true
    },
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}
</style>
