<!-- =========================================================================================
  File Name: EditHoverpack.vue
  Description: Form to edit hobject, placement and anchor in the same form
========================================================================================== -->

<template>
  <div>
    <div v-if="referenceImageScore != null" class="vx-col w-full mb-2">
      <vs-alert v-if="referenceImageScore == -1" color="danger" title="Trackability Score" active="true" class="mt-3">
        Trackability score could not be computed at this time.
      </vs-alert>
      <vs-alert
        v-else-if="referenceImageScore == -2"
        color="danger"
        title="Trackability Score"
        active="true"
        class="mt-3"
      >
        Image lacks features, contrast lines or edges.
        <a
          href="https://developers.google.com/ar/develop/c/augmented-images#best_practices"
          target="_blank"
          rel="nofollow"
          >Click here
        </a>
        to learn how to improve your reference image.
      </vs-alert>
      <vs-alert
        v-else-if="referenceImageScore < 25"
        color="danger"
        title="Trackability Score"
        active="true"
        class="mt-3"
      >
        Your image quality score is <strong> {{ referenceImageScore }} / 100. </strong>
        <a
          href="https://developers.google.com/ar/develop/c/augmented-images#best_practices"
          target="_blank"
          rel="nofollow"
          >Click here
        </a>
        to learn how to improve your reference image.
      </vs-alert>
      <vs-alert
        v-else-if="referenceImageScore >= 25 && referenceImageScore <= 75"
        color="warning"
        title="Trackability Score"
        active="true"
        class="mt-3"
      >
        Your image quality score is <strong> {{ referenceImageScore }} / 100. </strong>
        <a
          href="https://developers.google.com/ar/develop/c/augmented-images#best_practices"
          target="_blank"
          rel="nofollow"
          >Click here
        </a>
        to learn how to improve your reference image.
      </vs-alert>
      <vs-alert v-else color="success" title="Trackability Score" active="true" class="mt-3">
        Your image quality score is <strong> {{ referenceImageScore }} / 100</strong>.
      </vs-alert>
    </div>
    <!-- <br /> -->

    <file-pond
      :imagePreviewHeight="200"
      name="test"
      ref="pond"
      :label-idle="this.$t('HoverpackImageDrag')"
      :server="{ process, revert, restore, load, fetch }"
      :allow-multiple="false"
      accepted-file-types="image/jpeg, image/png"
      :files="myFiles"
      v-on:removefile="fileRemoved"
      v-on:addfile="fileAdded"
      v-on:init="handleFilePondInit"
    />
    <span class="text-danger">{{ errors.first('step-1.image_upload') }}</span>
    <span v-if="errorMessageUpload" class="text-danger">{{ errorMessageUpload }}</span>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          data-vv-name="name"
          name="name"
          v-validate="{ max: 64, required: true }"
          :label="this.$t('Name')"
          v-model="anchor.name"
        />
        <span class="text-danger">{{ errors.first('name') }}</span>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col xl:w-1/2">
        <vs-input
          class="w-full"
          :label="isImageAnchor() ? 'Real world height of the stencil' : 'Real world height of the reference image'"
          v-model.number="height"
          name="height"
        />
      </div>
      <div class="vx-col xl:w-1/2">
        <label for="" class="vs-input--label">{{ this.$t('HoverpackUnit') }}</label>
        <v-select :clearable="false" v-model="defaultUnit" label="text" class="w-full" :options="unitOptions" />
      </div>
    </div>
    <span v-for="errorMessageUpload in errorMessageUploads" v-bind:key="errorMessageUpload" class="text-danger"
      >{{ errorMessageUpload }}<br
    /></span>
    <span class="text-danger">{{ errors.first('step-2.name') }}</span>

    <div v-if="isImageAnchor()" class="vx-row mt-6">
      <div class="vx-col w-full">
      <label v-if="anchorAlwaysTracking == false" for="" class="vs-input--label"
        >The reference image is static. (ie street art, mural)</label
      >
      <label v-else for="" class="vs-input--label"
        >The reference image is moving and your content should follow it (ie card) - This feature is more computation
        intensive</label
      >
      <vs-switch color="primary" v-model="anchorAlwaysTracking">
        <span slot="on">Moving</span>
        <span slot="off">Static</span>
      </vs-switch>
      </div>
    </div>
  </div>
</template>

<script>
import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'
import * as Utils from '@/assets/js/utils/utils.js'
import imageCompression from 'browser-image-compression'

import vSelect from 'vue-select'

// Import Vue FilePond
import vueFilePond from 'vue-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
)

export default {
  inject: ['$validator'],
  props: {
    anchor: null,
    convertImageToData64: null,
    initialReferenceImageOrientation: null,
  },
  data() {
    return {
      EditHobjectOnly: false,
      placement: null,
      hobject: null,
      model: null,
      userRole: HoverlayUtils.getUserRole(),
      // File
      myFiles: [],
      // Form
      errorMessageUpload: null,
      // Anchor
      anchorAlwaysTracking: true,
      // Height
      measurement: this.$store.state.AppActiveUser.measurement,
      defaultHeight: null,
      defaultHeightInInches: 30,
      defaultHeightInCentimeter: 100,
      height: null,
      heightInMeter: null,
      defaultUnit: 'cm',
      unitOptions: [
        {
          text: 'cm',
          value: 'cm',
        },
        {
          text: 'inches',
          value: 'inches',
        },
      ],
      errorMessageUploads: [],
      referenceImageScore: null,
    }
  },
  computed: {
    placements() {
      return this.$store.state.hoverlay.placements
    },
    anchors() {
      return this.$store.state.hoverlay.anchors
    },
    hobjects() {
      return this.$store.state.hoverlay.hobjects
    },
    models() {
      return this.$store.state.hoverlay.models
    },
  },
  watch: {
    referenceImageOrientation: {
      handler: function() {
        if (this.referenceImageOrientation && this.referenceImageOrientation.value)
          this.$emit('referenceImageOrientationChanged', this.referenceImageOrientation.value)
      },
    },
    defaultUnit: {
      handler: function() {
        try {
          this.ComputeHeight(this.height)
        } catch (e) {
          console.log(e)
        }
      },
    },
    height: {
      handler: function(updatedValue) {
        try {
          this.ComputeHeight(updatedValue)
        } catch (e) {
          console.log(e)
        }
      },
    },

    anchorAlwaysTracking: {
      handler: function(updatedValue) {
        var mark = JSON.parse(this.anchor.mark)
        mark.trigger_only = !updatedValue
        this.anchor.mark = JSON.stringify(mark)
      },
    },
  },
  created() {
    this.referenceImageOrientation = this.initialReferenceImageOrientation
    this.init()
  },
  mounted() {},
  methods: {
    init() {
      // Add anchor image to filepond
      if (this.anchor.data)
        this.myFiles = [
          {
            source: this.anchor.data,
            options: {
              type: 'local',
            },
          },
        ]

      // Set system of measurement
      if (this.measurement == null || this.measurement == 'metric') {
        this.defaultUnit = {
          text: 'cm',
          value: 'cm',
        }
        this.defaultHeight = this.defaultHeightInCentimeter
      }
      if (this.measurement == 'imperial') {
        this.defaultUnit = {
          text: 'inches',
          value: 'inches',
        }
        this.defaultHeight = this.defaultHeightInInches
      }

      // set the checkbox with the appropriate value
      var mark = JSON.parse(this.anchor.mark)
      this.anchorAlwaysTracking = !mark.trigger_only

      // set height
      try {
        var h = mark.height
        if (h == null) {
          this.height = this.defaultHeight
        } else {
          if (this.measurement == null || this.measurement == 'metric') this.height = (h * 100).toFixed(2)
          if (this.measurement == 'imperial') this.height = (h * 100 * 0.393701).toFixed(2)
        }
        if (!this.height) this.height = this.defaultHeight
        this.heightInMeter = this.height / 100
      } catch (e) {
        console.log(e)
        this.height = this.defaultHeight
      }
    },
    ComputeHeight(height) {
      console.log(this.defaultUnit.value)
      let heightInMeter = null
      if (isNaN(this.height)) {
        heightInMeter = 1
      } else if (this.defaultUnit.value === 'inches') {
        heightInMeter = parseFloat(height) * 0.0254
      } else {
        heightInMeter = parseFloat(height) / 100
      }

      var mark = JSON.parse(this.anchor.mark)
      mark.height = heightInMeter
      this.anchor.mark = JSON.stringify(mark)
    },

    fileRemoved: function(error, file) {
      console.log('fileRemoved')
      this.referenceImageScore = null
    },
    async fileAdded(error, file) {
      var data64
      var imageFile = file.file

      //
      // Autofill the name of the anchor if none is given
      //
      if (!this.anchor.name) {
        delete this.anchor.name
        this.$set(this.anchor, 'name', file.filename.replace(/\.[^/.]+$/, ''))
      }

      //
      // Get Reference Image Score
      //
      if (this.isImageAnchor()) {

        if (imageFile.size / 1024 / 1024 > 5) {
          console.log('originalFile instanceof Blob', imageFile instanceof Blob) // true
          console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`)

          var options = {
            maxSizeMB: 2,
            useWebWorker: true,
          }
          var fileCompressed = await imageCompression(imageFile, options)
          data64 = await Utils.toBase64(fileCompressed)
        } else data64 = await Utils.toBase64(imageFile)
        var res = await this.$store.dispatch('hoverlay/getReferenceImageScore', data64)
        if (res.status == 'failed') {
          this.referenceImageScore = -2
        } else {
          var score = parseInt(res.res)
          if (isNaN(score)) this.referenceImageScore = -1
          else this.referenceImageScore = parseInt(res.res)
        }
      }
    },
    handleFilePondInit: function() {
      console.log('FilePond has initialized')
      // FilePond instance methods are available on `this.$refs.pond`
    },
    async process(fieldName, file, metadata, load, error, progress, abort) {
      // get image dimmension and compute maxWidthOrHeight so that the min height or width is 512
      var url = URL.createObjectURL(file)
      var img = new Image()
      img.src = url
      await img.decode()
      var ratio
      var maxWidthOrHeight
      if (img.width > img.height) {
        ratio = img.height / 512
        maxWidthOrHeight = img.width / ratio
      } else {
        ratio = img.width / 512
        maxWidthOrHeight = img.height / ratio
      }

      const options = {
        maxWidthOrHeight: maxWidthOrHeight,
        useWebWorker: true,
      }
      const compressedFile = await imageCompression(new File([file], file.name, { type: file.type }), options)

      if (this.convertImageToData64) this.anchor.data = await Utils.toBase64(compressedFile)
      else this.anchor.data = compressedFile
      this.$eventBus.$emit('anchorChanged', this.anchor)

      load(compressedFile)
      // Should expose an abort method so the request can be cancelled
      return {
        abort: () => {
          // Let FilePond know the request has been cancelled
          abort()
        },
      }
    },
    scale(W, H, max) {
      var min = Math.min(W, H), // Get the smallest size
        nr = min > max, // NeededResize (Boolean)
        rat = Math.min(max / W, max / H) // Ratio
      return {
        W: nr ? W * rat : W, // if NeededResize do W*rat, else just use the image W
        H: nr ? H * rat : H,
      }
    },
    load(source, load, error, progress, abort, headers) {
      var myRequest = new Request(source)
      fetch(myRequest).then(function(response) {
        response.blob().then(function(myBlob) {
          load(myBlob)
        })
      })
    },

    fetch(url, load, error, progress, abort, headers) {
      var myRequest = new Request(url)
      fetch(myRequest).then(function(response) {
        response.blob().then(function(myBlob) {
          load(myBlob)
        })
      })
    },

    restore(uniqueFileId, load, error, progress, abort, headers) {
      // error();
    },

    revert(uniqueFileId, load, error) {
      // load();
    },
    getReferenceImageScoreColor(score) {
      console.log('getReferenceImageScoreColor')
      console.log(score)
      if (score > 66) {
        return 'success'
      } else if (score < 66 && score > 33) return 'warning'
      else return 'danger'
    },
    isImageAnchor() {
      return this.anchor.anchor_type == 'image' || this.anchor.anchor_type == 'vuforia-instant-image-target'
    },
  },
  components: { vSelect },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}
</style>
