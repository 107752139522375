<!-- =========================================================================================
  File Name: FormWizard.vue
  Description: Form wizard with validation
========================================================================================== -->

<template>
  <vx-card class="w-full">
    <init-data></init-data>
    <!-- {{ space }}
    <br />
    {{ node }}
    <br />
    {{ anchor }}
    <br />
    {{ hobject }}
    <br /> -->
    <!-- <vx-card title="Publishing Wizard"> -->

    <!-- <p>Implement Form validation with form wizard using popular <strong> <router-link to="/forms/form-validation">VeeValidate</router-link></strong></p> -->
    <div class="mt-5">
      <form-wizard
        color="rgba(var(--vs-primary), 1)"
        errorColor="rgba(var(--vs-danger), 1)"
        title=""
        subtitle=""
        finishButtonText="Save"
      >
        <!-- tab map content -->
        <tab-content
          v-if="!createHobjectOnly && !addHobjectToExistingSpace"
          :title="this.$t('WhereWhen')"
          class="mb-5"
          icon="feather icon-map"
          :before-change="validateStep1"
        >
          <where
            ref="formTab1"
            v-bind:space="space"
            v-bind:spaces="spaces"
            v-bind:initialHobject="hobject"
            v-bind:initialAnchor="anchor"
          ></where>
        </tab-content>

        <!-- tab hobject selection content -->
        <tab-content :title="this.$t('What')" class="mb-5" icon="feather icon-box" :before-change="validateStep2">
          <div v-if="!createHobjectOnly">
            <!-- <h4>{{ $t('HoverpackSelectionTitle') }}</h4> -->
            <vs-tabs alignment="fixed">
              <vs-tab id="create-new-object-tab" label="Create a new object">
                <!-- <hoverpacks-selection ref="formTab2" v-bind:initialHobject="hobject"></hoverpacks-selection> -->
                <object-creation
                  ref="objectCreationRef"
                  v-bind:model="model"
                  v-bind:hobject="hobject"
                  v-bind:anchor="anchor"
                ></object-creation>
              </vs-tab>
              <vs-tab label="Re-use an existing object">
                <form-select-existing-hobject
                  ref="formTab2"
                  v-bind:spaces="spaces"
                  v-bind:nodes="nodes"
                  v-bind:initialHobject="hobject"
                ></form-select-existing-hobject>
              </vs-tab>
            </vs-tabs>
          </div>
          <div v-else>
            <object-creation
              ref="objectCreationRef"
              v-bind:model="model"
              v-bind:hobject="hobject"
              v-bind:anchor="anchor"
            ></object-creation>
          </div>
        </tab-content>

        <!-- tab hobject properties content -->
        <tab-content
          v-if="!createHobjectOnly"
          :title="this.$t('How')"
          class="mb-5"
          icon="feather icon-move"
          :before-change="validateStep4"
        >
          <form data-vv-scope="step-4">
            <vs-row vs-type="flex" vs-justify="center">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                <div class="vx-col xl:w-3/4 w-full mb-base">
                  <vx-card title="How should your content be positioned?">
                    <!-- <h4>How should your content be positioned?</h4> -->
                    <br />
                    <form-placement
                      ref="formTab4"
                      :hobject="hobject"
                      v-bind:addHobjectToExistingSpace="addHobjectToExistingSpace"
                      v-bind:anchor="anchor"
                      v-bind:space="space"
                      v-bind:node="node"
                      v-bind:hobjects="hobjects"
                      v-bind:setDefaultFloor="true"
                    ></form-placement>
                  </vx-card>
                </div>
              </vs-col>
            </vs-row>
          </form>
        </tab-content>
      </form-wizard>
    </div>
  </vx-card>
</template>

<script>
import InitData from '@/views/hoverlay/components/InitData.vue'
import FormPlacement from '@/views/hoverlay/pages/create/components/FormPlacement.vue'
import Where from '@/views/hoverlay/pages/create/components/Where.vue'
import FormSelectExistingHobject from '@/views/hoverlay/pages/create/components/FormSelectExistingHobject.vue'
import ObjectCreation from '@/views/hoverlay/components/ObjectCreation.vue'

import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'
import * as Utils from '@/assets/js/utils/utils.js'

export default {
  inject: ['$validator'],
  data() {
    return {
      userRole: HoverlayUtils.getUserRole(),
      createHobjectOnly: false,
      addHobjectToExistingSpace: false,
      autoScaleGlb: true,
      space: {
        latitude: null,
        longitude: null,
        present_from: new Date().toISOString(),
        present_to: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString(), // add 365 days
        // minutes: 60 * 24 * 10,
        range: 100,
        name: '',
        description: '',
      },
      hobject: {},
      node: {
        angle_x: 0,
        angle_y: 0,
        angle_z: 0,
        x: 0,
        y: 0,
        z: 0,
        scale: 1,
      },
      anchor: {},
      model: {
        provider: JSON.parse(localStorage.getItem('layerInfo')).name,
        collection: 'glb',
        name: null,
        version: '1.0',
        format: 'glb',
        angle_x: 0,
        angle_y: 0,
        angle_z: 0,
        access_level: 'everyone',
        description: null,
        abilities: null,
        label: 'glb',
        data: null,
        scale: 1,
      },
      formData: null,
      url: window.location.pathname,
    }
  },
  computed: {
    nodes() {
      return this.$store.state.hoverlay.nodes
    },
    spaces() {
      return this.$store.state.hoverlay.spaces
    },
    hobjects() {
      return this.$store.state.hoverlay.hobjects
    },
    models() {
      return this.$store.state.hoverlay.models
    },
    anchors() {
      return this.$store.state.hoverlay.anchors
    },
  },
  mounted() {
    // remove wizard header
    document.getElementsByClassName('wizard-header')[0].style.display = 'none'
  },
  created() {
    this.$store.dispatch('hoverlay/getLayerContent')

    this.$store.dispatch('hoverlay/fetchAnchors', this.modifiedPlacements)

    this.$eventBus.$on('hobjectChanged', this.onHobjectChanged)
    this.$eventBus.$on('anchorChanged', this.onAnchorChanged)
    this.$eventBus.$on('nodeChanged', this.onNodeChanged)
    this.$eventBus.$on('spaceChanged', this.onSpaceChanged)
    this.$eventBus.$on('modelChanged', this.onModelChanged)

    this.space.latitude = parseFloat(this.$route.params.lat)
    this.space.longitude = parseFloat(this.$route.params.lon)

    if (window.location.pathname == '/create/') {
      this.createHobjectOnly = true
    }
    if (this.$route.params.space_pid) {
      this.addHobjectToExistingSpace = true
      this.space = { pid: this.$route.params.space_pid }
    }
  },
  beforeDestroy() {
    this.$eventBus.$off('hobjectChanged')
    this.$eventBus.$off('anchorChanged')
    this.$eventBus.$off('spaceChanged')
    this.$eventBus.$off('nodeChanged')
    this.$eventBus.$off('modelChanged')
  },
  methods: {
    onChildClick(value) {
      console.log('onChildClick')
      this.fromChild = value
    },
    onHobjectChanged(hobject) {
      console.log('onHobjectChanged')
      console.log(hobject)
      this.hobject = hobject
    },
    onNodeChanged(node) {
      console.log('onNodeChange')
      console.log(node)
      this.node = node
    },
    onAnchorChanged(anchor) {
      console.log('onAnchorChanged')
      console.log(anchor)
      this.anchor = anchor
    },
    onSpaceChanged(space) {
      console.log('onSpaceChanged')
      console.log(space)
      this.space = space
    },
    onModelChanged(model) {
      console.log('onModelChange')
      console.log(model)
      this.model = model
    },
    setHobject(val) {
      console.log('FormWizard.vue [setHobject] ')
      console.log(val)
      this.hobject = val
    },
    setPlacement(val) {
      console.log('setPlacement = ' + val)
      this.placement = val
    },
    setAnchor(val) {
      this.anchor = val
    },
    setModel(val) {
      this.model = val
    },
    async createSpace(spaceWorkflowItem) {
      var image64 = spaceWorkflowItem.hobject.image64
      delete spaceWorkflowItem.hobject.image64
      if (image64) spaceWorkflowItem.hobject.image = image64
      var response = await this.$store.dispatch('hoverlay/createSpace', spaceWorkflowItem)
      await this.$store.dispatch('hoverlay/addAnchorSpace', {
        anchor_pid: response.anchor.pid,
        space_pid: response.space.pid,
      })
      // if (!spaceWorkflowItem.anchor.pid) await this.$store.dispatch('hoverlay/getAnchor', response.anchor.pid)
      this.$router.push({
        path: `/space/${response.space.pid}?anchor=${response.anchor.pid}`,
      })
    },
    async createAnchor(anchor) {
      const formData = new FormData()
      Object.keys(anchor).forEach(key => formData.append(key, anchor[key]))
      var newlyCreatedAnchor = await this.$store.dispatch('hoverlay/addAnchor', anchor)
      return newlyCreatedAnchor
    },
    async createHobjectAndReturnToObjectLibrary() {
      this.$vs.loading({ text: ' ' }) // Add blank text so we can modified it later (progress pourcentage)
      await HoverlayUtils.createHobject(this.$store, this.$vs, this.hobject)
      this.$vs.loading.close()
      this.$router.push({
        name: 'objectslibrary',
      })
    },
    // // // // // // // // // // // // // // // //
    // Not use anymore see FormPlacement.vue, AddObjectToExistingAnchor and FormSelectExistingAnchor
    // // // // // // // // // // // // // // // //
    // changeDefaultOrientationOnReferenceImagesIfNeeded() {
    //   var h, a
    //   if (this.hobject.pid) h = this.hobjects.find(hobject => hobject.pid == this.hobject.pid)
    //   else h = this.hobject
    //   if (this.anchor.pid) a = this.anchors.find(anchor => anchor.pid == this.anchor.pid)
    //   else a = this.anchor
    //   if (a.anchor_type != 'image') return
    //   this.node.x = 0
    //   this.node.y = 0
    //   this.node.z = 0
    //   if (
    //     a.anchor_type == 'image' &&
    //     h.model_identifier != 'hoverlay.core.3dModel.1.0' &&
    //     h.model_identifier != 'hoverlay.core.UnityAssetBundle.1.0' &&
    //     h.model_identifier != 'hoverlay.core.Portal.1.0'
    //   ) {
    //     this.node.quaternion_x = 0.7
    //     this.node.quaternion_w = 0.7
    //     var anchorHeight = JSON.parse(a.mark).height
    //     this.node.z = -anchorHeight / 2
    //   }
    // },
    async createNewNodeInSpace() {
      // // // // // // // // // // // // // // // //
      // Prepare payload to add a new node in space
      // // // // // // // // // // // // // // // //

      try {
        this.$vs.loading({ text: ' ' }) // Add blank text so we can modified it later (progress pourcentage)

        var params = {}

        // Add hobject pid to params payload
        if (!this.hobject.pid) this.hobject = await HoverlayUtils.createHobject(this.$store, this.$vs, this.hobject)
        params.hobject_pid = this.hobject.pid

        // Add Anchor pid to params payload
        if (!this.anchor.pid) this.anchor = await this.createAnchor(this.anchor)
        params.anchor_pid = this.anchor.pid

        params.angle_x = this.node.angle_x
        params.angle_y = this.node.angle_y
        params.angle_z = this.node.angle_z
        params.quaternion_x = this.node.quaternion_x
        params.quaternion_y = this.node.quaternion_y
        params.quaternion_z = this.node.quaternion_z
        params.quaternion_w = this.node.quaternion_w

        params.x = this.node.x
        params.y = this.node.y
        params.z = this.node.z
        params.scale = this.node.scale
        // Add space pid to params payload
        params.pid = this.space.pid
        // Call /space/{pid}/node
        await this.$store.dispatch('hoverlay/createNodeInSpace', params)

        this.$router.push(`/space/${this.space.pid}`).catch(err => {
          console.log(err)
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.$vs.loading.close()
      }
    },

    async createSpaceWorkfowRequest() {
      console.log('SUBMIT')
      console.log(this.hobject)
      console.log(this.space)
      console.log(this.node)
      console.log(this.anchor)

      // Convert to object
      this.$vs.loading({ text: ' ' }) // Add blank text so we can modified it later (progress pourcentage)

      var underscoreSpace = {}
      for (var camel in this.space) {
        underscoreSpace[this.camelToUnderscore(camel)] = this.space[camel]
      }
      var underscoreAnchor = {}
      for (camel in this.anchor) {
        underscoreAnchor[this.camelToUnderscore(camel)] = this.anchor[camel]
      }
      var underscoreNode = {}
      for (camel in this.node) {
        underscoreNode[this.camelToUnderscore(camel)] = this.node[camel]
      }
      if (!underscoreSpace.name) {
        underscoreSpace.name = 'Unamed location'
      }
      if (!underscoreSpace.latitude || !underscoreSpace.longitude) {
        underscoreSpace.latitude = Utils.getRandomFloat(-90, 90, 0.1)
        underscoreSpace.longitude = Utils.getRandomFloat(-180, 180, 0.1)
      }
      var newSpaceRequest = {
        layer: {
          pid: JSON.parse(localStorage.getItem('layerInfo')).pid,
        },
        space: underscoreSpace,
        node: underscoreNode,
        anchor: underscoreAnchor,
      }

      // If hobject already exist
      if (this.hobject.pid) {
        newSpaceRequest.hobject = {
          pid: this.hobject.pid,
        }
        // If the hobject does not exist, create a new one
      } else {
        var newlyCreatedHobject = await HoverlayUtils.createHobject(this.$store, this.$vs, this.hobject)
        newSpaceRequest.hobject = {
          pid: newlyCreatedHobject.pid,
        }
      }

      await this.createSpace(newSpaceRequest)

      this.$vs.loading.close()
    },
    validateStep1() {
      return new Promise((resolve, reject) => {
        var success = false
        try {
          success = this.$refs.formTab1.validate()
        } catch (e) {
          console.log(e)
        }
        if (success) {
          resolve(true)
          // hack to select the first tab when adding a object into a space
          setTimeout(function() {
            document.getElementById('create-new-object-tab').click()
          }, 300)
        } else reject('correct all values')
      })
    },
    async validateStep2() {
      var success = false
      console.log('validateStep2')
      if (this.hobject.pid != null) success = true
      else success = await this.$refs.objectCreationRef.validate()
      return new Promise((resolve, reject) => {
        try {
          if (success) {
            if (this.createHobjectOnly) this.createHobjectAndReturnToObjectLibrary()
            resolve(true)
          } else reject('correct all values')
        } catch (e) {
          console.log(e)
        }
      })
    },

    validateStep4() {
      console.log('validate step4')
      return new Promise((resolve, reject) => {
        var success = true
        try {
          success = this.$refs.formTab4.validate()
        } catch (e) {
          console.log(e)
          resolve(false)
        }
        if (success) {
          // this.changeDefaultOrientationOnReferenceImagesIfNeeded()
          if (this.addHobjectToExistingSpace == true) this.createNewNodeInSpace()
          if (!this.createHobjectOnly && !this.addHobjectToExistingSpace) this.createSpaceWorkfowRequest()
          resolve(true)
        } else reject('correct all values')
      })
    },
    camelToUnderscore(key) {
      var result = key.replace(/([A-Z])/g, ' $1')
      return result
        .split(' ')
        .join('_')
        .toLowerCase()
    },
  },
  components: {
    FormWizard,
    TabContent,
    FormPlacement,
    Where,
    FormSelectExistingHobject,
    InitData,
    ObjectCreation,
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}
</style>
