<!-- =========================================================================================
  File Name: Cutout.vue
  Description: Form to edit the atributes of an image cutout hoverpack
========================================================================================== -->

<template>
  <div>
    <div class="vx-row mb-6">
      <div class="vx-col xl:w-1/2">
        <vs-input class="w-full" :label="this.$t('HoverpackHeight')" v-model.number="height" name="height" />
      </div>

      <div class="vx-col xl:w-1/2">
        <label for="" class="vs-input--label">{{ this.$t('HoverpackUnit') }}</label>
        <v-select :clearable="false" v-model="defaultUnit" label="text" class="w-full" :options="unitOptions" />
      </div>
    </div>
    <span v-for="errorMessageUpload in errorMessageUploads" v-bind:key="errorMessageUpload" class="text-danger"
      >{{ errorMessageUpload }}<br
    /></span>
    <span class="text-danger">{{ errors.first('step-2.name') }}</span>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  inject: ['$validator'],
  components: {
    'v-select': vSelect,
  },
  props: {
    initialPlacement: {},
    hobject: {},
    abilityName: null,
  },
  data() {
    return {
      height: null,
      heightInMeter: null,
      measurement: this.$store.state.AppActiveUser.measurement,
      defaultUnit: 'cm',
      defaultHeight: null,
      defaultHeightInInches: 30,
      defaultHeightInCentimeter: 100,
      unitOptions: [
        {
          text: 'cm',
          value: 'cm',
        },
        {
          text: 'inches',
          value: 'inches',
        },
      ],
      errorMessageUploads: [],
    }
  },
  watch: {
    defaultUnit: {
      handler: function() {
        this.ComputeHeight(this.height)
      },
    },
    height: {
      handler: function(updatedValue) {
        console.log('height')
        try {
          this.ComputeHeight(updatedValue)
        } catch (e) {
          console.log(e)
        }
      },
    },
  },
  created() {
    // Set system of measurement
    if (this.measurement == null || this.measurement == 'metric') {
      this.defaultUnit = {
        text: 'cm',
        value: 'cm',
      }
      this.defaultHeight = this.defaultHeightInCentimeter
    }
    if (this.measurement == 'imperial') {
      this.defaultUnit = {
        text: 'inches',
        value: 'inches',
      }
      this.defaultHeight = this.defaultHeightInInches
    }
    try {
      var h = JSON.parse(this.hobject.abilities)[this.abilityName].height
      if (h == null) {
        this.height = this.defaultHeight
      } else {
        if (this.measurement == null || this.measurement == 'metric') this.height = (h * 100).toFixed(2)
        if (this.measurement == 'imperial') this.height = (h * 100 * 0.393701).toFixed(2)
      }
      if (!this.height) this.height = this.defaultHeight
      this.heightInMeter = this.height / 100
    } catch (e) {
      console.log(e)
      this.height = this.defaultHeight
    }
  },
  methods: {
    ComputeHeight(height) {
      console.log(this.defaultUnit.value)
      let heightInMeter = null
      if (isNaN(this.height)) {
        heightInMeter = 1
      } else if (this.defaultUnit.value === 'inches') {
        heightInMeter = parseFloat(height) * 0.0254
      } else {
        heightInMeter = parseFloat(height) / 100
      }

      var abilities = JSON.parse(this.hobject.abilities)
      abilities[this.abilityName].height = heightInMeter
      this.hobject.abilities = JSON.stringify(abilities)
    },
    validate() {
      console.log('[FormPhysicalHeight.vue] VALIDATION TRUE')
      this.errorMessageUploads = []
      if (!this.height) {
        this.errorMessageUploads.push('Height must not be empty')
        return false
      }
      if (this.height) {
        return true
      } else return false
    },
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}
</style>
