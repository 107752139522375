<!-- =========================================================================================
  File Name: FormNameDescription.vue
  Description: Form to edit the attributes name and desccription of a hobject
========================================================================================== -->

<template>
  <div class="vx-row">
    <div class="vx-col xl:w-full">
    <vs-switch class="mb-base" color="success" v-model="use_space_audio">
      <span slot="on">spatial audio</span>
      <span slot="off">ambient audio</span>
    </vs-switch>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  props: {
    hobject: {},
  },
  data() {
    return {
      use_space_audio: true,
    }
  },
  watch: {
    use_space_audio: {
      handler: function () {
        var abilities
        if (this.use_space_audio) {
          abilities = JSON.parse(this.hobject.abilities)
          abilities.audio.audio_mode = 'spatial'
          this.hobject.abilities = JSON.stringify(abilities)
        } else {
          abilities = JSON.parse(this.hobject.abilities)
          abilities.audio.audio_mode = 'direct'
          this.hobject.abilities = JSON.stringify(abilities)
        }
      },
    },
    hobject: {
      handler: function (updatedValue) {
        this.$emit('setHobject', updatedValue)
      },
    },
  },
  created() {
    try {
      var abilities = JSON.parse(this.hobject.abilities)
      if ('audio' in abilities && abilities.audio.audio_mode == 'direct') {
        this.use_space_audio = false
      } else this.use_space_audio = true
    } catch (e) {
      this.use_space_audio = false
    }
  },
  methods: {
    validate() {
      return true
    },
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}
</style>
