<!-- =========================================================================================
  File Name: uploads/VideoImageUpload.vue
  Description: Image Upload Form
========================================================================================== -->

<template>
  <!-- tab 1 content -->
  <form data-vv-scope="step-1">
    <file-pond
      :imagePreviewHeight="300"
      name="test"
      ref="pond"
      :maxFileSize="this.maxFileSize"
      :label-idle="this.$t('HoverpackImageDrag') + '(Max ' + this.maxFileSize + ')'"
      :server="{ process, revert, restore, load, fetch }"
      :allow-multiple="false"
      accepted-file-types="image/jpeg, image/png, video/mp4"
      :files="myFiles"
      v-on:onremovefile="fileRemoved"
      v-on:addfile="fileAdded"
      v-on:init="handleFilePondInit"
    />
    <span class="text-danger">{{ errors.first('step-1.image_upload') }}</span>
    <span v-if="errorMessageUpload" class="text-danger">{{ errorMessageUpload }}</span>
    <canvas v-show="false" id="canvas" width="640" height="480"></canvas>
  </form>
</template>

<script>
import humanize from 'string-humanize'
import * as Utils from '@/assets/js/utils/utils.js'

// Import Vue FilePond
import vueFilePond from 'vue-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'
import imageCompression from 'browser-image-compression'

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
)

export default {
  inject: ['$validator'],
  components: {
    FilePond,
  },
  props: {
    hobject: {},
  },
  data() {
    return {
      // inherited variables from parent
      errorMessageUpload: '',
      myFiles: [],
    }
  },
  watch: {
    hobject: {
      handler: function () {},
      deep: true,
    },
  },
  computed: {
    maxFileSize: function () {
      return HoverlayUtils.getMaxUploadFileSize()
    },
  },
  created() {
    console.log('uploads/VideoImageUpload.vue created ')
    var dataUrl = null
    if (!this.hobject.name) this.hobject.name = ''
    if (this.hobject.asset_uri) dataUrl = this.hobject.asset_uri
    if (dataUrl)
      this.myFiles = [
        {
          source: dataUrl,
          options: {
            type: 'local',
          },
        },
      ]
  },
  methods: {
    fileRemoved: function (error, file) {
      console.log('fileRemoved')
    },
    fileAdded: function (error, file) {
      console.log('fileAdded')
      if (file.file.type.includes('video')) this.tryCanvasScreenshot()
      if (!this.hobject.name) {
        delete this.hobject.name
        this.$set(this.hobject, 'name', humanize(file.filename.replace(/\.[^/.]+$/, '')).substring(0, 63))
      }
    },
    handleFilePondInit: function () {
      console.log('FilePond has initialized')
      // FilePond instance methods are available on `this.$refs.pond`
    },
    validate() {
      if (this.hobject.data || this.myFiles.length > 0) {
        this.errorMessageUpload = ''
        console.log('[VideoImageUpload.vue] VALIDATION TRUE')
        return true
      } else {
        console.log('[VideoImageUpload.vue] VALIDATION FALSE')
        this.errorMessageUpload = 'You must upload an image'
        return false
      }
    },
    setHobject(hobject) {
      this.hobject = hobject
    },
    async process(fieldName, file, metadata, load, error, progress, abort) {
      console.log('process')
      var abilities
      if (file.type.includes('image')) {
        abilities = JSON.parse(this.hobject.abilities)
        abilities.skin.type = '360image'
        this.hobject.abilities = JSON.stringify(abilities)
        const resizeOptionsForThumbnail = {
          maxSizeMB: 1,
          maxWidthOrHeight: 512,
          useWebWorker: true,
        }
        const compressedThumbnail = await imageCompression(
          new File([file], file.name, { type: file.type }),
          resizeOptionsForThumbnail
        )
        this.hobject.image = compressedThumbnail
        const resizeOptionsForImage = {
          maxSizeMB: 5,
          maxWidthOrHeight: 6000,
          useWebWorker: true,
          type: 'image/jpeg',
        }
        const compressedImage = await imageCompression(
          new File([file], file.name, { type: file.type }),
          resizeOptionsForImage
        )
        this.hobject.data = compressedImage
      } else {
        abilities = JSON.parse(this.hobject.abilities)
        abilities.skin.type = '360video'
        this.hobject.abilities = JSON.stringify(abilities)
        this.hobject.data = file
      }
      console.log(this.hobject)
      load(file)
      // Should expose an abort method so the request can be cancelled
      return {
        abort: () => {
          // Let FilePond know the request has been cancelled
          abort()
        },
      }
    },
    tryCanvasScreenshot() {
      this.screenshotInterval = setInterval(this.takeCanvasScreenshot, 1000)
    },
    takeCanvasScreenshot() {
      try {
        var canvas = document.querySelector('canvas')
        var context = canvas.getContext('2d')
        var video = document.getElementsByClassName('filepond--media-preview')[0].children[0]
        var w, h, ratio

        ratio = video.videoWidth / video.videoHeight
        w = video.videoWidth - 100
        h = parseInt(w / ratio, 10)
        canvas.width = w
        canvas.height = h
        context.fillRect(0, 0, w, h)
        context.drawImage(video, 0, 0, w, h)
        this.hobject.image = Utils.dataURItoBlob(canvas.toDataURL())
        console.log(this.hobject.image)
        clearInterval(this.screenshotInterval)
      } catch (e) {
        console.log(e)
      }
    },
    load(source, load, error, progress, abort, headers) {
      var myRequest = new Request(source)
      fetch(myRequest).then(function (response) {
        response.blob().then(function (myBlob) {
          load(myBlob)
        })
      })
    },

    fetch(url, load, error, progress, abort, headers) {
      var myRequest = new Request(url)
      fetch(myRequest).then(function (response) {
        response.blob().then(function (myBlob) {
          load(myBlob)
        })
      })
    },

    restore(uniqueFileId, load, error, progress, abort, headers) {
      // error();
    },

    revert(uniqueFileId, load, error) {
      // load();
    },
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}

.image-preview {
  height: 220px;
  width: 220px;
}
</style>
