<!-- =========================================================================================
  File Name: HobjectList.vue
  Description: Object List - Thumb View 
========================================================================================== -->

<template>
  <div id="data-list-thumb-view" class="data-list-container hoverlay-custom">
    <!-- <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" /> -->
    <!-- <h4>{{ this.$t('AnchorExisting') }}</h4> -->
    <br />
    <vs-table
      class="existing-anchors"
      noDataText="It seems that you don't have any object yet, to create one click on 'Add Object'"
      ref="table"
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="anchors"
    >
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{ anchors.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : anchors.length }} of
              {{ queriedItems }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="type">Type</vs-th>
        <vs-th sort-key="data">Thumbnail</vs-th>
        <vs-th sort-key="name">{{ $t('Name') }}</vs-th>
        <vs-th>Position</vs-th>
        <vs-th sort-key="category">{{ $t('Height') }}</vs-th>
        <!-- <vs-th sort-key="order_status">{{ $t('Status') }}</vs-th> -->
        <!-- <vs-th>Action</vs-th> -->
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="anchor" :key="indextr" v-for="(anchor, indextr) in data">
            <vs-td class="img-container">
              <img
                :src="require('@/assets/images/hoverlay/anchors/trackedimageanchor.jpg'),"
                class="product-img rounded-lg"
              />
            </vs-td>
            <vs-td class="img-container">
              <img :src="getAnchorThumbnail(anchor)" class="product-img rounded-lg" />
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{ anchor.name }}</p>
              <p class="product-category">{{ anchor.description }}</p>
            </vs-td>
            <vs-td>
              <!-- <vs-chip color="primary" class="product-order-status"> -->
              {{ getAnchorOrientation(anchor) }}
              <!-- </vs-chip> -->
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{ getAnchorHeight(anchor) }} cm</p>
            </vs-td>
            <!-- <vs-td>
              <div v-if="getActivePlacement(anchor).length > 0">
                <vs-chip
                  :data="placement"
                  :key="indextr"
                  v-for="(placement, indextr) in getActivePlacement(anchor)"
                  link
                  color="primary"
                  class="product-order-status"
                >
                  {{ placement.description | truncate(20, '...') }}
                </vs-chip>
              </div>
              <vs-chip v-else link color="warning" class="product-order-status"> inactive </vs-chip>
            </vs-td> -->
            <!-- 
            <vs-td class="whitespace-no-wrap">
              <feather-icon
                size="3x"
                icon="EditIcon"
                svgClasses="w-7 h-7 hover:text-primary stroke-current"
                class="ml-2"
                @click.stop="editanchor(anchor.pid)"
              />
              <feather-icon
                size="3x"
                icon="TrashIcon"
                svgClasses="w-7 h-7 hover:text-danger stroke-current"
                class="ml-2"
                @click.stop="deleteanchor(anchor.pid)"
              />
            </vs-td> -->
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  props: {
    node: {},
    hobject: {},
  },
  components: {},
  data() {
    return {
      selected: [],
      itemsPerPage: 4,
      isInitialized: false,
      isMounted: false,
      addNewDataSidebar: false,
      sidebarData: {},
      Youtube: null,
    }
  },
  watch: {
    selected: {
      handler: function(updatedValue) {
        var anchor = {}
        anchor.pid = updatedValue.pid
        this.$eventBus.$emit('anchorChanged', anchor)
        // // Check anchor position type and modify space accordingly.
        try {
          const exisitingAnchor = this.anchors.find(anchor => anchor.pid == updatedValue.pid)
          console.log(exisitingAnchor)

          var anchorPosition = JSON.parse(exisitingAnchor.mark).position
          var anchorHeight = JSON.parse(exisitingAnchor.mark).height
          console.log(anchorPosition)
          switch (anchorPosition) {
            case 'vertical':
              this.node.quaternion_x = 0.7071
              this.node.quaternion_w = 0.7071
              this.node.z = -anchorHeight / 2
              break
            case 'horizontal':
              this.node.quaternion_x = 0
              this.node.quaternion_w = 0
              this.node.z = 0
              break
            default:
              break
          }
        } catch (e) {
          console.log(e)
        }
      },
    },
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    anchors() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.anchors.filter(anchor => anchor.anchor_type === 'image')
      } else {
        return null
      }
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.anchors.length
    },
  },
  methods: {
    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    getAnchorThumbnail(anchor) {
      if (anchor.data) return this.getCloudfrontUrlFromS3(anchor.data)
      if (anchor.dataUri) return this.getCloudfrontUrlFromS3(anchor.dataUri)
    },
    getModelThumbnail(hobject) {
      var model = this.$store.state.hoverlay.models.find(model => model.pid === hobject.modelPid)
      if (model && model.thumbnail_medium) return model.modelThumbnail
      else return null
    },
    getAnchorHeight(anchor) {
      try {
        return parseFloat(Number.parseFloat(JSON.parse(anchor.mark).height * 100).toFixed(2))
      } catch (e) {
        return 0
      }
    },
    getModelDispayName(hobject) {
      var model = this.$store.state.hoverlay.models.filter(model => model.pid === hobject.modelPid)[0].name
      return model
    },
    getModelDescription(hobject) {
      var model = this.$store.state.hoverlay.models.filter(model => model.pid === hobject.modelPid)[0].description
      return model
    },
    getPopularityColor(num) {
      if (num > 90) return 'success'
      if (num > 70) return 'primary'
      if (num >= 50) return 'warning'
      if (num < 50) return 'danger'
      return 'primary'
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    getAnchorOrientation(anchor) {
      var orientation = 'None'
      try {
        orientation = JSON.parse(anchor.mark).position
      } catch (e) {
        e
      }
      return orientation
    },
    getThumb(url, size) {
      var results, video
      if (url === null) {
        return ''
      }
      size = size === null ? 'big' : size
      results = url.match('[\\?&]v=([^&#]*)')
      video = results === null ? url : results[1]

      if (size === 'small') {
        return 'http://img.youtube.com/vi/' + video + '/2.jpg'
      }
      return 'http://img.youtube.com/vi/' + video + '/0.jpg'
    },
    async fetchAllData() {
      this.$vs.loading()
      await this.$store.dispatch('hoverlay/getLayerContent')
      this.$vs.loading.close()
    },
  },
  created() {},
  async mounted() {
    this.isMounted = true
  },
}
</script>

<style lang="scss">
// Hack to remove next button because it reload the page
.existing-anchors .vs-pagination--button-next {
  display: none;
}
.hoverlay-custom > .vs-con-table .vs-con-tbody {
  background: #ffffff;
}
.hoverlay-custom > .vs-table-primary .is-selected {
  background: rgba(var(--vs-primary), 0.15) !important;
}
#data-list-thumb-view .vs-con-table .vs-table tr td.img-container .product-img {
  height: 80px !important;
}
#data-list-thumb-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }

          &.img-container {
            // width: 1rem;
            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .product-img {
              height: 110px;
            }
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
  a {
    color: white;
    text-decoration: underline;
  }
}
</style>
