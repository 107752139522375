<!-- =========================================================================================
    File Name: LeafletMap.vue
    Description: Basic map to create and see spaces
========================================================================================== -->

<template>
  <div class="root w-full bg-grid-color-secondary h-12">
    <div class="vx-row">
      <div class="vx-col w-1/2">
        <div class="vx-col w-full">
          <h4 class="mb-5">{{ $t('CreateAndPlaceWorkflowWhereTitle') }}</h4>
        </div>

        <label class="vs-input--label">Restrict access to link or QR Code only</label>
        <vs-switch color="success" class="mb-6" v-model="is_access_level_unlisted">
          <span slot="on">On</span>
          <span slot="off">Off</span>
        </vs-switch>

        <vs-input
          class="w-full mb-6"
          icon-pack="feather"
          icon="icon-bookmark"
          icon-no-border
          label="What do you want your space to be privately called ?"
          placeholder="Enter the space name here"
          name="name"
          data-vv-name="name"
          v-model="space.name"
        />
        <span class="text-danger">{{ errors.first('name') }}</span>
        <vs-input
          class="w-full mb-6"
          icon-pack="feather"
          icon="icon-bookmark"
          icon-no-border
          label="What do you want your space to be publicly called ?"
          placeholder="Enter the space description here"
          name="description"
          data-vv-name="description"
          v-model="space.description"
        />
        <vs-input
          v-show="!is_access_level_unlisted"
          class="w-full"
          icon-pack="feather"
          icon="icon-target"
          icon-no-border
          :label="$t('CreateAndPlaceWorkflowWhereRadius')"
          v-validate="{ between: [15, 20000], required: true }"
          name="range"
          data-vv-name="range"
          v-model.number="space.range"
        />
        <div v-show="!is_access_level_unlisted" class="vx-col w-full">
          <span class="text-danger">{{ errors.first('range') }}</span>
        </div>

        <div v-show="!is_access_level_unlisted" class="w-full">
          <div class="vx-row mt-6">
            <div class="vx-col w-1/2">
              <label for="" class="vs-input--label">From</label>
              <flat-pickr :config="config" class="w-full" w-fullplaceholder="From Date" v-model="present_from" />
            </div>
            <div class="vx-col w-1/2">
              <label for="" class="vs-input--label">To</label>
              <flat-pickr :config="config" class="w-full" w-fullplaceholder="From Date" v-model="present_to" />
            </div>
          </div>

          <vs-alert color="primary" icon="new_releases" active="true" class="mb-1 mt-6">
            <span>
              All times local to your location ({{ Intl.DateTimeFormat().resolvedOptions().timeZone }}).<br />
              Be mindful that your space might be in a different time zone.</span
            >
          </vs-alert>
        </div>
      </div>
      <div class="vx-col w-1/2">
        <div v-show="!is_access_level_unlisted">
          <vs-input
            v-on:keyup.enter="AdressSearch"
            icon="search"
            placeholder="Seach by name, address or latitude longitude"
            class="w-full mb-2"
            v-model="address"
          />
          <l-map
            ref="map"
            @click="addMarker"
            v-if="showMap && pulsingIconDisable"
            :zoom="zoom"
            :max-zoom="maxZoom"
            :min-zoom="minZoom"
            :maxBounds="[
              [-90, -180],
              [90, 180],
            ]"
            :center="center"
            :options="mapOptions"
            class="rounded-lg"
            style="height: 420px; width: 100%"
            @update:center="centerUpdate"
            @update:zoom="zoomUpdate"
          >
            <l-control-layers ref="control"></l-control-layers>

            <l-tile-layer
              :options="{ maxZoom: maxZoom }"
              :url="TerrainMapUrl"
              :attribution="attribution"
              name="Terrain"
              layer-type="base"
            ></l-tile-layer>
            <l-tile-layer
              :options="{ maxZoom: maxZoom }"
              :url="SatelliteOnlyMapUrl"
              :attribution="attribution"
              name="Satellite"
              layer-type="base"
            ></l-tile-layer>
            <l-tile-layer
              :options="{ maxZoom: maxZoom }"
              :url="HybridMapUrl"
              :attribution="attribution"
              name="Hybrid"
              layer-type="base"
            ></l-tile-layer>
            <l-tile-layer
              :options="{ maxZoom: maxZoom }"
              :url="AlteredRoadmapMapUrl"
              :attribution="attribution"
              name="Altered Roadmap"
              layer-type="base"
            ></l-tile-layer>
            <l-tile-layer
              :options="{ maxZoom: maxZoom }"
              :url="RoadmapMapUrl"
              :attribution="attribution"
              name="Roadmap"
              layer-type="base"
            />
            <l-circle
              v-if="marker"
              :fillOpacity="0"
              :opacity="0.5"
              :stroke="true"
              fillColor="#28c76f"
              color="#28c76f"
              :lat-lng="marker"
              :radius="space.range"
            ></l-circle>
            <l-marker
              v-if="marker"
              :icon="this.pulsingIconEnable"
              :lat-lng="[space.latitude, space.longitude]"
            ></l-marker>
          </l-map>

          <br />
        </div>
      </div>

      <span v-bind:key="errorMessageUpload" v-for="errorMessageUpload in errorMessageUploads" class="text-danger"
        >{{ errorMessageUpload }}<br
      /></span>
      <span class="text-danger">{{ errors.first('step-2.name') }}</span>
    </div>
  </div>
</template>

<script>
// Leaflet
import L from 'leaflet'
import 'leaflet.locatecontrol'
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css'
// Leaflet Pulse Plugin
import '@ansur/leaflet-pulse-icon/dist/L.Icon.Pulse.css'
import 'leaflet-pulse-icon'

import 'leaflet.markercluster/dist/leaflet.markercluster.js'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { LMap, LMarker, LTileLayer, LCircle, LTooltip, LControlLayers } from 'vue2-leaflet'

export default {
  name: 'GeometryTest',
  inject: ['$validator'],
  components: {
    // LTooltip,
    LMarker,
    LMap,
    LTileLayer,
    LCircle,
    LControlLayers,
    flatPickr,
  },
  props: {
    initialPlacement: {},
    initialHobject: {},
    spaces: {},
    space: {},
  },
  data() {
    return {
      is_access_level_unlisted: false,
      // Leaflet
      pulsingIconEnable: L.icon.pulse({
        iconSize: [12, 12],
        color: '#28c76f',
        heartbeat: 0.85,
      }),
      pulsingIconDisable: L.icon.pulse({
        iconSize: [12, 12],
        color: '#7367F0',
        heartbeat: 0.85,
      }),
      address: '',
      zoom: 4.5,
      center: [37, -95],
      maxZoom: 20,
      minZoom: 1,
      RoadmapMapUrl: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
      TerrainMapUrl: 'http://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}',
      AlteredRoadmapMapUrl: 'http://mt0.google.com/vt/lyrs=r&hl=en&x={x}&y={y}&z={z}',
      SatelliteOnlyMapUrl: 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',
      HybridMapUrl: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 11.5,
      currentCenter: [37, -95],
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: true,
      marker: null,
      errorMessageUploads: [],
      uniquePlacements: [],
      // When
      present_from: new Date(this.space.present_from),
      present_to: new Date(this.space.present_to),
      config: {
        //wrap: true, // set wrap to true only when using 'input-group'
        // altFormat: 'M j, Y',
        altInput: true,
        // dateFormat: 'm-d-Y',
        enableTime: true,
      },
    }
  },
  watch: {
    is_access_level_unlisted: {
      handler(updatedValue) {
        if (updatedValue) this.space.access_level = 'unlisted'
        else this.space.access_level = 'everyone'
      },
    },
    marker: {
      async handler(updatedValue) {
        if (updatedValue != null) {
          this.errorMessageUploads = []
          this.$eventBus.$emit('spaceChanged', this.space)
          var geoReverseAddress
          if (!this.address && this.space.name == '') {
            try {
              geoReverseAddress = await this.$store.dispatch('hoverlay/getAddressFromLatLong', this.space)
            } catch (e) {
              geoReverseAddress = 'Unnamed location'
            }
            this.space.name = geoReverseAddress
            this.space.description = geoReverseAddress
          } else {
            if (this.space.name == '') {
              this.space.name = this.address
              this.space.description = this.address
            }
          }
        }
      },
    },
    present_to: {
      handler: function(updatedValue) {
        console.log(updatedValue)
        console.log(new Date(updatedValue).toISOString())
        var p = JSON.parse(JSON.stringify(this.space))
        p.present_to = new Date(updatedValue).toISOString().toString()
        this.$eventBus.$emit('spaceChanged', p)
      },
      deep: true,
    },
    present_from: {
      handler: function(updatedValue) {
        console.log(new Date(updatedValue).toISOString())
        var p = JSON.parse(JSON.stringify(this.space))
        p.present_from = new Date(updatedValue).toISOString().toString()
        this.$eventBus.$emit('spaceChanged', p)
      },
      deep: true,
    },
  },
  computed: {},
  mounted() {
    // https://github.com/vue-leaflet/Vue2Leaflet/issues/96
    setTimeout(function() {
      window.dispatchEvent(new Event('resize'))
    }, 250)

    L.control.locate().addTo(this.$refs.map.mapObject)

    this.createSpacesMarker()
  },
  created() {
    if (this.$route.query.zoom && this.$route.query.centerLng && this.$route.query.centerLat) {
      this.zoom = parseFloat(this.$route.query.zoom)
      this.center = [parseFloat(this.$route.query.centerLat), parseFloat(this.$route.query.centerLng)]
    } else {
      this.zoom = parseFloat(4)
      this.center = [parseFloat(39.78441640845785), parseFloat(-101.30107733279354)]
    }
  },

  beforeDestroy() {
    // Leaflet
    // this.pulsingIconEnable = null
    // this.pulsingIconDisable = null
    // this.address = null
    // this.zoom = null
    // this.center = null
    // this.maxZoom = null
    // this.RoadmapMapUrl = null
    // this.TerrainMapUrl = null
    // this.AlteredRoadmapMapUrl = null
    // this.SatelliteOnlyMapUrl = null
    // this.HybridMapUrl = null
    // this.attribution = null
    // this.currentZoom = null
    // this.currentCenter = null
    // this.showParagraph = null
    // this.mapOptions = null
    // this.zoomSnap = null
    // this.showMap = null
    // this.marker = null
    // this.errorMessageUploads = null
    // this.uniquePlacements = null
  },

  methods: {
    validate() {
      this.errorMessageUploads = []
      if (this.errors.items.length > 0) return false
      if (!this.marker && !this.is_access_level_unlisted) {
        this.errorMessageUploads.push(this.$t('CreateAndPlaceWorkflowWhereError'))
        return false
      } else {
        return true
      }
    },
    async addMarker(event) {
      console.log('addMarker ' + event.latlng)
      // this.$refs.map.mapObject.flyTo(event.latlng, this.maxZoom);
      this.marker = event.latlng
      console.log('addMarker')
      console.log(event.latlng)

      this.space.latitude = event.latlng.lat
      this.space.longitude = event.latlng.lng
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom
    },
    centerUpdate(center) {
      this.currentCenter = center
    },
    showLongText() {
      this.showParagraph = !this.showParagraph
    },
    innerClick() {
      alert('Click!')
    },
    removeMarker() {
      // this.layer.clearLayers();
    },
    centerMap() {
      this.$refs.map.mapObject.flyTo([37, 95], 2)
    },
    createSpacesMarker() {
      this.circlesCluster = new L.MarkerClusterGroup()

      this.spaces.forEach(space => {
        var containerElement = document.createElement('div')
        containerElement.style.width = `60px`
        containerElement.style.height = `60px`
        containerElement.style.backgroundSize = '100%'
        containerElement.style.position = 'absolute'

        var thumbnailElement = document.createElement('div')
        containerElement.appendChild(thumbnailElement)

        thumbnailElement.className = 'space-marker'
        thumbnailElement.id = `space-${space.pid}`
        thumbnailElement.setAttribute('space_pid', space.pid)

        if (new Date() < new Date(space.present_to)) thumbnailElement.classList.add('space-marker-not-expired')
        else thumbnailElement.classList.add('space-maker-expired')

        // Create a DOM element for each marker
        if (space.thumbnail_small) thumbnailElement.style.backgroundImage = `url(${space.thumbnail_small})`
        else thumbnailElement.style.backgroundImage = `url(${require('@/assets/images/hoverlay/Icon-512.png')})`
        thumbnailElement.style.width = `60px`

        thumbnailElement.style.height = `60px`
        thumbnailElement.style.backgroundSize = '100%'
        thumbnailElement.style.position = 'absolute'

        if (space.description && space.description != 'null') {
          var descriptionElement = document.createElement('div')
          descriptionElement.className = 'marker-description'
          descriptionElement.id = `description-${space.pid}`
          var width = 120
          if (space.description.length < 12) {
            width = space.description.length * 18
            descriptionElement.style.top = '20px'
          }
          descriptionElement.style.width = `${width}px`
          descriptionElement.innerHTML = space.description
          containerElement.appendChild(descriptionElement)
        }

        var icon = L.divIcon({
          html: containerElement.outerHTML,
          iconSize: [60, 60],
          iconAnchor: [30, 30],
        })

        var thumbnailMarker = L.marker([space.latitude, space.longitude], { icon: icon })

        this.circlesCluster.addLayer(thumbnailMarker)

        var circle = L.circle([space.latitude, space.longitude], space.range, {
          color: '#333333',
          fillOpacity: 0,
          opacity: 0.2,
          weight: 2,
          contextmenu: true,
        }).addTo(this.$refs.map.mapObject)

        var popupContent = space.name.substring(0, 30)

        thumbnailMarker.bindTooltip(popupContent, {
          permanent: false,
          sticky: true,
          className: 'my-label',
          offset: [0, 0],
        })
        circle.bindTooltip(popupContent, { permanent: false, sticky: true, className: 'my-label', offset: [0, 0] })

        this.$refs.map.mapObject.addLayer(this.circlesCluster)
      })
    },
    AdressSearch: function() {
      // Check if the address is a lat long or an street address
      var latLongRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
      var isAddressLatLong = latLongRegex.test(this.address)
      if (isAddressLatLong) {
        var ExtractNumberRegex = /[+-]?\d+(\.\d+)?/g
        var coordinates = this.address.match(ExtractNumberRegex).map(function(v) {
          return parseFloat(v)
        })
        console.log(coordinates)

        this.marker = [coordinates[0], coordinates[1]]
        this.$refs.map.mapObject.setView([coordinates[0], coordinates[1]], 18)

        this.space.latitude = coordinates[0]
        this.space.longitude = coordinates[1]

        return true
      }
      this.$vs.loading()

      this.$store
        .dispatch('hoverlay/getLatLongFromAddress', this.address)
        .then(response => {
          this.$vs.loading.close()

          var firstResult = response[0]

          console.log(response[0])
          var bounds = L.latLngBounds(
            [firstResult.boundingbox[0], firstResult.boundingbox[2]],
            [firstResult.boundingbox[1], firstResult.boundingbox[3]]
          )

          this.marker = [firstResult.lat, firstResult.lon]

          this.space.latitude = parseFloat(firstResult.lat)
          this.space.longitude = parseFloat(firstResult.lon)

          var computedRange = parseInt(
            this.calcCrow(
              firstResult.boundingbox[0],
              firstResult.boundingbox[2],
              firstResult.boundingbox[1],
              firstResult.boundingbox[3]
            ) * 300
          )

          if (computedRange < 50) this.space.range = 50
          else this.space.range = computedRange

          this.$refs.map.mapObject.fitBounds(bounds)
        })
        .catch(err => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error',
            text: 'Could not find the requested location',
            color: 'danger',
          })
          console.error(err)
        })
    },

    //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
    calcCrow(lat1, lon1, lat2, lon2) {
      var R = 6371 // km
      var dLat = this.toRad(lat2 - lat1)
      var dLon = this.toRad(lon2 - lon1)
      lat1 = this.toRad(lat1)
      lat2 = this.toRad(lat2)

      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      var d = R * c
      return d
    },
    // Converts numeric degrees to radians
    toRad(Value) {
      return (Value * Math.PI) / 180
    },
  },
}
</script>

<style lang="scss">
.root {
  height: 490px !important;
}

.marker {
  -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.35));
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.35));
}
.leaflet-div-icon {
  background: none;
  border: none;
}
.space-marker {
  display: block;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* -webkit-transition-duration: 0.4s;
        transition-duration: 0.4s; */
  /* overflow: hidden; */
}
.space-marker-not-expired {
  border: 3px solid rgb(255, 255, 255);
}

.space-maker-expired {
  border: 3px solid rgb(255, 0, 0);
}

.marker-description {
  visibility: hidden;
  font-size: 14px;
  font-weight: 900;
  line-height: 19px;
  box-sizing: border-box;
  padding: 4px 10px 5px 27px;
  border-radius: 10px;
  left: 45px;
  top: 10px;
  /* width: 130px; */
  /* margin-left: 10px; */
  /* margin-top: 10px; */
  /* max-width: 165.352px; */
  overflow: hidden;
  max-height: 46px;
  background-color: #ffffff;
  z-index: -9999999;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
}
</style>
