<!-- =========================================================================================
  File Name: uploads/VideoUpload.vue
  Description: Form to edit a youtube link
========================================================================================== -->

<template>
  <div>
    <!-- {{ this.myFiles  }} -->
    <!-- {{ hobject }} -->
    <!-- <br> -->
    <!-- {{ this.hobject.abilities }} -->
    <file-pond
      name="test"
      ref="pond"
      :mediaPreviewHeight="200"
      :maxFileSize="this.maxFileSize"
      :label-idle="this.$t('HoverpackVideoDrag') + '(Max ' + this.maxFileSize + ')'"
      :server="{ process, revert, restore, load, fetch }"
      :allow-multiple="false"
      accepted-file-types="video/mp4"
      :files="myFiles"
      v-on:removefile="fileRemoved"
      v-on:addfile="fileAdded"
      v-on:init="handleFilePondInit"
      class="mb-5"
    />
    <div v-if="error" class="vx-col w-full">
      <vs-alert active="true" color="danger" class="mt-5" icon-pack="feather" icon="icon-info">
        Video format is not supported. Pleaser refer to the
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.spaces.hoverlay.com/docs/1.0/hoverlay-spaces-basics"
          >documentation</a
        >
        for more info.
      </vs-alert>
    </div>
    <div v-show="isCompressionCheckboxVisible" class="vx-row">
      <div class="vx-col w-1/12">
        <vx-tooltip text="If checked the video will be compressed to 720p">
          <feather-icon icon="HelpCircleIcon" class="p-3 inline-flex rounded-full"></feather-icon>
        </vx-tooltip>
      </div>
      <div class="vx-col w-9/12 mt-3">
        <vs-checkbox v-model="hobject.compress_asset"
          >Compress Video (it might take a few minutes to complete)</vs-checkbox
        >
      </div>
    </div>

    <div v-if="errorMessageUpload">
      <span class="text-danger">{{ errorMessageUpload }}</span>
    </div>
    <canvas id="my_canvas" style="display: none"></canvas>
  </div>
</template>

<script>
import humanize from 'string-humanize'

// Import tools to crop the hologram
import { getUnusedSpaceAndRatiosFromVideos } from '@/assets/js/utils/greenscreen-video-tools.js'

// Utils
import * as Utils from '@/assets/js/utils/utils.js'

// Import Vue FilePond
import vueFilePond from 'vue-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import FilePond plugins
// Import video preview plugin styles
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css'

// Import video preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview'

// eslint-disable-next-line no-unused-vars
import MediaInfo from 'mediainfo.js'
import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginMediaPreview)

import 'flatpickr/dist/flatpickr.css'

export default {
  inject: ['$validator'],
  props: {
    initialPlacement: {},
    hobject: {},
    initialAnchor: {},
    isInEditMode: null,
  },
  data() {
    return {
      placement: this.initialPlacement,
      anchor: this.initialAnchor,
      errorMessageUpload: null,
      // mediainfo: null,
      myFiles: [],
      error: false,
      volume: 85,
      isCompressionCheckboxVisible: !this.isInEditMode,
    }
  },
  watch: {
    volume: function(updatedValue) {
      try {
        var abilities = JSON.parse(this.hobject.abilities)
        abilities.skin.volume = updatedValue / 100
        this.hobject.abilities = JSON.stringify(abilities)
      } catch (e) {
        console.error(e)
      }
    },
  },
  computed: {
    maxFileSize: function() {
      return HoverlayUtils.getMaxUploadFileSize()
    },
  },
  mounted() {
    if (this.$refs.alert && this.$refs.alert.style) {
      this.$nextTick(() => {
        this.$refs.alert.style.height = 'auto'
      })
    }
    // MediaInfo({ format: 'JSON' }, mediainfo => {
    //   this.mediainfo = mediainfo
    // })
  },
  created() {
    console.log('PsteYoutubeLink.vue')
    console.log(this.hobject)
    this.hobject.compress_asset = true
    if (this.hobject.asset_uri)
      this.myFiles = [
        {
          source: this.hobject.asset_uri,
          options: {
            type: 'local',
          },
        },
      ]

    // Get Volume
    try {
      var abilities = JSON.parse(this.hobject.abilities)
      console.log(Number(abilities.skin.volume))
      if (Number(abilities.skin.volume) === abilities.skin.volume && abilities.skin.volume % 1 !== 0)
        this.volume = abilities.skin.volume * 100
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    captureThumbnailFromVideo() {
      console.log('captureThumbnailFromVideo')
      const canvas = document.createElement('canvas')
      var video = document.getElementsByTagName('video')[0]
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      // draw the video at that frame
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
      // convert it to a usable blob
      this.hobject.image = Utils.dataURItoBlob(canvas.toDataURL())
      console.log(this.hobject)
    },
    fileAdded: function(error, file) {
      console.log('fileAdded')
      if (!this.hobject.name) {
        delete this.hobject.name
        this.$set(this.hobject, 'name', humanize(file.filename.replace(/\.[^/.]+$/, '').substring(0, 63))) // Max 64 char for a name
      }
    },
    // eslint-disable-next-line no-unused-vars
    fileRemoved(error, file) {
      this.myFiles = []
      this.isCompressionCheckboxVisible = true
    },
    handleFilePondInit: function() {
      console.log('FilePond has initialized')

      // FilePond instance methods are available on `this.$refs.pond`
    },

    computeGroundOfSetAndContentRatio() {
      getUnusedSpaceAndRatiosFromVideos(document.querySelector('video').src)
        .then(response => {
          console.log(response)
          // eslint-disable-next-line no-unused-vars
          var [min, max, ratio] = response
          var abilities = JSON.parse(this.hobject.abilities)
          abilities.skin.ground_offset = parseFloat(Number(min / 100).toFixed(2))
          abilities.skin.vertical_content_ratio_to_media = parseFloat(Number((100 - (min + max)) / 100).toFixed(2))
          this.hobject.abilities = JSON.stringify(abilities)
        })
        .catch(err => {
          console.log('[Error]:   ' + err)
        })
    },
    validate() {
      console.log('[VideoUpload.vue] VALIDATION')
      this.errorMessageUploads = []

      if (this.hobject.data || this.myFiles.length > 0) {
        console.log('[VideoUpload.vue] VALIDATION TRUE')
        return true
      } else {
        console.log('[VideoUpload.vue] VALIDATION FALSE')
        this.errorMessageUpload = 'Upload an mp4 video first'
        return false
      }
    },
    checkVideoFormat(file) {
      var that = this
      return new Promise((resolve, reject) => {
        const getSize = () => file.size
        console.log(process.env.NODE_ENV)

        const readChunk = (chunkSize, offset) => that.createFileReader(file, chunkSize, offset)
        this.mediainfo
          .analyzeData(getSize, readChunk)
          .then(result => {
            console.log(JSON.parse(result).media.track)
            const videoTrack = JSON.parse(result).media.track.find(e => e['@type'] == 'Video')
            if (videoTrack.Format != 'AVC') {
              reject('Format must be AVC')
            }
            console.log(videoTrack)
            resolve(videoTrack)
          })
          .catch(error => {
            console.log(`An error occured:\n${error.stack}`)
            reject(error.stack)
          })
      })
    },

    async process(fieldName, file, metadata, load, error, progress, abort) {
      // if (process.env.NODE_ENV != 'development') {
      //   try {
      //     await this.checkVideoFormat(file)
      //   } catch (e) {
      //     this.error = true
      //     this.$refs.pond.removeFile()
      //     return
      //   }
      // }
      this.error = false
      this.hobject.data = file

      try {
        if (JSON.parse(this.hobject.abilities).skin.chroma_transparency == true)
          this.computeGroundOfSetAndContentRatio()
      } catch (e) {
        console.log(e)
      }

      this.captureThumbnailFromVideo()

      if (!this.hobject.name) {
        this.hobject.name = file.name.replace(/\.[^/.]+$/, '').substring(0, 63) // Max 64 char
      }
      load(file)
      // Should expose an abort method so the request can be cancelled
      return {
        abort: () => {
          // Let FilePond know the request has been cancelled
          abort()
        },
      }
    },
    // eslint-disable-next-line no-unused-vars
    load(source, load, error, progress, abort, headers) {
      var myRequest = new Request(source)
      fetch(myRequest).then(function(response) {
        response.blob().then(function(myBlob) {
          load(myBlob)
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    fetch(url, load, error, progress, abort, headers) {
      console.log('fetch')
      console.log(url)

      var myRequest = new Request(url)
      fetch(myRequest).then(function(response) {
        response.blob().then(function(myBlob) {
          load(myBlob)
        })
      })

      // console.log("originalImage")
      // url = this.hobject.original_image
      // error("Local files only");
    },
    // eslint-disable-next-line no-unused-vars
    restore(uniqueFileId, load, error, progress, abort, headers) {
      // error();
    },
    // eslint-disable-next-line no-unused-vars
    revert(uniqueFileId, load, error) {
      // TODO -> AlbumRepository delete photo
      // load();
    },
    createFileReader(file, chunkSize, offset) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = event => {
          if (event.target.error) {
            reject(event.target.error)
          }
          resolve(new Uint8Array(event.target.result))
        }
        reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize))
      })
    },
    showErrorOnScreen(title, errorMessage) {
      this.$vs.dialog({
        color: 'danger',
        title: title,
        text: errorMessage,
      })
      // remove file from file-pond
      this.$refs.pond.removeFile()
      this.glbUrl = null
      this.report = null
    },
  },

  components: {
    FilePond,
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}

.con-vs-alert .con-vs-alert-primary {
  height: auto !important;
}
</style>
