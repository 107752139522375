<!-- =========================================================================================
  File Name: uploads/GlbUpload.vue
  Description: GLB Upload Form
========================================================================================== -->

<template>
  <file-pond
    :maxFileSize="this.maxFileSize"
    name="test"
    ref="pond"
    :label-idle="`${this.$t('HoverpackUabDrag')} (Max ${this.maxFileSize})`"
    :server="{ process, revert, restore, load, fetch, remove }"
    :allow-multiple="false"
    accepted-file-types="application/zip"
    allowFileTypeValidation="{false}"
    :files="filePondFile"
    v-on:addfile="fileAdded"
    v-on:removefile="fileRemoved"
    v-on:init="handleFilePondInit"
  />
</template>
<script>
import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'
import humanize from 'string-humanize'

// Import Vue FilePond
import vueFilePond from 'vue-filepond'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'

import { unzip, setOptions } from 'unzipit'

setOptions({ workerURL: 'path/to/unzipit-worker.module.js' })

import * as Utils from '@/assets/js/utils/utils.js'
const zip = require('@zip.js/zip.js/index.cjs')

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize)

export default {
  inject: ['$validator'],
  components: {
    FilePond,
  },
  props: {
    hobject: {},
  },
  data() {
    return {
      loopAnimation: null,
      // local variables
      errorMessageUpload: '',
      filePondFile: [],
      userRole: HoverlayUtils.getUserRole(),
      screenshotInterval: null,
      report: null,
    }
  },
  computed: {
    maxFileSize: function () {
      return HoverlayUtils.getMaxUploadFileSize()
    },
  },
  watch: {
    model: {
      handler: function (updatedValue) {},
      deep: true,
    },
    hobject: {
      handler: function (updatedValue) {},
      deep: true,
    },
    loopAnimation: {
      handler: function (updatedValue) {
        var abilities = JSON.parse(this.hobject.abilities)
        if (updatedValue) abilities.uab_loader.loop = true
        else abilities.uab_loader.loop = false
        this.hobject.abilities = JSON.stringify(abilities)
      },
    },
  },
  async created() {},
  mounted() {
    if (this.hobject.android_asset_uri) {
      this.filePondFile = [
        {
          source: this.hobject.android_asset_uri,
          options: {
            type: 'local',
          },
        },
      ]
    }
    console.log(this.hobject.abilities)
    var abilities = JSON.parse(this.hobject.abilities)
    this.loopAnimation = abilities.uab_loader.loop
  },
  methods: {
    getAlertColor(issues) {
      if (issues.numErrors > 0) return 'warning'
      else return 'warning'
    },
    validate() {
      if (!this.hobject.data && !this.hobject.asset_uri) {
        this.errorMessageUpload = 'You must upload a valid asset bundle file'
        return false
      } else if (!this.hobject.name) {
        this.errorMessageUpload = 'You must provide a name'
        return false
      } else {
        this.errorMessageUpload = ''
        return true
      }
    },
    showErrorOnScreen(title, errorMessage) {
      this.$vs.dialog({
        color: 'danger',
        title: title,
        text: errorMessage,
      })
      // remove file from file-pond
      this.$refs.pond.removeFile()

      this.report = null
    },

    chooseFile: function () {
      console.log('chooseFile')
      document.getElementById('fileUpload').click()
    },
    fileAdded: function (error, file) {
      console.log('fileAdded')
      if (!this.hobject.name) {
        delete this.hobject.name
        this.$set(this.hobject, 'name', humanize(file.filename.replace(/\.[^/.]+$/, '')).substring(0, 63))
      }
    },
    fileRemoved: function (error, file) {
      // remove file from file-pond
      this.report = null
    },
    handleFilePondInit: function () {
      console.log('FilePond has initialized')
      // FilePond instance methods are available on `this.$refs.pond`
    },
    async process(fieldName, file, metadata, load, error, progress, abort) {
      const { entries } = await unzip(file)
      var androidUab, iosUab
      for (const [key, value] of Object.entries(entries)) {
        if (key.startsWith('android')) {
          console.log('start with android ')
          androidUab = await value.blob('application/octet-stream')
          this.hobject.android_asset = androidUab
        }
        if (key.startsWith('ios')) {
          console.log('start with ios')
          iosUab = await value.blob('application/octet-stream')
          this.hobject.ios_asset = iosUab
        }
        if (key.startsWith('metadata')) {
          var arrayBuffer = await value.arrayBuffer()
          var string = new TextDecoder().decode(arrayBuffer)
          var data = JSON.parse(string)
          this.hobject.triangles_count = data.triangles_count

          try {
            if (data.unity_version) {
              var abilities = JSON.parse(this.hobject.abilities)
              abilities.uab_loader.unity_version = data.unity_version
              this.hobject.abilities = JSON.stringify(abilities)
            }
          } catch (e) {
            console.error(e)
          }
        }
      }
      this.$eventBus.$emit('hobjectChanged', this.hobject)
      load(file)
      // Should expose an abort method so the request can be cancelled
      return {
        abort: () => {
          // Let FilePond know the request has been cancelled
          abort()
        },
      }
    },
    load(source, load, error, progress, abort, headers) {
      var myRequest = new Request(source)
      fetch(myRequest).then(function (response) {
        response.blob().then(function (myBlob) {
          load(myBlob)
        })
      })
    },

    fetch(url, load, error, progress, abort, headers) {
      console.log('fetch')
      console.log(url)
      var myRequest = new Request(url)
      fetch(myRequest).then(function (response) {
        response.blob().then(function (myBlob) {
          load(myBlob)
        })
      })
    },

    restore(uniqueFileId, load, error, progress, abort, headers) {
      // error();
    },

    revert(uniqueFileId, load, error) {
      // TODO -> AlbumRepository delete photo
      // load();
    },
    remove(source, load, error) {
      this.hobject.android_asset = null
      // Should somehow send `source` to server so server can remove the file with this source
      // Should call the load method when done, no parameters required
      load()
    },

    downloadBlob(blob, name = 'file.txt') {
      // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
      const blobUrl = URL.createObjectURL(blob)

      // Create a link element
      const link = document.createElement('a')

      // Set link's href to point to the Blob URL
      link.href = blobUrl
      link.download = name

      // Append link to the body
      document.body.appendChild(link)

      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      )

      // Remove link from body
      document.body.removeChild(link)
    },
  },
}
</script>

<style lang="scss">
model-viewer {
  width: 100%;
  height: 400px;
  top: 0px;
  position: sticky;
}
.margin-top {
  margin-top: 25px;
}

.image-preview {
  height: 220px;
  width: 220px;
}
.square {
  position: relative;
  width: 100%;
}

.square:after {
  content: '';
  display: block;
  padding-bottom: 100%;
  height: 200px;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
