<!-- =========================================================================================
  File Name: uploads/GlbUpload.vue
  Description: GLB Upload Form
========================================================================================== -->

<template>
  <!-- tab 1 content -->
  <form data-vv-scope="step-1">
    <div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <uab-zip-upload v-bind:hobject="hobject"></uab-zip-upload>
        </div>
      </div>

      <!-- <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <uab-upload-android v-bind:hobject="hobject"></uab-upload-android>
        </div>
      </div> -->
      <!-- <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <uab-upload-ios v-bind:hobject="hobject"></uab-upload-ios>
        </div>
      </div> -->

      <span v-if="errorMessageUpload" class="text-danger">{{ errorMessageUpload }}</span>

      <div class="vx-row mt-6 mb-6">
        <div class="vx-col sm:w-2/3 w-full">
          <vs-checkbox class="inline-flex" v-model="loopAnimation">Animation loop</vs-checkbox>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <thumbnail-upload v-bind:hobject="hobject"></thumbnail-upload>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'
import UabZipUpload from './UabZipUpload.vue'

import UabUploadAndroid from './UabUploadAndroid.vue'
import UabUploadIos from './UabUploadiOS.vue'
import ThumbnailUpload from './ThumbnailUpload.vue'

import * as Utils from '@/assets/js/utils/utils.js'

export default {
  inject: ['$validator'],
  components: {
    // UabUploadAndroid,
    // UabUploadIos,
    UabZipUpload,
    ThumbnailUpload,
  },
  props: {
    hobject: {},
    initialModel: {},
  },
  data() {
    return {
      // inherited variables from parent
      model: this.initialModel,
      loopAnimation: null,
      // local variables
      errorMessageUpload: '',
      userRole: HoverlayUtils.getUserRole(),
      screenshotInterval: null,
      report: null,
    }
  },
  computed: {},
  watch: {
    model: {
      handler: function (updatedValue) {},
      deep: true,
    },
    hobject: {
      handler: function (updatedValue) {},
      deep: true,
    },
    loopAnimation: {
      handler: function (updatedValue) {
        var abilities = JSON.parse(this.hobject.abilities)
        if (updatedValue) abilities.uab_loader.loop = true
        else abilities.uab_loader.loop = false
        this.hobject.abilities = JSON.stringify(abilities)
      },
    },
  },
  async created() {
    if (!this.hobject.original_image)
      this.hobject.image = await fetch(require('@/assets/images/hoverlay/hoverpacks/unityassetbundle.png')).then(r =>
        r.blob()
      )
  },
  methods: {
    getAlertColor(issues) {
      if (issues.numErrors > 0) return 'warning'
      else return 'warning'
    },
    validate() {
      this.errorMessageUpload = ''
      console.log('VALIDATION')
      if (!this.hobject.ios_asset && !this.hobject.android_asset) {
        if (this.hobject.ios_asset_uri || this.hobject.ios_android_uri) {
        } else {
          this.errorMessageUpload = 'You must upload a Unity asset bundle file'
          return false
        }
      }
      if (!this.hobject.name) {
        this.errorMessageUpload = 'You must provide a name'
        return false
      }
      return true
    },
  },
}
</script>

<style lang="scss">
model-viewer {
  width: 100%;
  height: 400px;
  top: 0px;
  position: sticky;
}
.margin-top {
  margin-top: 25px;
}

.image-preview {
  height: 220px;
  width: 220px;
}
.square {
  position: relative;
  width: 100%;
}

.square:after {
  content: '';
  display: block;
  padding-bottom: 100%;
  height: 200px;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
