<!-- =========================================================================================
  File Name: FormPlacement.vue
  Description: Form to edit the attribute of a node and anchor
========================================================================================== -->

<template>
  <div>
    <div class="flex justify-center">
      <div class="w-full">
        <div class="vx-row justify-center">
          <div v-show="isFloorAnchorAttachedToSpace == false" class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
            <vx-card>
              <div slot="no-body">
                <img
                  :src="require('@/assets/images/pages/anchor/floor.gif')"
                  alt="content-img"
                  class="responsive card-img-top"
                />
              </div>
              <h5 class="mb-2">Around the viewer (Default)</h5>
              <p class="text-grey">Place content around, above or behind someone.</p>
              <div class="flex justify-center flex-wrap">
                <vs-button
                  selected
                  v-on:click="
                    anchor_type_selection = 0
                    setNodeAndAnchor(0)
                  "
                  class="mt-4 w-1/2"
                  type="border"
                  color="primary"
                  >Around viewers</vs-button
                >
              </div>
            </vx-card>
          </div>
          <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
            <vx-card>
              <div slot="no-body">
                <img
                  :src="require('@/assets/images/pages/anchor/tracked_image.gif')"
                  alt="content-img"
                  class="responsive card-img-top"
                />
              </div>
              <h5 class="mb-2">On a reference image</h5>
              <p class="text-grey">Place content in relation to a reference image.</p>
              <div class="flex justify-between flex-wrap">
                <vs-button v-on:click="anchor_type_selection = 1" class="mt-4 mr-2 w-5/12" type="border" color="primary"
                  >New image</vs-button
                >
                <vs-button v-on:click="anchor_type_selection = 2" class="mt-4 w-6/12" type="border" color="primary"
                  >Reuse image</vs-button
                >
              </div>
            </vx-card>
          </div>
        </div>
        <div v-if="anchor_type_selection == 2">
          <form-select-existing-anchor v-bind:node="node"></form-select-existing-anchor>
        </div>
        <div v-show="anchor_type_selection == 1">
          <div class="vx-row justify-center">
            <div class="vx-col w-full sm:w-full lg:w-2/3 mb-base">
              <form-anchor
                v-if="anchor.anchor_type == 'image'"
                v-bind:convertImageToData64="this.addHobjectToExistingSpace == false"
                v-bind:anchor="anchor"
                v-bind:initialReferenceImageOrientation="referenceImageOrientation"
              >
                ></form-anchor
              >
              <div class="vx-row mt-6">
                <div class="vx-col">
                  <label for="" class="vs-input--label">Orientation of the object vis-à-vis the reference image</label>
                  <v-select
                    v-model="referenceImageOrientationValue"
                    label="text"
                    :options="referenceImageOrientationOptions"
                    class=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <span v-bind:key="errorMessageUpload" v-for="errorMessageUpload in errorMessageUploads" class="text-danger"
          >{{ errorMessageUpload }}<br
        /></span>
      </div>
    </div>
    <vs-row vs-lg="w-1/2" vs-align="flex-start" vs-type="flex" vs-justify="center"> </vs-row>
  </div>
</template>

<script>
import FormSelectExistingAnchor from '@/views/hoverlay/components/FormSelectExistingAnchor.vue'
import FormAnchor from '@/views/hoverlay/components/anchors-form/FormAnchor.vue'
import 'video.js/dist/video-js.css'
import { LessEqualDepth } from 'three'
import vSelect from 'vue-select'

export default {
  props: {
    space: {},
    addHobjectToExistingSpace: {},
    node: {},
    hobject: {},
    hobjects: {},
    anchor: {},
    anchors_spaces: {},
    setDefaultFloor: {},
  },
  data() {
    return {
      errorMessageUpload: null,
      anchor_type: [
        {
          text: 'the floor',
          value: 0,
        },
        {
          text: 'an existing reference image',
          value: 2,
        },
        {
          text: 'a new reference image',
          value: 1,
        },

        // {
        //     text: 'a gps localtion (30 meters range accuracy)',
        //     value: 2
        // }
      ],
      anchor_type_selection: 0,
      referenceImageOrientation: 'vertical',
      referenceImageOrientationValue: {
        text: 'Vertical',
        value: 'vertical',
      },
      referenceImageOrientationOptions: [
        {
          text: 'Vertical',
          value: 'vertical',
        },
        {
          text: 'Horizontal',
          value: 'horizontal',
        },
      ],
      selectPlacementPosition: null,
      rangePlacement: 40,

      // Anchor
      trackedImageSrc: null,
      trackedImageHeight: 0.5,
      trackedImageTriggerOnly: true,
      trackedImage: '',
      trackedImageName: '',
      errorMessageUploads: [],
      hobject_model_identifier: null,
    }
  },
  computed: {
    isFloorAnchorAttachedToSpace: function() {
      if (this.anchors_spaces && this.anchors_spaces.length > 0)
        if (this.anchors_spaces.find(anchor_space => anchor_space.anchor.pid == this.anchorPidFloor)) return true
        else return false
      return false
    },

    placementPosition: function() {
      return [
        {
          text: 'floating at eyes level',
          value: this.anchorPidSceneOrigin,
        },
        {
          text: 'at floor level, on a horizontal surface',
          value: this.anchorPidFloor,
        },
        {
          text: 'at table-top level, on a horizontal surface',
          value: this.anchorPidTableTop,
        },
      ]
    },
    anchorPidFloor: function() {
      if (this.anchors && this.anchors.find(anchor => anchor.anchor_type == 'floor'))
        return this.anchors.find(anchor => anchor.anchor_type == 'floor').pid
      else return '-1'
    },
    anchorPidSceneOrigin: function() {
      if (this.anchors && this.anchors.find(anchor => anchor.anchor_type == 'scene-origin'))
        return this.anchors.find(anchor => anchor.anchor_type == 'scene-origin').pid
      else return '-2'
    },
    anchorPidTableTop: function() {
      if (this.anchors && this.anchors.find(anchor => anchor.anchor_type == 'table-top'))
        return this.anchors.find(anchor => anchor.anchor_type == 'table-top').pid
      else return '-3'
    },
    anchorList: function() {
      return [
        {
          text: this.$t('AnchorEyes'),
          value: this.anchorPidSceneOrigin,
        },
        {
          text: this.$t('AnchorFloor'),
          value: this.anchorPidFloor,
        },
        {
          text: this.$t('AnchorTable'),
          value: this.anchorPidTableTop,
        },
      ]
    },
    anchors() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.anchors
      } else return []
    },
  },
  watch: {
    referenceImageOrientationValue: {
      handler: function() {
        if (this.referenceImageOrientationValue && this.referenceImageOrientationValue.value)
          this.referenceImageOrientation = this.referenceImageOrientationValue.value
      },
    },
    anchor: {
      handler: function() {
        // console.log("anchor changed" + updatedValue)
        // this.$eventBus.$emit('anchorChanged', updatedValue);
        // console.log(updatedValue)
        // this.$emit('setAnchor', updatedValue)
      },
      deep: true,
    },
    present_to: {
      handler: function(updatedValue) {
        console.log('present_to changed')
        console.log(updatedValue)
        var diff = Math.abs(new Date() - new Date(this.present_to))
        var minutes = Math.floor(diff / 1000 / 60)
        this.space.minutes = minutes
        console.log(this.space.minutes)
      },
    },
    trackedImageHeight: {
      handler: function(updatedValue) {
        console.log('trackedImageHeight changed')
        this.anchor.mark = `{ "position": "${this.referenceImageOrientation}", "height": ${updatedValue /
          100}, "trigger_only": ${this.trackedImageTriggerOnly}}`
        this.node.z = -updatedValue / 100 / 2
        this.$eventBus.$emit('anchorChanged', this.anchor)
      },
    },
    trackedImageTriggerOnly: {
      handler: function(updatedValue) {
        console.log('trackedImageTriggerOnly changed')
        this.anchor.mark = `{ "position": "${this.referenceImageOrientation}", "height": ${this.trackedImageHeight /
          100}, "trigger_only": ${updatedValue}}`
        this.$eventBus.$emit('anchorChanged', this.anchor)
      },
    },
    trackedImage: {
      handler: function(updatedValue) {
        console.log('trackedImage changed')
        this.anchor.mark = `{ "position": "${this.referenceImageOrientation}","height": ${this.trackedImageHeight /
          100}, "trigger_only": ${this.trackedImageTriggerOnly}}`
        this.anchor.data = updatedValue
        this.$eventBus.$emit('anchorChanged', this.anchor)
      },
    },
    trackedImageName: {
      handler: function(updatedValue) {
        console.log('trackedImageName changed')
        this.anchor.name = updatedValue
        this.$eventBus.$emit('anchorChanged', this.anchor)
      },
    },

    node: {
      handler: function(updatedValue) {
        console.log('node changed')
        this.$eventBus.$emit('nodeChanged', updatedValue)
      },
      deep: true,
    },
    selectPlacementPosition(val) {
      if (this.anchor == undefined) return

      if (this.anchorPidFloor == val) {
        this.node.x = 0
        this.node.y = 0
        this.node.z = 1.21
        this.node.angle_x = 0
        this.node.angle_y = 0
        this.node.angle_z = 0
      }

      this.clearAnchor()

      console.log(this.anchor)
      this.anchor.pid = val

      // this.$eventBus.$emit('nodeChanged', this.node)
      this.$eventBus.$emit('anchorChanged', this.anchor)
    },

    referenceImageOrientation() {
      this.anchor.mark = `{ "position": "${this.referenceImageOrientation}","height": ${this.trackedImageHeight /
        100}, "trigger_only": ${this.trackedImageTriggerOnly}}`
      if (this.anchor_type_selection == 2 || this.anchor_type_selection == 1)
        this.setNodeAndAnchor(this.anchor_type_selection)
    },
    anchor_type_selection(val) {
      this.setNodeAndAnchor(val)
    },
  },
  mounted() {},
  created() {
    this.clearAnchor()
    if (this.space.present_to) {
      console.log('new Date(this.space.present_to)')
      this.present_to = new Date(this.space.present_to)
    }
    if (this.setDefaultFloor) this.setNodeAndAnchor(0)
  },
  beforeDestroy() {
    // this.$eventBus.$off('anchorChanged')
    // this.$eventBus.$off('spaceChanged')
    // this.$eventBus.$off('nodeChanged')
  },
  methods: {
    setNodeAndAnchor(val) {
      console.log('setNodeAndAnchor')
      // reset value
      this.node.x = 0
      this.node.x = 0
      this.node.x = 0
      this.node.angle_x = 0
      this.node.angle_y = 0
      this.node.angle_z = 0
      this.node.quaternion_x = 0
      this.node.quaternion_y = 0
      this.node.quaternion_z = 0
      this.node.quaternion_w = 0

      switch (val) {
        case 0:
          this.node.x = 0
          this.node.y = 0
          this.node.z = 1.21
          // TABLE_TOP
          for (var m in this.anchor) delete this.anchor[m]
          this.anchor.pid = this.anchorPidFloor
          this.$eventBus.$emit('anchorChanged', this.anchor)
          console.log(this.anchor)
          break
        case 2:
        case 1:
          if (this.referenceImageOrientation == 'vertical') {
            this.node.quaternion_x = 0.7071
            this.node.quaternion_w = 0.7071
            this.node.z = -this.trackedImageHeight / 2
          } else {
            this.node.quaternion_x = 0
            this.node.quaternion_w = 0
            this.node.z = 0
          }
          this.node.x = 0
          this.node.y = 0
          this.anchor.anchor_type = 'image'
          this.anchor.scale = 1.0
          this.anchor.mark = `{ "position": "${this.referenceImageOrientation}","height": ${this.trackedImageHeight}, "trigger_only": ${this.trackedImageTriggerOnly}}`
          delete this.anchor.pid
          this.$eventBus.$emit('anchorChanged', this.anchor)
          break
        case 3:
          this.$eventBus.$emit('anchorChanged', {})
          break
      }
      this.$eventBus.$emit('nodeChanged', this.node)
    },

    clearAnchor() {
      this.anchor = {}
    },
    validate() {
      // Add floor anchor if none provided
      if (!this.anchor.pid && this.anchor.anchor_type != 'image') {
        this.anchor = { pid: this.anchorPidFloor }
        this.$eventBus.$emit('anchorChanged', this.anchor)
      }

      this.errorMessageUploads = []

      // Existing anchor
      if (this.anchor_type_selection == 2 && this.anchor.pid != undefined) {
        return true
      }

      // new image anchor
      if (this.anchor.anchor_type == 'image' && this.anchor.data == null) {
        this.errorMessageUploads.push('You must provide an image')
        return false
      }
      if (this.anchor.anchor_type == 'image' && this.anchor.name == null) {
        this.errorMessageUploads.push('You must provide an name')
        return false
      }
      return true
    },
  },
  components: {
    FormSelectExistingAnchor,
    FormAnchor,
    vSelect,
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}
.card-no-padding > *:first-child {
  padding: 0rem;
}
</style>
