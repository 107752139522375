<!-- =========================================================================================
  File Name: uploads/VideoUpload.vue
  Description: Form to edit a youtube link
========================================================================================== -->

<template>
  <div>
    <label for="" class="vs-input--label">Volume</label>
    <vs-slider ticks step="1" class="mb-6" text-fixed="%" :step-decimals="true" v-model="volume" />
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  props: {
    initialPlacement: {},
    hobject: {},
    initialAnchor: {},
  },
  data() {
    return {
      volume: 85,
    }
  },
  watch: {
    volume: function(updatedValue) {
      try {
        var abilities = JSON.parse(this.hobject.abilities)
        abilities.skin.volume = updatedValue / 100
        this.hobject.abilities = JSON.stringify(abilities)
      } catch (e) {
        console.error(e)
      }
    },
  },
  mounted() {},
  created() {
    // Get Volume
    try {
      var abilities = JSON.parse(this.hobject.abilities)
      console.log(Number(abilities.skin.volume))
      if (Number(abilities.skin.volume) === abilities.skin.volume && abilities.skin.volume % 1 !== 0)
        this.volume = abilities.skin.volume * 100
    } catch (e) {
      console.error(e)
    }
  },
  computed: {},
  methods: {},

  components: {},
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}

.con-vs-alert .con-vs-alert-primary {
  height: auto !important;
}
</style>
