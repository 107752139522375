<!-- =========================================================================================
  File Name: FormNameDescription.vue
  Description: Form to edit the attributes name and desccription of a hobject
========================================================================================== -->

<template>
  <div>
    <vs-divider class="my-6"></vs-divider>
    <p>Choose whether users can interact with this object:</p>
    <div class="vx-row mt-6 mb-6">
      <div class="vx-col w-1/3">
        <vs-checkbox v-model="positionable_by_user">Move</vs-checkbox>
      </div>
      <div class="vx-col w-1/3">
        <vs-checkbox v-model="orientable_by_user">Rotate</vs-checkbox>
      </div>
      <div class="vx-col w-1/3">
        <vs-checkbox v-model="resizable_by_user">Scale</vs-checkbox>
      </div>
    </div>
    <vs-divider class="my-6"></vs-divider>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  props: {
    initialPlacement: {},
    hobject: {},
  },
  data() {
    return {
      positionable_by_user: false,
      orientable_by_user: false,
      resizable_by_user: false,
    }
  },
  watch: {
    positionable_by_user: {
      handler: function() {
        var abilities
        if (this.positionable_by_user) {
          abilities = JSON.parse(this.hobject.abilities)
          if (!abilities.interactions) abilities.interactions = {}
          abilities.interactions.positionable_by_user = true
          this.hobject.abilities = JSON.stringify(abilities)
        } else {
          abilities = JSON.parse(this.hobject.abilities)
          if (!abilities.interactions) abilities.interactions = {}
          abilities.interactions.positionable_by_user = false
          this.hobject.abilities = JSON.stringify(abilities)
        }
      },
    },
    orientable_by_user: {
      handler: function() {
        var abilities
        if (this.orientable_by_user) {
          abilities = JSON.parse(this.hobject.abilities)
          if (!abilities.interactions) abilities.interactions = {}
          abilities.interactions.orientable_by_user = true
          this.hobject.abilities = JSON.stringify(abilities)
        } else {
          abilities = JSON.parse(this.hobject.abilities)
          if (!abilities.interactions) abilities.interactions = {}
          abilities.interactions.orientable_by_user = false
          this.hobject.abilities = JSON.stringify(abilities)
        }
      },
    },
    resizable_by_user: {
      handler: function() {
        var abilities
        if (this.resizable_by_user) {
          abilities = JSON.parse(this.hobject.abilities)
          if (!abilities.interactions) abilities.interactions = {}
          abilities.interactions.resizable_by_user = true
          this.hobject.abilities = JSON.stringify(abilities)
        } else {
          abilities = JSON.parse(this.hobject.abilities)
          if (!abilities.interactions) abilities.interactions = {}
          abilities.interactions.resizable_by_user = false
          this.hobject.abilities = JSON.stringify(abilities)
        }
      },
    },
    hobject: {
      handler: function(updatedValue) {
        this.$emit('setHobject', updatedValue)
      },
    },
  },
  created() {
    // positionable_by_user
    try {
      var abilities = JSON.parse(this.hobject.abilities)
      if ('interactions' in abilities && abilities.interactions.positionable_by_user) {
        this.positionable_by_user = true
      } else this.positionable_by_user = false
    } catch (e) {
      this.positionable_by_user = false
    }
    // orientable_by_user
    try {
      abilities = JSON.parse(this.hobject.abilities)
      if ('interactions' in abilities && abilities.interactions.orientable_by_user) {
        this.orientable_by_user = true
      } else this.orientable_by_user = false
    } catch (e) {
      this.orientable_by_user = false
    }
    // resizable_by_user
    try {
      abilities = JSON.parse(this.hobject.abilities)
      if ('interactions' in abilities && abilities.interactions.resizable_by_user) {
        this.resizable_by_user = true
      } else this.resizable_by_user = false
    } catch (e) {
      this.resizable_by_user = false
    }
  },
  methods: {
    validate() {
      return true
    },
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}
</style>
