<!-- =========================================================================================
  File Name: uploads/VideoUpload.vue
  Description: Form to edit a youtube link
========================================================================================== -->

<template>
  <div>
    <!-- {{ hobject }} -->
    <!-- <br> -->
    <!-- {{ this.hobject.abilities }} -->
    <!-- <vs-alert active="true">
        If you're using a computer, right click on the YouTube video to copy the URL.
        <br>

    </vs-alert> -->
    <file-pond
      :maxFileSize="this.maxFileSize"
      name="test"
      ref="pond"
      :mediaPreviewHeight="150"
      :label-idle="`${this.$t('HoverpackAudioDrag')} (Max ${this.maxFileSize})`"
      :server="{ process, revert, restore, load, fetch }"
      :allow-multiple="false"
      accepted-file-types="audio/mpeg,audio/mp3,audio/x-m4a"
      :files="myFiles"
      v-on:addfile="fileAdded"
      v-on:init="handleFilePondInit"
    />
    <vs-alert ref="alert">{{ this.$t('HoverpackAudioInfo') }}</vs-alert>
    <!-- <div class="vx-row mb-6">
        <div class="vx-col w-full">

            <vs-input class="w-full" label="Paste your Youtube URL here." v-model="hobject.asset_uri" />
        </div>
    </div> -->
    <div v-if="errorMessageUpload">
      <span class="text-danger">{{ errorMessageUpload }}</span>
    </div>
    <canvas id="my_canvas" style="display: none"></canvas>
    <div class="vx-row">
      <div class="vx-col w-1/12">
        <vx-tooltip text="If checked the audio will be compressed to MP3 192kbps">
          <feather-icon icon="HelpCircleIcon" class="p-3 inline-flex rounded-full"></feather-icon>
        </vx-tooltip>
      </div>
      <div class="vx-col w-9/12 mt-3">
        <vs-checkbox v-model="hobject.compress_asset"
          >Compress audio (it might take a few minutes to complete)</vs-checkbox
        >
      </div>
    </div>
  </div>
</template>

<script>
import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'

import humanize from 'string-humanize'

// Import Vue FilePond
import vueFilePond from 'vue-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import FilePond plugins
// Import video preview plugin styles
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css'

// Import video preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview'

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginMediaPreview)

import 'flatpickr/dist/flatpickr.css'

export default {
  inject: ['$validator'],
  props: {
    initialPlacement: {},
    hobject: {},
    initialAnchor: {},
  },
  data() {
    return {
      placement: this.initialPlacement,
      // hobject: this.initialHobject,
      anchor: this.initialAnchor,
      errorMessageUpload: null,
      myFiles: [],
      volume: 85,
    }
  },
  computed: {
    maxFileSize: function () {
      return HoverlayUtils.getMaxUploadFileSize()
    },
  },
  watch: {
    volume: function (updatedValue) {
      try {
        var abilities = JSON.parse(this.hobject.abilities)
        abilities.audio.volume = updatedValue / 100
        this.hobject.abilities = JSON.stringify(abilities)
      } catch (e) {
        console.error(e)
      }
    },
  },
  mounted() {
    if (this.$refs.alert && this.$refs.alert.style) {
      this.$nextTick(() => {
        this.$refs.alert.style.height = 'auto'
      })
    }
  },
  async created() {
    this.hobject.compress_asset = true

    // if (!this.hobject.abilities) this.hobject.abilities = `{ "audio": { "loop": true, "auto_start": true, "source_url": null } }`
    if (this.hobject.asset_uri)
      this.myFiles = [
        {
          source: this.hobject.asset_uri,
          options: {
            type: 'local',
          },
        },
      ]

    // Get Volume
    try {
      var abilities = JSON.parse(this.hobject.abilities)
      console.log(Number(abilities.audio.volume))
      if (Number(abilities.audio.volume) === abilities.audio.volume && abilities.audio.volume % 1 !== 0)
        this.volume = abilities.audio.volume * 100
    } catch (e) {
      console.error(e)
    }

    if (!this.hobject.original_image)
      this.hobject.image = await fetch(require('@/assets/images/hoverlay/hoverpacks/sound.png')).then(r => r.blob())
  },
  methods: {
    fileAdded: function (error, file) {
      console.log('fileAdded')
      if (!this.hobject.name) {
        delete this.hobject.name
        this.$set(this.hobject, 'name', humanize(file.filename.replace(/\.[^/.]+$/, '')).substring(0, 63))
      }
    },
    handleFilePondInit: function () {
      console.log('FilePond has initialized')

      // FilePond instance methods are available on `this.$refs.pond`
    },
    validate() {
      console.log('[AudioUpload.vue] VALIDATION')
      console.log(this.hobject)

      this.errorMessageUploads = []

      if (this.hobject.data || this.myFiles.length > 0) {
        return true
      } else {
        this.errorMessageUpload = 'Upload an mp3 file first'
        return false
      }
    },
    process(fieldName, file, metadata, load, error, progress, abort) {
      console.log('begin process')
      this.hobject.data = file
      if (!this.hobject.name) {
        this.hobject.name = file.name.replace(/\.[^/.]+$/, '')
      }
      load(file)
      // Should expose an abort method so the request can be cancelled
      return {
        abort: () => {
          // Let FilePond know the request has been cancelled
          abort()
        },
      }
    },
    load(source, load, error, progress, abort, headers) {
      var myRequest = new Request(source)
      fetch(myRequest).then(function (response) {
        response.blob().then(function (myBlob) {
          load(myBlob)
        })
      })
    },

    fetch(url, load, error, progress, abort, headers) {
      console.log('fetch')
      console.log(url)

      var myRequest = new Request(url)
      fetch(myRequest).then(function (response) {
        response.blob().then(function (myBlob) {
          load(myBlob)
        })
      })

      // console.log("originalImage")
      // url = this.hobject.original_image
      // error("Local files only");
    },

    restore(uniqueFileId, load, error, progress, abort, headers) {
      // error();
    },

    revert(uniqueFileId, load, error) {
      // TODO -> AlbumRepository delete photo
      // load();
    },
  },
  components: {
    FilePond,
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}

.con-vs-alert .con-vs-alert-primary {
  height: auto !important;
}
</style>
