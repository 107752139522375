<!-- =========================================================================================
  File Name: uploads/ImageUpload.vue
  Description: Image Upload Form
========================================================================================== -->

<template>
  <!-- tab 1 content -->
  <form data-vv-scope="step-1">
    <file-pond
      :imageResizeTargetWidth="960"
      :imagePreviewHeight="200"
      name="test"
      ref="pond"
      :label-idle="this.$t('HoverpackImageDrag')"
      :server="{ process, revert, restore, load, fetch }"
      :allow-multiple="false"
      accepted-file-types="image/jpeg, image/png"
      :files="myFiles"
      v-on:onremovefile="fileRemoved"
      v-on:addfile="fileAdded"
      v-on:init="handleFilePondInit"
    />
    <span class="text-danger">{{ errors.first('step-1.image_upload') }}</span>
    <span v-if="errorMessageUpload" class="text-danger">{{ errorMessageUpload }}</span>
  </form>
</template>

<script>
import humanize from 'string-humanize'

// Import Vue FilePond
import vueFilePond from 'vue-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import imageCompression from 'browser-image-compression'

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
)

export default {
  inject: ['$validator'],
  components: {
    FilePond,
  },
  props: {
    hobject: {},
  },
  data() {
    return {
      // inherited variables from parent
      errorMessageUpload: '',
      myFiles: [],
    }
  },
  watch: {
    hobject: {
      handler: function() {},
      deep: true,
    },
  },
  computed: {},
  created() {
    console.log('uploads/ImageUpload.vue created ')
    var dataUrl = null
    if (!this.hobject.name) this.hobject.name = ''
    if (this.hobject.asset_uri) dataUrl = this.hobject.asset_uri
    if (this.hobject.original_image) dataUrl = this.hobject.original_image
    if (dataUrl)
      this.myFiles = [
        {
          source: dataUrl,
          options: {
            type: 'local',
          },
        },
      ]
  },
  methods: {
    fileRemoved: function(error, file) {
      console.log('fileRemoved')
    },
    fileAdded: function(error, file) {
      console.log('fileAdded')
      if (!this.hobject.name) {
        delete this.hobject.name
        this.$set(this.hobject, 'name', humanize(file.filename.replace(/\.[^/.]+$/, '')).substring(0, 63))
      }
    },
    handleFilePondInit: function() {
      console.log('FilePond has initialized')
      // FilePond instance methods are available on `this.$refs.pond`
    },
    validate() {
      if (this.hobject.image || this.myFiles.length > 0) {
        this.errorMessageUpload = ''
        console.log('[ImageUpload.vue] VALIDATION TRUE')
        return true
      } else {
        console.log('[ImageUpload.vue] VALIDATION FALSE')
        this.errorMessageUpload = 'You must upload an image'
        return false
      }
    },
    setHobject(hobject) {
      this.hobject = hobject
    },
    async process(fieldName, file, metadata, load, error, progress, abort) {
      console.log('process')
      const resizeOptions = {
        maxSizeMB: 1,
        maxWidthOrHeight: 960,
        useWebWorker: true,
      }
      const compressedImage = await imageCompression(new File([file], file.name, { type: file.type }), resizeOptions)
      this.hobject.image = compressedImage
      this.hobject.data = compressedImage
      console.log(this.hobject)
      load(file)
      // Should expose an abort method so the request can be cancelled
      return {
        abort: () => {
          // Let FilePond know the request has been cancelled
          abort()
        },
      }
    },
    load(source, load, error, progress, abort, headers) {
      var myRequest = new Request(source)
      fetch(myRequest).then(function(response) {
        response.blob().then(function(myBlob) {
          load(myBlob)
        })
      })
    },

    fetch(url, load, error, progress, abort, headers) {
      var myRequest = new Request(url)
      fetch(myRequest).then(function(response) {
        response.blob().then(function(myBlob) {
          load(myBlob)
        })
      })
    },

    restore(uniqueFileId, load, error, progress, abort, headers) {
      // error();
    },

    revert(uniqueFileId, load, error) {
      // load();
    },
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}

.image-preview {
  height: 220px;
  width: 220px;
}
</style>
