<!-- =========================================================================================
  File Name: Cutout.vue
  Description: Form to edit the atributes of an image cutout hoverpack
========================================================================================== -->

<template>
  <div>
    <!-- {{ this.hobject.data }} -->
    <!-- {{ hobject }} -->
    <!--  <br>
    {{myFiles}} -->
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          :success-text="this.$t('HoverpackCTAValid')"
          val-icon-danger="clear"
          danger-text="The url is not valid"
          val-icon-success="done"
          :success="isUrlValid"
          :danger="!isUrlValid && isUrlValid != null"
          placeholder="https://www.instagram.com/hoverlayar/"
          :label="this.$t('HoverpackCTALink')"
          class="w-full"
          icon-pack="feather"
          v-model="destinationLink"
        />
      </div>
    </div>

    <file-pond
      :imageResizeTargetWidth="1024"
      :imagePreviewHeight="200"
      name="test"
      ref="pond"
      :label-idle="this.$t('HoverpackImageDrag')"
      :server="{ process, revert, restore, load, fetch }"
      :allow-multiple="false"
      accepted-file-types="image/jpeg, image/png"
      :files="myFiles"
      v-on:addfile="fileAdded"
      v-on:init="handleFilePondInit"
    />

    <!-- <div class="vx-row mb-6">
        <div class="vx-col xl:w-full">
            <vs-checkbox v-model="virtualFrame">Virtual Frame</vs-checkbox>
        </div>
    </div> -->
    <span v-for="errorMessageUpload in errorMessageUploads" v-bind:key="errorMessageUpload" class="text-danger"
      >{{ errorMessageUpload }}<br
    /></span>
    <span class="text-danger">{{ errors.first('step-2.name') }}</span>
  </div>
</template>

<script>
import vSelect from 'vue-select'

// Import Vue FilePond
import vueFilePond from 'vue-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginFileEncode
)

export default {
  inject: ['$validator'],
  components: {
    FilePond,
  },
  props: {
    initialPlacement: {},
    hobject: {},
  },
  data() {
    return {
      destinationLink: null,
      myFiles: [],
      isUrlValid: null,
      height: null,
      heightInMeter: null,
      model_identifier: 'hoverlay.core.Button.1.0',
      image: '',
      returnUrl: '',
      defaultUnit: 'cm',
      unitOptions: [
        {
          text: 'cm',
          value: 'cm',
        },
        {
          text: 'inches',
          value: 'inches',
        },
      ],
      virtualFrame: false,
      virtualFrameOptions: [
        {
          text: 'Yes',
          value: 'yes',
        },
        {
          text: 'No',
          value: 'no',
        },
      ],
      errorMessageUploads: [],
    }
  },
  watch: {
    defaultUnit: {
      handler: function() {
        this.ComputeHeight(this.height)
      },
    },
    height: {
      handler: function(updatedValue) {
        console.log('height')
        console.log(updatedValue)
        try {
          this.ComputeHeight(updatedValue)
        } catch (e) {
          console.log(e)
        }
        // this.$emit('setHobject', updatedValue)
      },
    },
    destinationLink: {
      handler: function(updatedValue) {
        console.log('destinationLink')
        if (updatedValue) {
          console.log(updatedValue)
          this.isUrlValid = this.isValidUrlSchema(updatedValue)
          if (this.isUrlValid) {
            // if (!this.hobject.data) {
            try {
              console.log(this.getDomain(updatedValue))
              var img = require(`../../../../assets/images/hoverlay/socials/TXT-SOCIAL-${this.getDomain(
                updatedValue
              ).toUpperCase()}.png`)
              this.loadThumbnail(img)
            } catch (e) {
              console.log(e)
            }
            // }
            var abilities = JSON.parse(this.hobject.abilities)
            abilities.openable.destination_url = updatedValue
            this.hobject.abilities = JSON.stringify(abilities)
          } else {
            abilities = JSON.parse(this.hobject.abilities)
            abilities.openable.destination_url = ''
            this.hobject.abilities = JSON.stringify(abilities)
          }
        } else {
          this.myFiles = []
          this.isUrlValid = null
        }
        console.log('changed')
      },
    },
  },
  created() {
    if (!this.hobject.abilities) {
      this.hobject.abilities = `{ "skin": { "type": "image", "height": 0.5}, "openable": {"destination_url":""}, "fadeinout": {} }`
      this.hobject.model_identifier = this.model_identifier
    }
    try {
      this.height = JSON.parse(this.hobject.abilities).skin.height * 100
      this.destinationLink = JSON.parse(this.hobject.abilities).openable.destination_url
      this.heightInMeter = this.height * 100
    } catch (e) {
      this.height = 40
    }
    console.log('created createdcreated' + this.hobject.asset_uri)
    if (this.hobject.asset_uri) this.loadThumbnail(this.hobject.asset_uri)
    if (this.hobject.original_image) this.loadThumbnail(this.hobject.original_image)
  },
  methods: {
    fileAdded: async function() {
      console.log('fileAdded')
    },
    handleFilePondInit: function() {
      console.log('FilePond has initialized')

      // FilePond instance methods are available on `this.$refs.pond`
    },
    loadThumbnail(imageUrl) {
      this.myFiles = [
        {
          source: imageUrl,
          options: {
            type: 'local',
          },
          name: imageUrl,
          type: imageUrl.split('.').pop(),
        },
      ]

      var context = this
      var myRequest = new Request(imageUrl)
      context.hobject.data = 'blob is loading'
      fetch(myRequest).then(function(response) {
        response.blob().then(function(myBlob) {
          myBlob.name = 'image.' + myBlob.type.split('/')[1]
          context.hobject.data = myBlob
        })
      })
    },
    ComputeHeight(height) {
      try {
        console.log(this.defaultUnit.value)
        let heightInMeter = null
        if (isNaN(this.height)) {
          heightInMeter = 1
        } else if (this.defaultUnit.value === 'inches') {
          heightInMeter = parseFloat(height) * 0.0254
        } else {
          heightInMeter = parseFloat(height) / 100
        }
        var abilities = JSON.parse(this.hobject.abilities)
        abilities.skin.height = heightInMeter
        this.hobject.abilities = JSON.stringify(abilities)
      } catch (e) {
        console.log(e)
      }
    },
    validate() {
      let validate = true
      this.errorMessageUploads = []
      if (!this.hobject.name) {
        this.errorMessageUploads.push('Name must not be empty')
        validate = false
      }
      if (!this.height) {
        this.errorMessageUploads.push('Height must not be empty')
        validate = false
      }
      if (!this.hobject.data) {
        this.errorMessageUploads.push('You must provide an image')
        validate = false
      }
      if (!this.destinationLink || !this.isUrlValid) {
        this.errorMessageUploads.push(this.$t('HoverpackCTAError'))
        validate = false
      }
      return validate
    },
    isValidUrlSchema(string) {
      let url
      try {
        url = new URL(string)
      } catch (_) {
        return false
      }
      if (url.protocol === 'http:') return false
      else return true
    },

    pasteFromClipboard() {
      if (this.destinationLink) return
      console.log('paste')
      const context = this
      navigator.clipboard
        .readText()
        .then(text => {
          if (context.isValidUrlSchema(text)) context.destinationLink = text
        })
        .catch(err => {
          console.error('Failed to read clipboard contents: ', err)
        })
    },
    process(fieldName, file, metadata, load, error, progress, abort) {
      console.log('process')
      console.log()
      this.hobject.data = file
      this.hobject.image = file
      load(file)
      // Should expose an abort method so the request can be cancelled
      return {
        abort: () => {
          // Let FilePond know the request has been cancelled
          abort()
        },
      }
    },
    load(source, load, error, progress, abort, headers) {
      var myRequest = new Request(source)
      console.log('LOAD')
      fetch(myRequest).then(function(response) {
        response.blob().then(function(myBlob) {
          load(myBlob)
        })
      })
    },

    fetch(url, load, error, progress, abort, headers) {
      console.log('fetch')
      console.log(url)

      var myRequest = new Request(url)
      fetch(myRequest).then(function(response) {
        response.blob().then(function(myBlob) {
          load(myBlob)
        })
      })

      // console.log("originalImage")
      // url = this.hobject.original_image
      // error("Local files only");
    },

    restore(uniqueFileId, load, error, progress, abort, headers) {
      // error();
    },

    revert(uniqueFileId, load, error) {
      // TODO -> AlbumRepository delete photo
      // load();
    },
    getHostName(url) {
      var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i)
      if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
        return match[2]
      } else {
        return null
      }
    },
    getDomain(url) {
      var hostName = this.getHostName(url)
      var domain = hostName

      if (hostName != null) {
        var parts = hostName.split('.').reverse()

        if (parts != null && parts.length > 1) {
          domain = parts[1] + '.' + parts[0]

          if (hostName.toLowerCase().indexOf('.co.uk') != -1 && parts.length > 2) {
            domain = parts[2] + '.' + domain
          }
        }
      }

      return domain.split('.')[0]
    },
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}
</style>
