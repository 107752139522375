<!-- =========================================================================================
  File Name: FormNameDescription.vue
  Description: Form to edit the attributes name and desccription of a hobject
========================================================================================== -->

<template>
  <div class="vx-row">
    <div class="vx-col xl:w-full">
      <vs-checkbox v-model="use_advanced_chroma_key_algo">Use advanced greenscreen shader</vs-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  props: {
    hobject: {},
  },
  data() {
    return {
      use_advanced_chroma_key_algo: false,
    }
  },
  watch: {
    use_advanced_chroma_key_algo: {
      handler: function() {
        var abilities
        if (this.use_advanced_chroma_key_algo) {
          abilities = JSON.parse(this.hobject.abilities)
          abilities.skin.chroma_key_algorithm = 'advanced'
          if (abilities.skin.despill_luminance_add == null) abilities.skin.despill_luminance_add = 1
          if (abilities.skin.despill_strength == null) abilities.skin.despill_strength = 0.35
          if (abilities.skin.sharpening == null) abilities.skin.sharpening = 0.6
          if (abilities.skin.mask_feathering == null) abilities.skin.mask_feathering = 0.95
          if (abilities.skin.color_feathering == null) abilities.skin.color_feathering = 0.43
          if (abilities.skin.cut_off == null) abilities.skin.cut_off = 0.41
          this.hobject.abilities = JSON.stringify(abilities)
        } else {
          abilities = JSON.parse(this.hobject.abilities)
          abilities.skin.chroma_key_algorithm = 'default'
          this.hobject.abilities = JSON.stringify(abilities)
        }
      },
    },
    hobject: {
      handler: function(updatedValue) {
        this.$emit('setHobject', updatedValue)
      },
    },
  },
  created() {
    try {
      var abilities = JSON.parse(this.hobject.abilities)
      if ('skin' in abilities && abilities.skin.chroma_key_algorithm == 'advanced') {
        this.use_advanced_chroma_key_algo = true
      } else this.use_advanced_chroma_key_algo = false
    } catch (e) {
      this.use_advanced_chroma_key_algo = false
    }
  },
  methods: {
    validate() {
      return true
    },
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}
</style>
