/*
VideoPlane.js
=============
Plane mesh with custom shaders.
Accepts a video, and uses chromakey shaders to key out specified color. (Greenscreen or bluescreen)
*/

import * as THREE from 'three'

import ChromakeyMaterial from '@/assets/js/materials/ChromakeyMaterial'
import * as Utils from '@/assets/js/utils/utils.js'

export default class VideoPlane {
  constructor(scene, url, skin) {
    this.skin = skin
    this.scene = scene
    // Create video dom
    this.video = document.createElement('video')
    this.video.src = url
    this.video.muted = true
    this.video.play()
    this.video.loop = true
    this.texture = new THREE.VideoTexture(this.video)
    // use gamma color space
    this.texture.encoding = THREE.sRGBEncoding
    // init crop values
    this.top = skin.crop_top || 0
    this.bottom = skin.crop_bottom || 0
    this.left = skin.crop_left || 0
    this.right = skin.crop_right || 0
    // keyColor
    this.keyColor = new THREE.Color(0, 1, 0)
    try {
      this.keyColor = Utils.hexToRgb(skin.key_color)
      this.keyColor = new THREE.Color(this.keyColor.r / 255, this.keyColor.g / 255, this.keyColor.b / 255)
      console.log(this.keyColor)
    } catch (e) {
      console.error(e)
    }
  }
  async createMatAndAddToScene() {
    var video = this.video
    var promise = new Promise(function(resolve) {
      video.addEventListener(
        'loadedmetadata',
        function(event) {
          var video = event.target
          var dimensions = [video.videoWidth, video.videoHeight]
          resolve(dimensions)
        },
        { once: true }
      ) // remove event after run once
    })
    var dimensions = await promise
    var ratio = dimensions[0] / dimensions[1]

    // create geometry
    this.geom = new THREE.PlaneGeometry(2 * ratio, 2)
    // create material

    this.mat1 = new ChromakeyMaterial(
      { transparent: true },
      {
        texture: this.texture,
        keyColor: this.keyColor,
        colorCutoff: this.skin.cut_off == null ? 0.44 : this.skin.cut_off,
        colorFeathering: this.skin.color_feathering == null ? 0.43 : this.skin.color_feathering,
        maskFeathering: this.skin.mask_feathering == null ? 0.95 : this.skin.mask_feathering,
        sharpening: this.skin.sharpening == null ? 0.6 : this.skin.sharpening,
        despill: this.skin.despill_strength == null ? 0.35 : this.skin.despill_strength,
        despillLuminanceAdd: this.skin.despill_luminance_add == null ? 1 : this.skin.despill_luminance_add,
        crop: new THREE.Vector4(this.top, this.bottom, this.left, this.right),
        videoWidth: dimensions.videoWidth,
        videoHeight: dimensions.videoHeight,
      }
    )

    this.mesh1 = new THREE.Mesh(this.geom, this.mat1)
    this.mesh1.position.x = 0
    this.scene.add(this.mesh1)
  }
  deletePlaneFromScene() {
    for (var i = this.scene.children.length - 1; i >= 0; i--) {
      var obj = this.scene.children[i]
      this.scene.remove(obj)
    }
  }
  // Binds 3 GUI controls
  bindGUI(pane) {
    const folder1 = pane.addFolder({ title: 'Material Properties', expanded: true })
    this.addMaterialFolderGUI(folder1, this.mat1)
  }

  // Create new GUI folder for each material properties
  addMaterialFolderGUI(matFolder, material) {
    matFolder
      .addInput(material, 'keyColor', {
        label: 'Key',
        color: { type: 'float' },
      })
      .on('change', ev => {
        material.convertGammaToLinear(ev.value)
      })
    matFolder.addInput(material.uniforms._colorCutoff, 'value', {
      label: 'Color cutoff',
      min: 0,
      max: 1,
    })
    matFolder.addInput(material.uniforms._colorFeathering, 'value', {
      label: 'Color feathering',
      min: 0,
      max: 1,
    })
    matFolder.addInput(material.uniforms._maskFeathering, 'value', {
      label: 'Mask feathering',
      min: 0,
      max: 1,
    })
    matFolder.addInput(material.uniforms._sharpening, 'value', {
      label: 'Sharpening',
      min: 0,
      max: 1,
    })
    matFolder.addInput(material.uniforms._despill, 'value', {
      label: 'Despill',
      min: 0,
      max: 1,
    })
    matFolder.addInput(material.uniforms._despillLuminanceAdd, 'value', {
      label: 'Despill lum add',
      min: 0,
      max: 1,
    })

    const subFolder = matFolder.addFolder({ title: 'Crop' })
    subFolder.addInput(material.crop, 'x', { label: 'Top', min: 0, max: 1 })
    subFolder.addInput(material.crop, 'y', { label: 'Bottom', min: 0, max: 1 })
    subFolder.addInput(material.crop, 'z', { label: 'Left', min: 0, max: 1 })
    subFolder.addInput(material.crop, 'w', { label: 'Right', min: 0, max: 1 })
  }

  // Rotates sphere upon mouse drag
  update(secs, mousePos) {
    this.mesh1.rotation.y = mousePos.x * Math.PI
    this.mesh1.rotation.x = mousePos.y * Math.PI
    // this.mesh2.rotation.y = mousePos.x * Math.PI;
    // this.mesh2.rotation.x = mousePos.y * Math.PI;
    // this.mesh3.rotation.y = mousePos.x * Math.PI;
    // this.mesh3.rotation.x = mousePos.y * Math.PI;
  }
}
