<!-- =========================================================================================
  File Name: FormNameDescription.vue
  Description: Form to edit the attributes name and desccription of a hobject
========================================================================================== -->

<template>
  <div class="vx-row">
    <div class="vx-col xl:w-full">
      <vs-checkbox v-model="orientation">{{ this.$t('HoverpackRotation') }}</vs-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  props: {
    initialPlacement: {},
    hobject: {},
  },
  data() {
    return {
      orientation: false,
    }
  },
  watch: {
    orientation: {
      handler: function () {
        var abilities
        if (this.orientation) {
          abilities = JSON.parse(this.hobject.abilities)
          if (!abilities.orientation) abilities.orientation = {}
          abilities.orientation.look_at_target = 'always'
          this.hobject.abilities = JSON.stringify(abilities)
        } else {
          abilities = JSON.parse(this.hobject.abilities)
          if (!abilities.orientation) abilities.orientation = {}
          abilities.orientation.look_at_target = 'never'
          this.hobject.abilities = JSON.stringify(abilities)
        }
      },
    },
    hobject: {
      handler: function (updatedValue) {
        this.$emit('setHobject', updatedValue)
      },
    },
  },
  created() {
    try {
      var abilities = JSON.parse(this.hobject.abilities)
      if ('orientation' in abilities && abilities.orientation.look_at_target == 'always') {
        this.orientation = true
      } else this.orientation = false
    } catch (e) {
      this.orientation = false
    }
  },
  methods: {
    validate() {
      console.log('[FormOrientation.vue] VALIDATION TRUE')
      return true
    },
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}
</style>
