/**
 *  Return true when the given color is green, false otherwise
 * @param {Number} H
 * @param {Number} S
 * @param {Number} V
 * @returns {Boolean}
 */
function isGreenColor(H, S, V) {
  return H >= 90 && S >= 30 && V >= 30 && H <= 150 && S <= 100 && V <= 100
}
/**
 * Seek the video at a specials time depending of the value of mode:
 * 0 => 1/3 of the duration of the video
 * > 0 => 2/3 of the duration of the video
 * and draw the image on canvas
 * @param {String} path
 * @param {Number} mode
 * @returns {Promise} video object
 */
function getVideoImage(path, mode) {
  return new Promise((resolve, reject) => {
    let video = document.createElement('video')
    video.onloadedmetadata = function() {
      // Set the currentime according to the value of mode
      var secs
      if (mode == 0) secs = (video.duration * 1) / 3
      else secs = (video.duration * 2) / 3
      this.currentTime = Math.min(Math.max(0, (secs < 0 ? this.duration : 0) + secs), this.duration)
    }

    video.onseeked = function() {
      var canvas = document.getElementById('my_canvas')
      let ratio = video.videoWidth / video.videoHeight

      canvas.width = video.videoWidth
      canvas.height = video.videoWidth / ratio

      let ctx = canvas.getContext('2d')
      // Draw the video into the canvas so the other script can acces it later
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
      // Return the video object
      resolve(video)
    }

    video.onerror = function(e) {
      reject()
    }

    video.src = path
  })
}

/**
 * Transform a RGB color format in HSV format
 * @param {Number} r
 * @param {Number} g
 * @param {Number} b
 * @returns {Object that represent a HSV Object}
 */
function rgb2hsv(r, g, b) {
  let rabs, gabs, babs, rr, gg, bb, h, s, v, diff, diffc, percentRoundFn
  rabs = r / 255
  gabs = g / 255
  babs = b / 255
  ;(v = Math.max(rabs, gabs, babs)), (diff = v - Math.min(rabs, gabs, babs))
  diffc = c => (v - c) / 6 / diff + 1 / 2
  percentRoundFn = num => Math.round(num * 100) / 100
  if (diff == 0) {
    h = s = 0
  } else {
    s = diff / v
    rr = diffc(rabs)
    gg = diffc(gabs)
    bb = diffc(babs)

    if (rabs === v) {
      h = bb - gg
    } else if (gabs === v) {
      h = 1 / 3 + rr - bb
    } else if (babs === v) {
      h = 2 / 3 + gg - rr
    }
    if (h < 0) {
      h += 1
    } else if (h > 1) {
      h -= 1
    }
  }
  return {
    h: Math.round(h * 360),
    s: percentRoundFn(s * 100),
    v: percentRoundFn(v * 100),
  }
}

/**
 *  Return the maximun/mimimum from one columns
 * @param {Array with RGBA values} pixels
 * @param {Number} height
 * @param {Number} begin
 * @param {Number} width
 * @param {Number} w
 */
function _getMaxMinFromCol(pixels, height, begin, w) {
  var min = height
  var max = 0
  var cpt = 0
  // Bottom to top
  for (let y = height - 1; y > 1; y -= 1) {
    var red = pixels[(w * y + begin) * 4]
    var green = pixels[(w * y + begin) * 4 + 1]
    var blue = pixels[(w * y + begin) * 4 + 2]
    var hsl = rgb2hsv(red, green, blue)
    if (!isGreenColor(hsl.h, hsl.s, hsl.v)) {
      cpt += 1
      if (cpt > 0.1 * height) min = Math.min(y, min)
      max = Math.max(y, max)
    } else cpt = 0

    if (isNaN(hsl.h)) console.log('Bug to be found')
  }

  // if (min >= middle)
  // return (height, 0)
  // The pixel at coordinate (0 ,0) is at Top Left, so when we found the minimum of the image (resp maximun) it's the maximun (resp minimun)
  //console.log("[_getMaxMinFromCol]: min: " + height - max + " max: " + height - min)
  //console.log(height)
  return [height - max, height - min]
}

/**
 * Cut the image in 30 comlumns and return the maximum and mimimum values for the data in pixels
 * @param {Array with RGBA values} pixels
 * @param {Number} height
 * @param {Number} width
 */
function getMaxMinFromOneTexture(pixels, height, width) {
  var min = height
  var max = 0
  for (let index = 1; index <= 30; index++) {
    const [_min, _max] = _getMaxMinFromCol(pixels, height, Math.round((width / 30) * (index - 1) + 1), width)
    if (_min != height && _max != 0) {
      max = Math.max(max, _max)
      min = Math.min(min, _min)
    }
  }

  if (min == 0 && max == 0) return [-1, -1]
  return [min, max]
}

/**
 * Setup the canvas attributes and calculate the maximun and mimimun values from a single image
 */
function ComputeMaxMinOneImage(height, width) {
  var canvas = document.getElementById('my_canvas')
  var ctx = canvas.getContext('2d')
  // Retrieve Data from Canvas
  var imageData = ctx.getImageData(0, 0, width, height)
  var data = imageData.data
  // Calculate the maximun and the minimum according to data
  const [min, max] = getMaxMinFromOneTexture(data, height, width)
  //console.log("[Setup]:  max "+  max + "   min " + min)
  return [min, max]
}

/**
 * Async function that wait until a capture at a given time is draw on canvas and
 * call function ComputeMaxMinOneImage for compute max min for a single image twice
 * @param {String} src
 * @returns {Promise} Max Min and new ratio for the video
 */
async function getMaxMinFromTwoFrames(src) {
  var canvas = document.getElementById('my_canvas')

  // Draw a capture from the video on screen at given time
  await getVideoImage(src, 0)
  console.log(canvas.width + ' ' + canvas.height)
  var min = canvas.height
  var max = 0
  let height = canvas.height

  const [_min, _max] = ComputeMaxMinOneImage(canvas.height, canvas.width)
  // Check if the result is valid or not
  if (_min != -1 && _max != -1) {
    max = Math.max(max, _max)
    min = Math.min(min, _min)
  }

  console.log('getMaxMinFromTwoFrames')
  console.log(canvas)

  // Draw a capture from the video on screen at given time
  await getVideoImage(src, 5)
  const [_min1, _max1] = ComputeMaxMinOneImage(canvas.height, canvas.width)
  // Check if the result is valid or not
  if (_min1 != -1 && _max1 != -1) {
    max = Math.max(max, _max1)
    min = Math.min(min, _min1)
  }

  // Check if we have a least find one min/max, if not return (0,0) for avoiding division by zero later in the script
  if (min == 0 || max == 0) return [0, 0]

  // Increase max value by one percent, just in case this was not the biggest maximun of the video.
  if (max + (height * 1) / 100 < height) max += (height * 1) / 100
  //console.log ("max: " + max)

  min = (min / height) * 100
  max = 100 - (max / height) * 100

  return [min, max]
}

/**
 * Return the Unused space from the videos and a new ratio
 * @param {Object} videoObject
 */
export function getUnusedSpaceAndRatiosFromVideos(videoObject) {
  return new Promise((resolve, reject) => {
    getMaxMinFromTwoFrames(videoObject)
      .then(res => {
        let ratio = (100 + res[0] + res[1]) / 100
        console.log('[FirstCall]:    min: ' + res[0] + '  max: ' + res[1] + 'ratio: ' + ratio)
        resolve([res[0], res[1], ratio])
      })
      .catch(err => {
        console.log('[Error]:   ' + err)
        reject(err)
      })
  })
}
