<!-- =========================================================================================
  File Name: FormText.vue
  Description: Form to edit the attributes of Text Card hobject
========================================================================================== -->

<template>
  <div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <div class="items-center justify-center  md:w-full mx-auto text-center">
          <quill-editor @change="onEditorChange($event)" :options="editorOption" v-model="content"></quill-editor>
        </div>
      </div>
    </div>
    <span v-bind:key="errorMessageUpload" v-for="errorMessageUpload in errorMessageUploads" class="text-danger"
      >{{ errorMessageUpload }}<br
    /></span>
    <span class="text-danger">{{ errors.first('step-2.name') }}</span>
  </div>
</template>

<script>
import domtoimage from 'dom-to-image'
import humanize from 'string-humanize'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  inject: ['$validator'],
  components: {
    quillEditor,
  },
  props: {
    initialPlacement: {},
    hobject: {},
    initialModel: {},
  },
  data() {
    return {
      image: null,
      content: '',
      text: 'Type your text here',
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ['clean'],
          ],
        },
        readyOnly: false,
        theme: 'snow',
        syntax: true,
      },
      defaultAutoResize: {
        text: 'Custom',
        value: true,
      },
      autosizeOptions: [
        {
          text: 'Custom',
          value: true,
        },
        {
          text: 'Default',
          value: false,
        },
      ],
      errorMessageUploads: [],
    }
  },
  watch: {
    content: {
      handler: function(updatedValue) {
        this.hobject.asset_text = updatedValue
      },
    },
    defaultAutoResize: {
      handler: function(updatedValue) {
        if (updatedValue.value == false)
          try {
            var abilities = JSON.parse(this.hobject.abilities)
            abilities.skin.height = null
            this.hobject.abilities = JSON.stringify(abilities)
          } catch (e) {
            console.log(e)
            this.height = 100
          }
      },
    },

    hobject: {
      handler: function() {},
    },
  },
  mounted() {},
  destroy() {},
  created() {
    if (this.hobject.asset_text) this.content = this.hobject.asset_text
    else this.content = '<h2 class="ql-align-center"><strong>Type your text here</strong></h2>'
    var abilities = JSON.parse(this.hobject.abilities)

    if (!abilities.skin.height) {
      console.log('defaultAutoResize false')
      this.defaultAutoResize = {
        text: 'Default',
        value: false,
      }
    } else {
      console.log('defaultAutoResize true')
      this.defaultAutoResize = {
        text: 'Custom',
        value: true,
      }
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onEditorChange({ quill, html, text }) {
      this.text = text
    },
    async generateImage() {
      this.$vs.loading()

      var blob = await domtoimage.toBlob(document.querySelector('.ql-editor'))
      this.hobject.data = blob
      this.hobject.image = blob
      this.hobject.image.name = 'text.png'
      this.$eventBus.$emit('hobjectChanged', this.hobject)
      this.$vs.loading.close()
    },
    async validate() {
      await this.generateImage()
      this.errorMessageUploads = []
      this.hobject.asset_text = this.content
      console.log(this.hobject)
      // If the hobject does not have any name add one by default
      if (!this.hobject.name && this.text) this.$set(this.hobject, 'name', humanize(this.text.replace(/\.[^/.]+$/, '')))
      if (!this.hobject.name) {
        this.errorMessageUploads.push('Please enter a name for your object')
        return false
      }
      if (this.hobject.image) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style lang="scss">
.ql-container .ql-snow {
  margin-top: 10px;
  border-radius: 20px;
  background: white;
  font-size: 200%;
}
.ql-editor {
  font-size: 400%;
  -webkit-text-stroke-color: rgb(0, 0, 0);
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
}
// .ql-toolbar.ql-snow + .ql-container.ql-snow {
//   border-top: 1px !important;
//   border-bottom: 0px !important;
// }

.img-fit {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
  border-radius: 20px;
}
.margin-top {
  margin-top: 25px;
}
</style>
