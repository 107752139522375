<!-- =========================================================================================
  File Name: HobjectList.vue
  Description: Object List - Thumb View 
========================================================================================== -->

<template>
  <div id="data-list-thumb-view" class="data-list-container hoverlay-custom">
    <!-- <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" /> -->
    <!-- <h4>{{ this.$t('HoverpackExisting') }}</h4> -->
    <br />
    <vs-table
      noDataText="It seems that you don't have any object yet, to create one click on 'Add Object'"
      ref="table"
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="hobjects"
    >
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{ hobjects.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : hobjects.length }} of
              {{ queriedItems }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="model_pid">Type</vs-th>
        <vs-th>Thumbnail</vs-th>
        <vs-th sort-key="category">{{ $t('Name') }}</vs-th>
        <vs-th>Use in</vs-th>
        <vs-th sort-key="updated_at_timestamp">Date modified</vs-th>
        <!-- <vs-th sort-key="content_length">{{ $t('Size') }}</vs-th> -->

        <!-- <vs-th>Action</vs-th> -->
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="hobject" :key="indextr" v-for="(hobject, indextr) in data">
            <vs-td class="img-container">
              <img :src="getModelThumbnail(hobject)" class="product-img rounded-lg" />
            </vs-td>
            <vs-td class="img-container">
              <img :src="getHobjectThumbnail(hobject)" class="product-img rounded-lg" />
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{ hobject.name }}</p>
              <p class="product-category">{{ hobject.description }}</p>
            </vs-td>
            <vs-td>
              <div v-if="getActiveSpaces(hobject).length > 0">
                <vs-chip
                  :data="space"
                  :key="indextr"
                  v-for="(space, indextr) in getActiveSpaces(hobject)"
                  link
                  color="primary"
                  class="product-order-status"
                >
                  {{ space.name | truncate(20, '...') }}
                </vs-chip>
              </div>
              <vs-chip v-else link color="warning" class="product-order-status"> inactive </vs-chip>
            </vs-td>
            <vs-td>
              <p class="font-medium truncate">
                {{ dateToString(hobject.updated_at) }}
              </p>
            </vs-td>
            <!-- <vs-td>
              <p class="product-name font-medium truncate">{{ humanFileSize(hobject) }}</p>
            </vs-td> -->
            <!-- 
            <vs-td class="whitespace-no-wrap">
              <feather-icon
                size="3x"
                icon="EditIcon"
                svgClasses="w-7 h-7 hover:text-primary stroke-current"
                class="ml-2"
                @click.stop="editHobject(hobject.pid)"
              />
              <feather-icon
                size="3x"
                icon="TrashIcon"
                svgClasses="w-7 h-7 hover:text-danger stroke-current"
                class="ml-2"
                @click.stop="deleteHobject(hobject.pid)"
              />
            </vs-td> -->
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <span v-for="errorMessageUpload in errorMessageUploads" v-bind:key="errorMessageUpload"
      ><br />
      <h4 class="text-danger">{{ errorMessageUpload }}</h4>
    </span>
  </div>
</template>

<script>
import * as Utils from '@/assets/js/utils/utils.js'
import date from 'date-and-time'

export default {
  props: {
    nodes: {},
    spaces: {},
  },
  components: {},
  data() {
    return {
      selected: [],
      bypassValidation: false,
      errorMessageUploads: [],
      itemsPerPage: 10,
      isInitialized: false,
      isMounted: false,
      addNewDataSidebar: false,
      sidebarData: {},
      Youtube: null,
    }
  },
  watch: {
    selected: {
      handler: function (updatedValue) {
        var hobject = {}
        hobject.pid = updatedValue.pid
        this.$eventBus.$emit('hobjectChanged', hobject)
      },
    },
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    hobjects() {
      var h = this.$store.state.hoverlay.hobjects
      if (this.$store.state.hoverlay) {
        return h.sort(this.compareDate)
      } else {
        return null
      }
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.hobjects.length
    },
  },
  methods: {
    validate() {
      console.log('validate existing object')
      console.log(this.selected)
      if (this.selected && this.selected.pid) {
        this.bypassValidation = true
        document.getElementsByClassName('wizard-footer-right')[0].childNodes[1].click()
        return true
      }

      if (this.bypassValidation == true) return true

      this.errorMessageUploads = []
      this.errorMessageUploads.push('You must choose an object first')
      return false
    },
    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    editHobject(hobject_pid) {
      this.$router.push({
        path: `/edit-hobject/${hobject_pid}`,
      })
    },
    editAnchor(anchor_pid) {
      this.$router.push({
        path: `/edit-anchor/${anchor_pid}`,
      })
    },
    getHobjectThumbnail(hobject) {
      if (hobject.cropped_image) return this.getCloudfrontUrlFromS3(hobject.cropped_image)
    },
    getModelThumbnail(hobject) {
      var model = this.$store.state.hoverlay.models.find(model => model.pid === hobject.model_pid)
      if (model && model.thumbnail_medium) return this.getCloudfrontUrlFromS3(model.thumbnail_medium)
      else return null
    },

    getModelDispayName(hobject) {
      var model = this.$store.state.hoverlay.models.filter(model => model.pid === hobject.model_pid)[0].name
      return model
    },
    getModelDescription(hobject) {
      var model = this.$store.state.hoverlay.models.filter(model => model.pid === hobject.model_pid)[0].description
      return model
    },
    getActiveSpaces(hobject) {
      var spaces = []
      var nodes = this.nodes.filter(node => node.hobject_pid === hobject.pid)
      nodes.forEach(node => {
        var space = this.spaces.find(space => space.pid === node.space_pid)
        if (space) spaces.push(space)
      })
      return spaces
    },
    getPopularityColor(num) {
      if (num > 90) return 'success'
      if (num > 70) return 'primary'
      if (num >= 50) return 'warning'
      if (num < 50) return 'danger'
      return 'primary'
    },
    getThumb(url, size) {
      var results, video
      if (url === null) {
        return ''
      }
      size = size === null ? 'big' : size
      results = url.match('[\\?&]v=([^&#]*)')
      video = results === null ? url : results[1]

      if (size === 'small') {
        return 'http://img.youtube.com/vi/' + video + '/2.jpg'
      }
      return 'http://img.youtube.com/vi/' + video + '/0.jpg'
    },
    async fetchAllData() {
      this.$vs.loading()
      await this.$store.dispatch('hoverlay/getLayerContent')
      this.$vs.loading.close()
    },
    humanFileSize(hobject) {
      return Utils.humanFileSize(hobject.content_length, true)
    },
    dateToString(time) {
      return date.format(new Date(time), 'ddd, MMM DD YYYY') // => 'Fri, Jan 02 2015'
    },
    compareDate(a, b) {
      if (a.updated_at_timestamp < b.updated_at_timestamp) {
        return 1
      }
      if (a.updated_at_timestamp > b.updated_at_timestamp) {
        return -1
      }
      return 0
    },
  },
  created() {},
  async mounted() {
    this.isMounted = true
  },
}
</script>

<style lang="scss">
.hoverlay-custom > .vs-con-table .vs-con-tbody {
  background: #ffffff;
}
.hoverlay-custom > .vs-table-primary .is-selected {
  background: rgba(var(--vs-primary), 0.15) !important;
}
#data-list-thumb-view .vs-con-table .vs-table tr td.img-container .product-img {
  height: 80px !important;
}
#data-list-thumb-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }

          &.img-container {
            // width: 1rem;
            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .product-img {
              height: 110px;
            }
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
  a {
    color: white;
    text-decoration: underline;
  }
}
</style>
