<!-- =========================================================================================
  File Name: FormNameDescription.vue
  Description: Form to edit the attributes name and desccription of a hobject
========================================================================================== -->

<template>
  <div>
    <vs-row vs-align="center" class="mb-4" vs-type="flex" vs-w="12">
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="3">
          <p>Chroma Key</p>          <vx-tooltip text="Will be removed from the video, allowing that portion of the video to be transparent">
            <feather-icon icon="HelpCircleIcon" class="p-3 inline-flex rounded-full"></feather-icon>
          </vx-tooltip>
        </vs-col><vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="2">
          <color-picker v-model="chromaKeyHex"></color-picker>
        </vs-col>
      </vs-row>
    </vs-row>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  props: {
    initialPlacement: {},
    hobject: {},
  },
  data() {
    return {
      chromaKeyHex: '#0f0',
    }
  },
  watch: {
    hobject: {
      handler: function(updatedValue) {
        this.$emit('setHobject', updatedValue)
      },
    },
    chromaKeyHex: {
      handler: function(updatedValue) {
        var abilities
        abilities = JSON.parse(this.hobject.abilities)
        if (!abilities.skin) abilities.skin = {}
        abilities.skin.key_color = updatedValue
        this.hobject.abilities = JSON.stringify(abilities)
      },
    },
  },
  created() {
    try {
      var abilities = JSON.parse(this.hobject.abilities)
      if (abilities.skin.key_color) this.chromaKeyHex = abilities.skin.key_color
    } catch (e) {}
  },
  methods: {
    validate() {
      console.log('[FormOrientation.vue] VALIDATION TRUE')
      return true
    },
  },
  components: {},
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}
</style>
